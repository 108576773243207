import { Done, InProgress, Pending, Todo, ToVerify, UnderVerification } from '../Utils/SvgIcons';

export const DEFAULT_STATUS = {
  TODO: 'todo',
  IN_PROGRESS: 'in-progress',
  DONE: 'done',
  FAILED: 'failed',
};

export const typeConstants = {
  TASK: 1,
  OBJECTIVE: 2,
  KEY_RESULT: 3,
  REQUEST: 4,
  TODO: 5,
};
export const normalTaskTypes = [typeConstants.REQUEST, typeConstants.TASK, typeConstants.TODO];

export const actionConstants = {
  OKRADD: 'OKR-ADD',
};

export const NOTIFICATION_TYPE = {
  TASK_CHAT: 'task_chat',
  TASK: 'task',
  CHAT: 'chat',
  REMINDER: 'reminder',
};

export const NOTIFICATION_STATUS = {
  UNREAD: 0,
  VIEWED: 1,
  READ: 2,
};

export const MILESTONE_STATUS = {
  TODO: 1,
  START: 1,
  COMPLETE: 2,
};

export const MENU = {
  dashboard: { name: 'Dashboard', default_name: 'dashboard' },
  template: { name: 'Template', default_name: 'template' },
  project: { name: 'Project', default_name: 'project' },
  chat: { name: 'Chat', default_name: 'chat' },
  reports: { name: 'Reports', default_name: 'reports' },
  okr: { name: 'OKR', default_name: 'okr' },
  myResponsibility: {
    name: 'My responsibility',
    default_name: 'my-responsibility',
  },
  teamResponsibility: {
    name: 'Team responsibility',
    default_name: 'team-responsibility',
  },
  files: { name: 'Files', default_name: 'files' },
  settings: { name: 'Settings', default_name: 'settings' },
  contact: { name: 'Client', default_name: 'contact' },
};

export const STATUS_ICONS = {
  // eslint-disable-next-line react/react-in-jsx-scope
  pending: <Pending />,
  // eslint-disable-next-line react/react-in-jsx-scope
  done: <Done />,
  // eslint-disable-next-line react/react-in-jsx-scope
  'in-progress': <InProgress />,
  // eslint-disable-next-line react/react-in-jsx-scope
  'under-verification': <UnderVerification />,
  // eslint-disable-next-line react/react-in-jsx-scope
  'to-verify': <ToVerify />,
  // eslint-disable-next-line react/react-in-jsx-scope
  todo: <Todo />,
};

export const SETTINGS = {
  GENERAL: { label: 'general-settings', id: 12 },
};

export const VISIBLE_EVENTS = {
  REMINDER: 'REMINDER',
  SUBTASK: 'SUBTASK',
  TODO_TASK: 'TODO',
  TASK: 'TASK',
  LOG: 'LOG',
  EVENT: 'EVENT',
};

export const TASK_ADDITIONAL_SETTINGS = {
  UNDER_VERIFICATION: 'under_verification',
  TO_VERIFY: 'to_verify',
  HIDE_VERIFICATION_FROM_COMPLETED: 'hide_verification',
};

export const NOTIFICATION_CONTENT = {
  HEADING: 'HEADING',
  CONTENT: 'CONTENT',
  PROFILE: 'PROFILE',
  NAV_LINK: 'NAV_LINK',
};

// export const LABEL_OF_PROJECT = 'Workspace';
// export const LABEL_OF_CLIENT = 'Contact';

export const CommonDropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('field-error');
    return {
      ...styles,
      padding: '2px 1px 2px 4px',
      // height: '34px',
      minHeight: '25px',
      borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : '#87909E',
      boxShadow:
        isFocused && !hasError
          ? '0 0 0 3px rgba(52, 84, 209, 0.25)'
          : hasError && isFocused
          ? ' 0 0 0 3px rgba(255, 0, 0, 0.15)'
          : styles.boxShadow,
      '&:hover': {
        borderColor:
          isFocused && !hasError
            ? '#3454D1'
            : hasError
            ? '#EA4D4D'
            : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      // height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
      // width:'fit-content'
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      width: '100%',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};

export const WIDGETS = [
  {
    name: 'task-statics',
    title: 'Task Statics',
    options: {},
    size: 1,
  },
  {
    name: 'updates',
    title: 'Updates',
    options: {},
    size: 1,
  },
  {
    name: 'chats',
    title: 'Chats',
    options: {},
    size: 1,
  },
  {
    name: 'calendar',
    title: 'Calendar',
    options: {
      userSelect: true,
      projectSelect: true,
      templateSelect: true,
    },
    size: 3,
  },
];

export const PRE_POST = {
  POST: 2,
  PRE: 1,
  OFF: 0,
};
