/* eslint-disable no-unused-vars */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Copy, Dot, Eye, Home, Layers2, Pen, Plus, Search, SquareCheck, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Modal } from '../_components/Modal';
import { TASK_TYPES } from '../_constants/template.constants';
import { templateService } from '../_services/template.service';
// import {  TaskTabs } from './TaskTabs';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { Button, PaginationComponent, TableRow } from '../_components';
import StackedAvatars from '../_components/Avatar/StackedAvatars';
import IconLabelSet from '../_components/IconLabelSet';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT } from '../_constants';
import { DELETE_NOTE, MODAL_NOTES } from '../_constants/deletenote.constants';
import useDebounce from '../_helpers/useDebounce';
import { errorToString } from '../_helpers/utilFunctions';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { GlobalContext, Images, MyTeamIcon, TemplateIcon, fullNameUser } from '../Utils';
import { Calendar, LoadingIcon, StatusIcon } from '../Utils/SvgIcons';
import './template.css';

const TABLE_COLUMN = [
  'Sl.No',
  'Template',
  'Visibility',
  'Task Type',
  'Verification',
  'Created At',
  'Status',
  '',
];

export const TemplateList = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { makeAlert, permissionsMap } = useContext(GlobalContext);
  const nav = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'description',
      'createdAt',
      'task_visibility_type',
      'task_type',
      'is_verification_required',
      'template_visible_users',
      // TEst column,
      'labels',
    ],
    expanded_list: true,
    searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
  });

  const [addModal, setAddModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSortVisible, setIsSortVisible] = useState(false);

  const debouncedSearch = useDebounce(query?.searchKey, 500);
  // search box handleChange
  const handleSearch = (event) => {
    searchParams.set('pageNo', 1);
    setSearchParams(searchParams);
    let searchKey = event.target.value;
    setQuery((prevQuery) => ({
      ...prevQuery,
      pageVo: { ...query.pageVo, pageNo: 1 },
      searchKey: searchKey,
    }));
  };

  const { data, isLoading, isError, error, isSuccess, refetch } = useQuery({
    queryKey: ['template-list', query.pageVo.pageNo, debouncedSearch],
    queryFn: () => templateService.getTemplateList(query),
  });

  const templateList = data?.data?.rows;

  const handleTemplateDuplication = async (templateDetails) => {
    // Remove id from the template
    const { id, ...payload } = templateDetails;
    try {
      setIsSubmitting(true);
      const response = await templateService.duplicateTemplate({ id });
      if (response) {
        // Success
        await queryClient.invalidateQueries('template-list');
      }
    } catch (err) {
      // handleError
      makeAlert(errorToString(err));
    } finally {
      setIsSubmitting(false);
      setDuplicateModal(false);
      setSelectedTemplate(null);
    }
  };

  const handleTemplateDeactivate = async (id) => {
    try {
      setIsSubmitting(true);
      const response = await templateService.deactivateTemplate({ id });
      if (response) {
        // await queryClient.invalidateQueries('template-list');
        refetch();
      }
    } catch (err) {
      // Handle Error
    } finally {
      setIsSubmitting(false);
      setDeleteModal(false);
      setSelectedTemplate(null);
    }
  };

  console.log('chiiii', permissionsMap);

  return (
    <div className='fade-entry'>
      <div className=' url-container cursor-pointer'>
        <div className='d-flex align-items-center  breadcrumbs'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <IconLabelSet
            label={'Templates'}
            color={'#2A2E34'}
            fontWeight={500}
            icon={<img src={TemplateIcon.Template} alt='' />}
          />
        </div>
      </div>
      <div className='template-list-container'>
        <section className='template-sub-container'>
          <div className='tab_contain mt-2'>
            {/* <div className='search_contain'>
              <Search size={20} color='black' />
              <input
                type='text'
                onChange={handleSearch}
                placeholder='Search template'
                className='bodr_none'
              />
              {isLoading ? <Ellipsis size={18} color='black' /> : null}
            </div> */}
            <div className='search_contain'>
              <input
                type='text'
                className='bodr_none'
                onChange={(event) => {
                  handleSearch(event);
                }}
                placeholder='Search'
              />
              <Search size={18} color='#87909E' />
            </div>
            {/* <div className='d-flex gap-2 fs-14px align-items-center'>
              <div
                className='add_contain px-2 btn-wrapper'
                onClick={() => setAddModal(true)}
                onClick={() => {
                  navigate('/template-add/0');
                }}
              >
                <Plus size={15} color='black' />
                <div>Add Template</div>
              </div>
            </div> */}
            <div className='d-flex align-items-center justify-content-end gap-3'>
              {permissionsMap?.template_add && (
                <ToolTip tooltipText={'Add Template'} isModern>
                  <div
                    // className={addModal ? 'add_item_icon_bg_active' : 'add_item_icon_bg'}
                    className='menu-item-outline'
                    onClick={() => {
                      setAddModal(true);
                      navigate('/template-add/0');
                    }}
                  >
                    <Plus size={16} color='#87909E' />

                    {/* <Plus size={18} /> */}
                  </div>
                </ToolTip>
              )}

              {/* <div
                className='ellipsis_icon'
                onClick={() => {
                  setIsFilterVisible(true);
                }}
              >
                <>
                  <ToolTip tooltipText={'Filter'} isModern>
                    <div
                      onClick={() => setIsFilterVisible((prev) => !prev)}
                      className={isFilterVisible ? 'add_item_icon_bg_active' : 'add_item_icon_bg'}
                    >
                      <ListFilter size={18} />
                    </div>
                  </ToolTip>
                </>
              </div> */}

              {/* <ToolTip tooltipText={'Sort'} isModern>
                <div
                  onClick={() => setIsSortVisible((prev) => !prev)}
                  className={
                    isSortVisible
                      ? 'add_item_icon_bg_active me-2 ms-2'
                      : 'add_item_icon_bg me-2 ms-2'
                  }
                >
                  <ArrowDownUp size={18} />
                </div>
              </ToolTip> */}
            </div>
          </div>

          <section className='mt-3'>
            {/* {isLoading && <div>loading...</div>} */}
            <div className='template-table-container'>
              <Table className='table table-hover tbl mb-0'>
                <Thead className='table-head '>
                  {/* <tr>
                    {TABLE_COLUMN.map((heading, index) => {
                      return (
                        <th key={index} scope='col'>
                          {heading}
                        </th>
                      );
                    })}
                  </tr> */}
                  <Tr style={{ backgroundColor: '#F3F5F7' }}>
                    <Th>
                      <img src={Images.SerialNo} height={14} width={14} />{' '}
                    </Th>
                    <Th>
                      <IconLabelSet
                        label={'Template Name'}
                        icon={<SquareCheck size={16} color='gray' />}
                      />{' '}
                    </Th>
                    <Th>
                      <IconLabelSet
                        label={'Task Type'}
                        icon={
                          <img className='template-img-icon-size' src={TemplateIcon.TaskType} />
                        }
                      />
                    </Th>
                    <Th>
                      <IconLabelSet
                        label={'Verification'}
                        icon={
                          <img className='template-img-icon-size' src={TemplateIcon.Verification} />
                        }
                      />
                    </Th>
                    <Th>
                      <IconLabelSet label={'Status'} icon={<StatusIcon />} />
                    </Th>
                    <Th>
                      <IconLabelSet
                        label={'Created on'}
                        icon={<Calendar height={14} width={14} />}
                      />
                    </Th>
                    <Th>
                      <IconLabelSet label={'Visibility'} icon={<Eye size={16} color='#87909E' />} />
                    </Th>
                    <Th style={{ width: 100 }}></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {/* Loading */}
                  {isLoading && (
                    <tr>
                      <TableRow noOfCols={7}>
                        <LoadingIcon color='black' size={50} />
                      </TableRow>
                    </tr>
                  )}
                  {/* No data */}
                  {isSuccess && templateList?.length <= 0 && (
                    <tr>
                      <TableRow noOfCols={7}>No data found</TableRow>
                    </tr>
                  )}
                  {/* Table content */}
                  {isSuccess &&
                    templateList?.map((templateDetails, index) => {
                      const slNo = (query.pageVo.pageNo - 1) * query.pageVo.noOfItems + (index + 1);
                      const createdAtInString = moment(templateDetails.createdAt).format(
                        'MMM DD, YYYY',
                      );
                      return (
                        <Tr key={templateDetails.id}>
                          <td className='font-14'>{slNo}</td>
                          <Td
                            className='pointer'
                            onClick={() => {
                              nav(
                                `/${RT.TEMPLATE}/${templateDetails?.id}?status=${templateDetails?.navigation_id}&list_type=${TaskListTypeConst.Template}`,
                              );

                              // nav(`/${RT.TEMPLATE}/${templateDetails?.id}`);
                            }}
                          >
                            {templateDetails.name}
                          </Td>

                          <Td className='nowrap'>{TASK_TYPES[templateDetails.task_type]}</Td>
                          <Td>
                            {templateDetails.is_verification_required ? 'Required' : 'Not required'}
                          </Td>
                          <Td>
                            <div className='d-flex align-items-center '>
                              <Dot color='#1CA977' />
                              <div>Active</div>
                            </div>
                          </Td>
                          <Td>{createdAtInString}</Td>
                          <Td>
                            <TemplateVisibleUsers
                              visibleGroup={templateDetails.template_visible_group_details}
                              visibleUserIds={templateDetails.template_visible_users}
                              visibleUsers={templateDetails.template_visible_users_details}
                            />
                          </Td>
                          <td className='cursor-pointer'>
                            <div className='d-flex gap-4 justify-content-end icon-div invisible'>
                              <div className='d-flex gap-2'>
                                {permissionsMap?.template_edit && (
                                  <ToolTip
                                    tooltipText={'Edit Template'}
                                    isModern
                                    popOver
                                    // scrollRef={tableContainerRef}
                                  >
                                    <div
                                      className='icon-bg'
                                      onClick={() => {
                                        navigate(
                                          `/${RT.TEMPLATE_ADD}/${templateDetails.id}?pageNo=${
                                            searchParams.get('pageNo') ?? 1
                                          }`,
                                        );
                                        setContextMenuIndex(null);
                                        // handleTemplateDeactivate(templateDetails.id);
                                      }}
                                    >
                                      <Pen size={15} />
                                    </div>
                                  </ToolTip>
                                )}
                                {permissionsMap?.template_add && (
                                  <ToolTip
                                    tooltipText={'Duplicate Template'}
                                    isModern
                                    popOver
                                    // scrollRef={tableContainerRef}
                                  >
                                    <div
                                      className='icon-bg'
                                      onClick={() => {
                                        setSelectedTemplate(templateDetails);
                                        setDuplicateModal(true);
                                        setContextMenuIndex(null);
                                      }}
                                    >
                                      <Copy size={15} />
                                    </div>
                                  </ToolTip>
                                )}
                                {permissionsMap?.template_edit && (
                                  <ToolTip
                                    tooltipText={'Delete Template'}
                                    isModern
                                    popOver
                                    // scrollRef={tableContainerRef}
                                  >
                                    <div
                                      className='icon-bg'
                                      onClick={() => {
                                        setSelectedTemplate(templateDetails);
                                        setDeleteModal(true);
                                        setContextMenuIndex(null);

                                        // handleTemplateDeactivate(templateDetails.id);
                                      }}
                                    >
                                      <Trash size={15} />
                                    </div>
                                  </ToolTip>
                                )}
                              </div>
                            </div>
                          </td>
                          {/* <td>
                            <div className='position-relative'>
                              <div
                                className='btn-wrapper'
                                onClick={() => setContextMenuIndex(index)}
                              >
                                <Ellipsis size={18} color='black' />
                              </div>
                              <ContextMenu
                                isContextVisible={contextMenuIndex === index}
                                onRequestClose={() => setContextMenuIndex(null)}
                                position={POSITION.RIGHT}
                              >
                                <div className='d-flex flex-column gap-2 align-items-start justify-content-center'>
                                  <div
                                    role='button'
                                    onClick={() => {
                                      navigate(`/${RT.TEMPLATE_ADD}/${templateDetails.id}`);
                                      setContextMenuIndex(null);
                                      // handleTemplateDeactivate(templateDetails.id);
                                    }}
                                    className='btn-wrapper bold-on-hover w-100'
                                  >
                                    Edit
                                  </div>
                                  <div
                                    role='button'
                                    className='btn-wrapper bold-on-hover w-100'
                                    onClick={() => {
                                      setSelectedTemplate(templateDetails);
                                      setDuplicateModal(true);
                                      setContextMenuIndex(null);
                                    }}
                                  >
                                    Duplicate
                                  </div>
                                  <div
                                    role='button'
                                    onClick={() => {
                                      setSelectedTemplate(templateDetails);
                                      setDeleteModal(true);
                                      setContextMenuIndex(null);

                                      // handleTemplateDeactivate(templateDetails.id);
                                    }}
                                    className='btn-wrapper bold-on-hover w-100'
                                  >
                                    Delete
                                  </div>
                                </div>
                              </ContextMenu>
                            </div>
                          </td> */}
                        </Tr>
                      );
                    })}
                </Tbody>
              </Table>
            </div>
          </section>
          <section className='mt-2'>
            <PaginationComponent
              pageOnClick={(data) => {
                setQuery((prev) => ({
                  ...prev,
                  pageVo: {
                    ...prev.pageVo,
                    pageNo: data,
                  },
                }));
                searchParams.set('pageNo', data);
                setSearchParams(searchParams);
              }}
              demoData={data?.data}
            />
          </section>
        </section>
      </div>
      {/* {addModal && (
        <Modal.Container handleClose={() => setAddModal(false)}>
          <Modal.View>
            <Modal.Head Heading={'Add Template'} handleClose={() => setAddModal(false)} />
          </Modal.View>
        </Modal.Container>
      )} */}
      {deleteModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDeleteModal(false)} Heading={'Delete Confirmation'} />
            <Modal.Description>
              <img src={Images.DeleteBin} className='my-2' width={35} height={50} alt='' />
              <div className='del-description'>{DELETE_NOTE.TEMPLATE}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container
                isDisabled={isSubmitting}
                handleOk={() => setDeleteModal(false)}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container
                isDisabled={isSubmitting}
                handleOk={() => handleTemplateDeactivate(selectedTemplate.id)}
              >
                <Button.Title title={'Delete'} />
                <Button.Loading isLoading={isSubmitting} color='#FFF' />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
      {duplicateModal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head handleClose={() => setDuplicateModal(false)} Heading={'Create Duplicate'} />
            <Modal.Description>
              {/* <img src={Images.DeleteBin} width={35} height={50} alt='' /> */}
              <Layers2 size={35} color='grey' className='my-2' />
              <div className='del-description'>{MODAL_NOTES.TEMPLATE_DUPLICATE}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container
                isDisabled={isSubmitting}
                handleOk={() => setDuplicateModal(false)}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container
                isDisabled={isSubmitting}
                handleOk={() => handleTemplateDuplication(selectedTemplate)}
              >
                <Button.Title title={'Duplicate'} />
                <Button.Loading isLoading={isSubmitting} color='#FFF' />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
};

export const TemplateVisibleUsers = ({ visibleUsers, visibleUserIds, visibleGroup }) => {
  if (!visibleUserIds || (visibleUserIds.length > 0 && parseInt(visibleUserIds[0]) === 0)) {
    return <>All</>;
  }
  // const { members_details, name } = visibleGroup[0];

  const groupMembers =
    visibleGroup && visibleGroup?.length > 0
      ? visibleGroup.flatMap((item) => item.members_details)
      : [];
  const allUsers = [...(visibleUsers ?? []), ...(groupMembers ?? [])];

  return (
    <>
      <StackedAvatars
        getSourceByKey={(user) =>
          user.compressed_image_url ? user.compressed_image_url : user.image_url
        }
        getNameByKey={(user) => fullNameUser(user.first_name, user.last_name)}
        getGenderByKey={(user) => user.gender === 1}
        imageList={allUsers}
        showInitials={false}
        // tooltipPosition={POSITION.BOTTOM}
        size={25}
        justifyPosition='justify-content-start'
      />
    </>
  );
};
