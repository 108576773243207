/* eslint-disable no-unused-vars */
import { Dot } from 'lucide-react';
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import {
  apiConstants,
  NOTIFICATION_CONTENT,
  NOTIFICATION_STATUS,
  RT,
  USER_PLACEHOLDER,
} from '../_constants';
import { NOTIFICATION_MODE, NOTIFICATION_TYPE } from '../_constants/chat.constants';
import { TruncatedText } from '../Dashboard/TruncatedText';
import { getTimeDifference } from '../Task/taskValidation';
import { fullNameUser, GlobalContext, Images } from '../Utils';

export const NotificationItem = ({
  data,
  taskOwnerProfile,
  setIsNotificationVisible = () => {},
  pathName = ['', '/'],
  index,
}) => {
  const navigate = useNavigate();
  const { makeAlert } = useContext(GlobalContext);

  const getNotificationHeading = (dataObj, value) => {
    let notificationHeading = 'Notification';
    let notificationBody = 'New message';
    let profileIcon = USER_PLACEHOLDER.MALE;
    let navLink = '/';
    const isUnRead =
      data?.is_read === NOTIFICATION_STATUS.VIEWED || data?.is_read == NOTIFICATION_STATUS.UNREAD;

    //--task based non chat notification

    // if (
    //   dataObj?.notification_type === NOTIFICATION_TYPE.TASK ||
    //   dataObj.update_type === NOTIFICATION_TYPE.TASK
    // ) {
    //   console.log(dataObj?.notification_type, dataObj.update_type, 'gymmm');

    //   notificationHeading = dataObj?.Task?.name;
    //   notificationBody = `${fullNameUser(
    //     dataObj?.SenderDetails?.first_name,
    //     dataObj?.SenderDetails?.last_name,
    //   )}:${dataObj?.note}`;
    //   profileIcon = dataObj?.Task?.User?.image_url
    //     ? apiConstants.imgUrlS3 + dataObj?.Task?.User?.image_url
    //     : USER_PLACEHOLDER.MALE;
    //   navLink = dataObj?.task_id
    //     ? `/${RT.TASK}/${dataObj?.task_id}?tab=description&status=${dataObj?.Task?.Status?.default_name}&list_type=team-task&&prevpath=${pathName}&&nextAndPreviousFromChatUpdate=true`
    //     : null;
    // }
    //--- chat based from task
    if (
      (dataObj?.notification_type === NOTIFICATION_TYPE.CHAT && dataObj?.task_id > 0) ||
      dataObj?.notification_type === NOTIFICATION_TYPE.TASK
    ) {
      notificationHeading = dataObj?.Task?.name;
      notificationBody = `${fullNameUser(
        dataObj?.SenderDetails?.first_name,
        dataObj?.SenderDetails?.last_name,
      )}:${dataObj?.note}`;
      profileIcon = dataObj?.Task?.User?.image_url
        ? apiConstants.imgUrlS3 + dataObj?.Task?.User?.image_url
        : USER_PLACEHOLDER.MALE;
      navLink = `/${RT.TASK}/${dataObj?.task_id}?tab=description&status=${dataObj?.Task?.Status?.default_name}&list_type=team-task&&prevpath=${pathName}&&nextAndPrevFromNotificationList=true&&isUnRead=${isUnRead}&&notification_id=${data?.id}&&currentIndex=0`;
    }
    //-- chat based notification
    else if (dataObj?.notification_type === NOTIFICATION_TYPE.CHAT) {
      // personal chat
      if (dataObj?.chatUpdateLog?.update_type === NOTIFICATION_TYPE.PERSONAL) {
        notificationHeading = fullNameUser(
          dataObj?.SenderDetails?.first_name,
          dataObj?.SenderDetails?.last_name,
        );
        notificationBody = dataObj?.note;
        profileIcon = dataObj?.SenderDetails?.image_url
          ? apiConstants.imgUrlS3 + dataObj?.SenderDetails?.image_url
          : USER_PLACEHOLDER.MALE;

        navLink = dataObj?.chatUpdateLog?.id
          ? `/${RT.CHAT}/?tab=all&id=${dataObj?.chatUpdateLog?.id}`
          : null;
      }
      // task  chat
      else if (dataObj?.chatUpdateLog?.update_type === NOTIFICATION_TYPE.S_TASK) {
        notificationHeading = dataObj?.Task?.name;
        notificationBody = `${fullNameUser(
          dataObj?.SenderDetails?.first_name,
          dataObj?.SenderDetails?.last_name,
        )}:${dataObj?.note}`;
        profileIcon = dataObj?.Task?.User?.image_url
          ? apiConstants.imgUrlS3 + dataObj?.Task?.User?.image_url
          : USER_PLACEHOLDER.MALE;
        navLink = dataObj?.chatUpdateLog?.id
          ? `/${RT.CHAT}/?tab=task&id=${dataObj?.chatUpdateLog?.id}`
          : null;
      }
      //  group chat
      else if (dataObj?.chatUpdateLog?.update_type === NOTIFICATION_TYPE.GROUP) {
        //-- chat group
        if (dataObj.chat_group_id) {
          notificationHeading = dataObj?.chatGroupDetails?.name;
          notificationBody = `${fullNameUser(
            dataObj?.SenderDetails?.first_name,
            dataObj?.SenderDetails?.last_name,
          )}:${dataObj?.note}`;

          profileIcon = USER_PLACEHOLDER.GROUP;
          navLink = dataObj?.chatUpdateLog?.id
            ? `/${RT.CHAT}/?tab=all&id=${dataObj?.chatUpdateLog?.id}`
            : null;
        }
        //-- project group
        else if (dataObj.project_id) {
          notificationHeading = dataObj?.Project?.name;
          notificationBody = `${fullNameUser(
            dataObj?.SenderDetails?.first_name,
            dataObj?.SenderDetails?.last_name,
          )}:${dataObj?.note}`;
          profileIcon = USER_PLACEHOLDER.GROUP;
          navLink = dataObj?.chatUpdateLog?.id
            ? `/${RT.CHAT}/?tab=all&id=${dataObj?.chatUpdateLog?.id}`
            : null;
        }
        // users group
        else if (dataObj.team_id) {
          notificationHeading = dataObj?.Team?.name;
          notificationBody = `${fullNameUser(
            dataObj?.SenderDetails?.first_name,
            dataObj?.SenderDetails?.last_name,
          )}:${dataObj?.note}`;
          profileIcon = USER_PLACEHOLDER.GROUP;
          navLink = dataObj?.chatUpdateLog?.id
            ? `/${RT.CHAT}/?tab=all&id=${dataObj?.chatUpdateLog?.id}`
            : null;
        }
      }
    }
    // reminder notification
    else if (dataObj?.notification_type === NOTIFICATION_TYPE.REMINDER) {
      notificationHeading = dataObj?.Reminder?.title;
      notificationBody = `${dataObj?.note}`;
      profileIcon = Images.reminder;
      if (dataObj?.task_id) {
        // navLink = `/${RT.TASK}/${dataObj?.task_id}?tab=reminders`;
        navLink = `/${RT.TASK}/${dataObj?.task_id}?tab=reminders&status=${dataObj?.Task?.Status?.default_name}&list_type=team-task&&prevpath=${pathName}&&nextAndPrevFromNotificationList=true&&isUnRead=${isUnRead}&&notification_id=${data?.id}&&currentIndex=0`;
      }
    }

    if (value === NOTIFICATION_CONTENT.HEADING) {
      return notificationHeading;
    } else if (value === NOTIFICATION_CONTENT.CONTENT) {
      return notificationBody;
    } else if (value === NOTIFICATION_CONTENT.PROFILE) {
      return profileIcon;
    } else if (value === NOTIFICATION_CONTENT.NAV_LINK) {
      return navLink;
    }
  };

  const notificationHeading = getNotificationHeading(data, NOTIFICATION_CONTENT.HEADING);
  const notificationBody = getNotificationHeading(data, NOTIFICATION_CONTENT.CONTENT);
  const notificationIcon = getNotificationHeading(data, NOTIFICATION_CONTENT.PROFILE);
  const navigationLink = getNotificationHeading(data, NOTIFICATION_CONTENT.NAV_LINK);

  const time = getTimeDifference(parseInt(data?.updated_time));
  const isUnRead =
    data?.is_read === NOTIFICATION_STATUS.VIEWED || data?.is_read == NOTIFICATION_STATUS.UNREAD;

  const navigateToTask = () => {
    if (navigationLink) {
      navigate(navigationLink);
      setIsNotificationVisible(false);
    } else {
      makeAlert('Navigation data missing');
    }
  };

  return (
    <div className='nt-status-card pointer' onClick={() => navigateToTask(data)}>
      <div>
        <CircularAvatar
          key={index}
          placeholderSrc={notificationIcon}
          isMale
          source={taskOwnerProfile}
          size={35}
        />
      </div>

      <div className='nt-status-content '>
        {isUnRead && (
          <Dot
            size={30}
            style={{
              position: 'absolute',
              right: 25,
            }}
            color='blue'
          />
        )}
        <TruncatedText className={'main-text'} text={notificationHeading} />
        <div className='nt-message-content'>
          <div className='nt-icon-container'>
            <GetBadgeComponent mode={data?.mode} />
            <span className='nt-message-text'>
              <TruncatedText
                className={'nt-update-message'}
                text={notificationBody?.substring(0, 400)}
              />
            </span>
          </div>
          <div className='nt-timestamp'>{time}</div>
        </div>
      </div>
    </div>
  );
};

const GetBadgeComponent = (mode) => {
  const modeConfig = {
    [NOTIFICATION_MODE.CHAT_MODE]: {
      icon: '../',
      color: '#eaf7f3',
    },
    [NOTIFICATION_MODE.VERIFICATION_MODE]: {
      icon: Images.verify,
      color: '#eaf7f3',
    },
    [NOTIFICATION_MODE.ASSIGNED_MODE]: {
      icon: Images.chatGreen,
      color: '#eaf7f3',
    },
    [NOTIFICATION_MODE.COMPLETED_MODE]: {
      icon: Images.chatGreen,
      color: '#eaf7f3',
    },
    [NOTIFICATION_MODE.REMINDER_MODE]: {
      icon: Images.reminder,
      color: '#f0f0f0',
    },
    // Add other modes as needed
  };

  const defaultConfig = {
    icon: Images.chatGreen,
    color: '#eaf7f3',
  };

  const getConfigByMode = (mode) => modeConfig[mode] || defaultConfig;

  const { icon, color } = getConfigByMode(mode?.mode);

  return (
    <>
      <div className='nt-chat-icon' style={{ background: color }}>
        <img src={icon} height={14} width={14} />
      </div>
    </>
  );
};
