import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import DashBoardCustomGrid from '../Dashboard/DashboardCustomGrid';
import { useUser } from '../Hook/useUser';

export default function Home() {
  const user = useUser();
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate('/login', { replace: true });
    }
  }, [user]);

  return (
    <div>
      {/* <Dashboard /> */}
      <DashBoardCustomGrid />
    </div>
  );
}
