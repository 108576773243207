import { RefreshCcw } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingIcon } from '../../Utils/SvgIcons';

function ChatUpdateListWrapper({
  children,
  isEmpty,
  className = '',
  isError = false,
  isLoading = false,
  error = { message: '' },
  refetchUpdates = () => {},
}) {
  const navigate = useNavigate();

  if (isLoading) {
    return (
      <div
        className='d-flex align-items-center justify-content-center'
        style={{ height: `calc(100vh - var(--top-bar-height))` }}
      >
        <LoadingIcon size={50} />
      </div>
    );
  }

  if (isError && isEmpty) {
    return (
      <div className='fz-13px update-padding grey-text text-center mt-3'>
        {error?.message ?? 'Something went wrong'}
      </div>
    );
  }

  if (isEmpty) {
    return (
      <>
        <div
          onClick={() => {
            navigate('/chat?tab=all');
          }}
          className='update-padding grey-text text-center fz-13px-rem d-flex align-items-center justify-content-center h-100'
        >
          No result
        </div>
      </>
    );
  }

  return (
    <div className={`list_condainer fade-entry position-relative ${className}`}>
      {isError && (
        <div
          className='chat-error-banner top-0'
          role='button'
          onClick={() => {
            refetchUpdates();
          }}
        >
          {error?.message ?? 'Something went wrong'} <RefreshCcw size={14} />
        </div>
      )}
      {children}
    </div>
  );
}

export default ChatUpdateListWrapper;
