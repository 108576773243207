import { Mic, Pause, Play } from 'lucide-react';
import React, { useEffect } from 'react';
import useImperativeAudio from '../Hook/useImperativeAudio';
import Slider from './Slider/Slider';

function OkrAudioPlayer({ src, containerClassName = '', disableAvatar = false, iconSize = 40 }) {
  const {
    isPlaying,
    togglePlayPause,
    duration,
    currentTime,
    cycleSpeed,
    handleSeek,
    formatTime,
    playbackRate,
    audioRef,
  } = useImperativeAudio({ src });

  useEffect(() => {
    // We need stop playing the audio when component unmount.
    return () => {
      audioRef.current && audioRef.current.pause();
    };
  }, []);

  return (
    <div
      className={`colored-container align-items-center audio-container d-flex px-2 w-100 gap-3  ${containerClassName}`}
    >
      {!disableAvatar && (
        <div
          className='audio-mic-icon'
          style={{
            width: iconSize,
            height: iconSize,
          }}
        >
          <Mic size={iconSize / 2} color='#797979' />
        </div>
      )}
      <div className={`d-flex align-items-center gap-1 `} style={{ flex: 1 }}>
        <div className='d-flex align-items-center  justify-content-center'>
          {isPlaying ? (
            <Pause size={20} fill='rgba(42, 46, 52, 1)' role='button' onClick={togglePlayPause} />
          ) : (
            <Play size={20} fill='rgba(42, 46, 52, 1)' role='button' onClick={togglePlayPause} />
          )}
        </div>
        <div className='slider-wrapper px-2'>
          <Slider
            containerClassName='d-none d-md-block '
            currentTime={currentTime}
            progressTrackColor='black'
            duration={duration}
            hideHandle
            handleSeek={handleSeek}
          />
        </div>
        <button className='play-back-speed-btn' type='button' onClick={cycleSpeed}>
          {playbackRate}x
        </button>
      </div>
      <div className='audio-time fz-10px-rem'>{formatTime(currentTime)}</div>
    </div>
  );
}

export default OkrAudioPlayer;
