/* eslint-disable no-unused-vars */
import { useIsMutating, useMutation, useQuery } from '@tanstack/react-query';
import {
  Check,
  CircleDashed,
  Ellipsis,
  LayoutDashboard,
  Maximize,
  Pen,
  Plus,
  Trash,
  X,
} from 'lucide-react';
import React, { Suspense, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css';
import 'react-resizable/css/styles.css';
import { useNavigate } from 'react-router-dom';
import { ContextMenu, Modal } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import ToolTip from '../_components/Tooltip/ToolTip';
import { NOTIFICATION_TYPE, RT } from '../_constants';
import { dashboardService, miscService } from '../_services';
import { chatService } from '../_services/chat.service';
import useNotification from '../Hook/useNotification';
import { useUser } from '../Hook/useUser';
import { useUserValidate } from '../Hook/useUserValidate';
import { CustomEmptyContainer } from '../Task/TaskComponents/CustomInputs';
import { TASK_LIST_EMPTY_MESSAGE } from '../Task/taskConstants';
import { DashboardContext, GlobalContext, jsonToUrlEncoded } from '../Utils';
import { DashBoardDrawer, LoadingIcon } from '../Utils/SvgIcons';
import { widgetConstant, widgetTitles } from './_utils';
import './dashboard.css';
import './dashboardcustom.css';
import AddWidgetModal from './Widget/AddWidget';
import { layoutDropdownStyle } from './Widget/widgetconstants';

const ResponsiveGridLayout = WidthProvider(Responsive);

// Lazy load components
const DashboardBarGraph = React.lazy(() => import('./DashboardBarGraph'));
const DashboardChat = React.lazy(() => import('./DashboardChat'));
const DashboardStatusCounts = React.lazy(() => import('./DashboardStatusCounts'));
const DashboardTaskStatistics = React.lazy(() => import('./Widget/DashboardTaskStatistics'));
const DashboardUpdates = React.lazy(() => import('./DashboardUpdates'));
const DashboardDeadlines = React.lazy(() => import('./Widget/DashboardDeadlines'));
const DashboardWorkLoadStatics = React.lazy(() => import('./Widget/DashboardWorkLoadStatics'));
const TimeBoxCalendar = React.lazy(() => import('../Calander/TimeBoxCalendar'));
const DashboardProjects = React.lazy(() => import('./Widget/DashboardMyProjects'));

// used from home page
const DashBoardCustom = () => {
  const user = useUser();
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const userValidate = useUserValidate();
  const updateToken = useNotification({ disabled: false });
  const nav = useNavigate();

  const [queryPayload, setQueryPayload] = useState({});
  const [isEditMode, setIsEditMode] = useState(false);
  const [isWidgetModal, setIsWidgetModal] = useState(false);
  const [isWidgetDropDown, setIsWidgetDropDown] = useState({ modal: false, key: '' });
  const [deleteConfirmation, setDeleteConfirmation] = useState({ isDelete: false, id: null });
  const [editWidgetModal, setEditWidgetModal] = useState({ isEdit: false, editData: null });

  const [currentBreakpoint, setCurrentBreakpoint] = useState('lg');
  const isMutating = useIsMutating();

  const [savedLayouts, setSavedLayouts] = useState(() => {
    const saved = localStorage.getItem('dashboard-layouts');
    return saved ? JSON.parse(saved) : { lg: [], md: [], sm: [], xs: [], xxs: [] };
  });
  const [layout, setLayout] = useState(savedLayouts[currentBreakpoint] || []);

  const {
    data: widgetList,
    isLoading: widgetLoading,
    refetch,
  } = useQuery({
    queryKey: ['widgets'],
    queryFn: () => miscService.getWidget('widget'),
    select: (resp) => resp?.data,
    enabled: !!user?.id,
  });
  const [selectedWidget, setSelectedWidget] = useState({});

  const handleOpenModal = () => setIsWidgetModal(true);
  const handleCloseModal = () => setIsWidgetModal(false);

  const { mutateAsync, isPending } = useMutation({
    mutationKey: ['event-add'],
    mutationFn: (payload) => miscService.addWidget(payload),
    onSuccess: () => {
      setTimeout(() => {
        refetch();
      }, 1000);
      handleCloseModal();
      return;
    },
    onError: (err) => {
      console.error(err);
      makeAlert(err?.message);
    },
  });
  // Use an effect to set the selected widget when the widget list is loaded
  useEffect(() => {
    if (widgetList && widgetList?.length > 0) {
      const isActiveWidget = widgetList.filter((item) => item.is_active == true);
      const isDefaultWidget = widgetList.filter((item) => item.is_default == true);
      if (isActiveWidget?.length > 0) {
        setSelectedWidget(isActiveWidget[0]); // Set the first widget as the selected widget
      } else setSelectedWidget(isDefaultWidget[0]); // Set the first widget as the selected widget
    }
  }, [widgetList]);
  // const customLayout = selectedWidget?.custom_data?.map((item) => item?.position);

  // useEffect(() => {
  //   if (!widgetLoading && customLayout) {
  //     setLayout(customLayout);
  //     setSavedLayouts((prev) => ({ ...prev, [currentBreakpoint]: customLayout }));
  //   }
  // }, [widgetLoading, currentBreakpoint, selectedWidget]);
  // useEffect(() => {
  //   if (!widgetLoading && selectedWidget?.custom_data) {
  //     const sortedCustomLayout = [...selectedWidget.custom_data].sort((a, b) => a.order - b.order);
  //     const sortedLayout = sortedCustomLayout.map((item) => item.position);
  //     setLayout(sortedLayout);
  //     setSavedLayouts((prev) => ({ ...prev, [currentBreakpoint]: sortedLayout }));
  //   }
  // }, [widgetLoading, currentBreakpoint, selectedWidget]);
  const adjustWidgetLayout = (widgets, gridCols = 3) => {
    // Sort widgets by their `order` property
    const sortedWidgets = [...widgets].sort((a, b) => a.order - b.order);

    // Initialize position tracking
    let x = 0;
    let y = 0;

    // Adjust positions based on sorted order
    const adjustedWidgets = sortedWidgets.map((widget, index) => {
      const newWidget = {
        ...widget,
        position: {
          ...widget.position,
          x: x, // Assign current x
          y: y, // Assign current y
        },
      };

      // Move to the next column
      x += widget.position.w || 1;

      // If the current row is full, reset `x` and move to the next row
      if (x >= gridCols) {
        x = 0;
        y += widget.position.h || 1;
      }

      return newWidget;
    });

    return adjustedWidgets;
  };
  useEffect(() => {
    if (selectedWidget?.custom_data) {
      const sortedAndAdjustedWidgets = adjustWidgetLayout(selectedWidget.custom_data, 3); // Assuming 3 columns
      setLayout(sortedAndAdjustedWidgets.map((widget) => widget.position));
    }
  }, [selectedWidget?.custom_data]);

  const updateQuery = {
    select: [
      'id',
      'task_id',
      'last_message',
      'last_message_time',
      'message',
      'message_count_info',
      'update_type',
      'updated_time',
      'last_message_creator_id',
    ],
    update_type: 'task',
    unread: true,
    task_select: ['id', 'name', 'note', 'priority', 'order_seq'],
  };

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setQueryPayload(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const { data: updatesList, isLoading } = useQuery({
    queryKey: ['dashboard-update', user?.id],
    queryFn: () => chatService.getChatUpdateList({ payload: getPayLoad(updateQuery) }),
    select: (resp) => resp.data,
    enabled: !!user?.id,
  });

  const { data: dashboardCounts, isLoading: isDashboardLoading } = useQuery({
    queryKey: ['dashboard-counts', user?.id],
    queryFn: () => dashboardService.getDashboardCounts(),
    select: (resp) => resp.data,
    enabled: !!user?.id,
  });

  // for change in layout
  const handleLayoutChange = useCallback(
    (newLayout, allLayouts) => {
      // if (isEditMode) {
      setSavedLayouts(allLayouts);
      localStorage.setItem('dashboard-layouts', JSON.stringify(allLayouts));
      setLayout(newLayout);
      // }
    },
    [isEditMode],
  );
  const handleBreakpointChange = (breakpoint) => {
    setCurrentBreakpoint(breakpoint);
    setLayout(savedLayouts[breakpoint] || []);
  };

  const dashboardContextValue = useMemo(
    () => ({
      updatesList,
      dashboardCounts,
      isDashboardLoading,
      isLoading,
    }),
    [updatesList, dashboardCounts, isDashboardLoading, isLoading],
  );
  // Adjust the delay as needed (300ms in this example)

  const { total_counts, status_with_count } = dashboardCounts || {};

  const Loader = () => {
    return (
      <div className='loading-wrapper'>
        <LoadingIcon color={'#c1c1c1'} size={70} />
      </div>
    );
  };

  const dashboardWidgets = useMemo(
    () => ({
      [widgetConstant.task_statics]: (props) => (
        <Suspense fallback={<Loader />}>
          {!isDashboardLoading ? (
            <DashboardTaskStatistics
              counts={{ total_counts: total_counts, status_with_count: status_with_count }}
              {...props}
            />
          ) : (
            <Loader /> // Or any loading indicator you prefer
          )}
        </Suspense>
      ),
      [widgetConstant.calendar]: (props) => (
        <Suspense fallback={<Loader />}>
          <TimeBoxCalendar isNeedProject={false} isNeedTemplate={false} {...props} />
        </Suspense>
      ),
      [widgetConstant.chats]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardChat {...props} />
        </Suspense>
      ),
      [widgetConstant.updates]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardUpdates {...props} queryPayload={queryPayload} />
        </Suspense>
      ),
      [widgetConstant.graphical_report]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardBarGraph {...props} />
        </Suspense>
      ),
      [widgetConstant.status_counts]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardStatusCounts user={user} {...props} />
        </Suspense>
      ),
      [widgetConstant.upcoming_deadlines]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardDeadlines user={user} {...props} />
        </Suspense>
      ),
      [widgetConstant.workload_statics]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardWorkLoadStatics {...props} />
        </Suspense>
      ),
      [widgetConstant.my_projects]: (props) => (
        <Suspense fallback={<Loader />}>
          <DashboardProjects {...props} />
        </Suspense>
      ),
    }),
    [queryPayload, isDashboardLoading],
  );

  const getDashBoardWidget = (widgetName) => {
    let selectedProps = {};
    const WidgetComponent = dashboardWidgets[widgetName] || null;

    // Use find instead of filter to get the first matching item
    const customDataItem = selectedWidget?.custom_data?.find((item) => item?.name === widgetName);

    if (customDataItem) {
      selectedProps = {
        userType: customDataItem.userType,
        project_id: customDataItem.project_id,
        viewType: customDataItem.viewType,
      };
    }

    // If the widget component is not null, call it with the additional props
    return WidgetComponent ? WidgetComponent(selectedProps) : null;
  };

  // to change layout and set as active
  const handleChangeLayout = async (data) => {
    console.log({ handleChangeLayout: data });
    data.is_active = true;
    data.isChangeLayout = true;
    // setSelectedWidget(data);
    await mutateAsync(data);
  };

  // const handleDragAndResizeStop = async (layout, oldItem, newItem, placeholder, e, node) => {
  //   const currentWidget = structuredClone(selectedWidget);
  //   let hasChanged = false;

  //   console.log({ handleDragAndResizeStop: oldItem, newItem, e });

  //   // Destructure and validate newItem values
  //   const {
  //     i: newI,
  //     h: newH = oldItem.h, // Fallback to oldItem.h if newH is NaN or undefined
  //     x: newX,
  //     y: newY,
  //     w: newW = oldItem.w, // Fallback to oldItem.w if newW is NaN or undefined
  //   } = newItem;

  //   // Ensure newH and newW are valid numbers
  //   const validH = isNaN(newH) ? oldItem.h : newH;
  //   const validW = isNaN(newW) ? oldItem.w : newW;

  //   // Find the item being dragged
  //   const draggedIndex = currentWidget.custom_data?.findIndex((item) => item.name === newI);

  //   // Check for potential horizontal swaps
  //   const swapIndex = currentWidget.custom_data?.findIndex(
  //     (item) => item.name !== newI && item.position.x === newX && item.position.y === newY,
  //   );

  //   const updatedLayout = currentWidget.custom_data?.map((item, index) => {
  //     const { h: oldH, x: oldX, y: oldY, w: oldW } = item.position;

  //     if (index === draggedIndex) {
  //       // Update the dragged item's position with validated values
  //       hasChanged = validH !== oldH || newX !== oldX || newY !== oldY || validW !== oldW;
  //       return { ...item, position: { ...newItem, h: validH, w: validW } };
  //     } else if (index === swapIndex) {
  //       // Swap positions with the dragged item
  //       hasChanged = true;
  //       return { ...item, position: { ...item.position, x: oldItem.x, y: oldItem.y } };
  //     }
  //     return item;
  //   });

  //   console.log('updatedLayout', updatedLayout);

  //   currentWidget.custom_data = updatedLayout;

  //   // Update other widget properties if necessary
  //   if (currentWidget?.is_default) {
  //     currentWidget.is_default = false;
  //     delete currentWidget.id;
  //     delete currentWidget.user_id;
  //     delete currentWidget.title;
  //     currentWidget.user_id = user.id;
  //     currentWidget.title = 'Custom Layout - ' + widgetList.length;
  //   }

  //   // Save changes if the layout was updated
  //   if (hasChanged) await mutateAsync(currentWidget);
  // };
  const handleDragAndResizeStop = async (layout, oldItem, newItem, placeholder, e, node) => {
    const currentWidget = structuredClone(selectedWidget); // Avoid direct mutation
    let hasChanged = false;

    console.log({ handleWidgetUpdate: { oldItem, newItem, e } });

    // Extract and validate newItem values
    const { i: newI, h: newH = oldItem.h, x: newX, y: newY, w: newW = oldItem.w } = newItem;

    // Ensure valid dimensions and positions
    const validX = Math.max(0, newX);
    const validY = Math.max(0, newY);
    const validH = Math.max(1, newH);
    const validW = Math.max(1, newW);

    // Find the dragged widget
    const draggedIndex = currentWidget.custom_data?.findIndex((item) => item.name === newI);

    if (draggedIndex === -1) {
      console.error(`Widget with name ${newI} not found.`);
      return;
    }

    // Update the dragged widget's position
    const draggedWidget = {
      ...currentWidget.custom_data[draggedIndex],
      position: {
        ...currentWidget.custom_data[draggedIndex].position, // Retain other position properties
        x: validX,
        y: validY,
        w: validW,
        h: validH,
      },
    };

    // Replace the dragged widget with its updated version
    currentWidget.custom_data[draggedIndex] = draggedWidget;

    // Adjust layout for overlapping widgets and gaps
    const adjustedLayout = adjustLayout(currentWidget.custom_data);

    // Ensure sequential order based on position
    const finalLayout = adjustedLayout.map((widget, index) => ({
      ...widget,
      order: calculateOrder(widget.position, adjustedLayout),
    }));

    currentWidget.custom_data = finalLayout;

    // Check if layout has changed
    if (JSON.stringify(currentWidget.custom_data) !== JSON.stringify(selectedWidget.custom_data)) {
      hasChanged = true;
      console.log('Updated Layout:', currentWidget.custom_data);
      if (currentWidget?.is_default) {
        currentWidget.is_default = false;
        delete currentWidget.id, currentWidget.user_id, currentWidget.title;
        currentWidget.user_id = user.id;
        currentWidget.title = 'Custom Layout - ' + widgetList.length;
      }
      setSelectedWidget(currentWidget);
      await mutateAsync(currentWidget); // Save changes if there are updates
    } else {
      console.log('No changes detected.');
    }
  };

  // Adjust layout for overlaps and gaps
  const adjustLayout = (widgets) => {
    const sortedWidgets = [...widgets].sort(
      (a, b) => a.position.y - b.position.y || a.position.x - b.position.x, // Sort top-to-bottom, left-to-right
    );

    const occupied = new Set();

    return sortedWidgets.map((widget) => {
      const { x, y, w, h } = widget.position;

      // Find the lowest available y-position for the widget
      let newY = y;
      while (isOverlapping(newY, x, w, h, occupied)) {
        newY++;
      }

      // Mark the widget's grid space as occupied
      markOccupied(x, newY, w, h, occupied);

      return {
        ...widget,
        position: {
          ...widget.position,
          y: newY, // Update the widget's position to the adjusted position
        },
      };
    });
  };

  // Check if a widget overlaps any occupied grid cell
  const isOverlapping = (startY, x, w, h, occupied) => {
    for (let col = x; col < x + w; col++) {
      for (let row = startY; row < startY + h; row++) {
        if (occupied.has(`${col}-${row}`)) {
          return true;
        }
      }
    }
    return false;
  };

  // Mark the grid cells occupied by a widget
  const markOccupied = (x, y, w, h, occupied) => {
    for (let col = x; col < x + w; col++) {
      for (let row = y; row < y + h; row++) {
        occupied.add(`${col}-${row}`);
      }
    }
  };

  // Calculate order based on x and y position
  const calculateOrder = (position, widgets) => {
    const sortedWidgets = [...widgets].sort(
      (a, b) => a.position.y - b.position.y || a.position.x - b.position.x, // Sort by position
    );

    const order = sortedWidgets.findIndex(
      (widget) =>
        widget.position.x === position.x &&
        widget.position.y === position.y &&
        widget.position.w === position.w &&
        widget.position.h === position.h,
    );

    return order + 1; // Ensure 1-based order
  };

  const handleSubmit = async (formData) => {
    try {
      if (editWidgetModal.isEdit) {
        const updatedData = selectedWidget?.custom_data?.map((widget) => {
          if (widget.name === formData.name) {
            return {
              ...widget,
              position: formData.position,
            };
          }
          return widget;
        });
        selectedWidget.custom_data = updatedData;

        setEditWidgetModal({ editData: null, isEdit: false });
      } else {
        const customData = selectedWidget?.custom_data;

        delete formData.label, formData.value;
        formData.order = selectedWidget?.custom_data?.length + 1;
        customData?.push(formData);

        selectedWidget.custom_data = customData;
        selectedWidget.status = 1;
      }

      if (selectedWidget?.is_default) {
        selectedWidget.is_default = false;
        delete selectedWidget.id, selectedWidget.user_id, selectedWidget.title;
        selectedWidget.user_id = user.id;
        selectedWidget.title = 'Custom Layout - ' + widgetList.length;
      }

      await mutateAsync(selectedWidget); // Pass the form data to the mutation
    } catch (error) {
      console.error('Error during submission:', error);
    }
  };

  // handler for widget edit details - in which widget is need to be edited
  const handleEditWidget = (widgetId) => {
    console.log({ handleEditWidget: widgetId });
    const currentWidget = structuredClone(selectedWidget);
    const editWidget = currentWidget.custom_data?.filter((item) => item?.name == widgetId);
    console.log({ handleEditWidgeteditWidget: editWidget, currentWidget });
    setEditWidgetModal({ isEdit: true, editData: editWidget?.[0] });
  };
  //handle order change
  const handleOrderChange = async (newOrder, widgetName) => {
    console.log({ handleOrderChange: newOrder, widgetName });

    try {
      // Get the currently selected widget layout
      const currentWidget = structuredClone(selectedWidget);

      // Fetch the widget being reordered and the widget currently at the target order
      const reorderedWidget = currentWidget.custom_data?.find(
        (widget) => widget.name === widgetName,
      );
      const targetWidget = currentWidget.custom_data?.find((widget) => widget.order === newOrder);

      if (!reorderedWidget || !targetWidget) {
        console.error('Invalid order or widget not found');
        return;
      }

      // Update the orders of the affected widgets
      const updatedCustomData = currentWidget.custom_data.map((widget) => {
        if (widget.name === widgetName) {
          // Assign the new order to the reordered widget
          return { ...widget, order: newOrder };
        }
        if (widget.name === targetWidget.name) {
          // Assign the previous order to the target widget
          return { ...widget, order: reorderedWidget.order };
        }
        return widget;
      });

      // Sort widgets by order to ensure consistent rendering
      const sortedCustomData = updatedCustomData.sort((a, b) => a.order - b.order);

      // Update the custom data with the new order
      currentWidget.custom_data = sortedCustomData;

      // Persist the updated widget order to the server
      await mutateAsync(currentWidget);
    } catch (error) {
      console.error('Error updating widget order:', error);
    }
  };

  //handler for delete widget by id
  const handleDeleteWidget = (widgetId) => {
    setDeleteConfirmation({ isDelete: true, id: widgetId });
  };
  // handler for delete confirmation
  const handleRemove = async (name) => {
    setDeleteConfirmation({ id: null, isDelete: false });

    const currentWidget = structuredClone(selectedWidget);
    const removingItem = currentWidget.custom_data?.filter((item) => item?.name == name)?.[0];
    const updatedLayout = currentWidget.custom_data?.filter((item, index) => {
      if (item?.name != name) {
        if (index + 1 == removingItem.order) item.order = removingItem.order;
        return item;
      }
    });

    currentWidget.custom_data = updatedLayout;

    console.log({ currentWidget, name });
    await mutateAsync(currentWidget); // Pass the form data to the mutation
  };

  // return the order
  const OrderSelection = (itemName) => {
    const widgetByName = selectedWidget.custom_data?.filter((item) => {
      if (item.name == itemName) {
        return item;
      }
    });
    return widgetByName?.[0]?.order;
  };
  const navigateTo = (widgetName) => {
    if (widgetName === 'chats') {
      nav(`/${RT.CHAT}?tab=all`);
    } else if (widgetName === 'updates') {
      nav(`/${RT.NOTIFICATION}/${NOTIFICATION_TYPE.TASK_CHAT}`);
    } else if (widgetName === 'my_projects') {
      nav(`${RT.PROJECT_LIST}`);
    }
  };

  const orderItems = layout.map((item, index) => {
    return { value: index + 1, label: index + 1 };
  });
  if (isLoading || isDashboardLoading || widgetLoading) {
    return null;
  }

  return (
    <DashboardContext.Provider value={dashboardContextValue}>
      <section className='dashboard-layout'>
        <div className='notification-nav-contain'>
          <div className={'dashboard_menu_active cursor-pointer'}>
            <CircleDashed size={18} className='me-1' /> Overview
          </div>
          <section className='d-flex gap-1 mb-1'>
            {isEditMode && (
              <SearchableQuerySelect
                queryFn={miscService.getWidget}
                initialValue={widgetList}
                isCustomFunction
                value={selectedWidget || {}}
                isLoading={widgetLoading}
                queryKey='widgets'
                getOptionLabel={(option) => option?.title}
                getOptionValue={(option) => option?.id}
                classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal mr-1' }}
                styles={layoutDropdownStyle}
                components={{ DropdownIndicator: CustomDropdownIndicator }}
                placeholder={`Choose Layout`}
                onChange={(val) => {
                  handleChangeLayout(val);
                }}
              />
            )}
            <ToolTip tooltipText={'Dashboard'} isModern>
              <div
                className={`menu-item-outline-widget${!isEditMode ? ' active' : ''}`}
                onClick={() => setIsEditMode(false)}
              >
                <DashBoardDrawer
                  height={15}
                  width={15}
                  color={isEditMode ? '#87909E' : '#3454d1'}
                />
              </div>
            </ToolTip>
            <ToolTip tooltipText={'Edit'} isModern>
              <div
                className={`menu-item-outline-widget${isEditMode ? ' active' : ''}`}
                onClick={() => setIsEditMode(true)}
              >
                <Pen height={15} width={14} color={isEditMode ? '#3454d1' : '#87909E'} size={18} />
              </div>
            </ToolTip>
          </section>
        </div>
      </section>
      <ResponsiveGridLayout
        className='layout'
        layouts={savedLayouts}
        onLayoutChange={handleLayoutChange}
        onBreakpointChange={handleBreakpointChange}
        onDragStop={handleDragAndResizeStop}
        onResizeStop={handleDragAndResizeStop} // Add the resize handler
        cols={{ lg: 3, md: 3, sm: 2, xs: 1, xxs: 1 }}
        breakpoints={{ lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0 }}
        draggableHandle='.dashboard-header-container'
        resizeHandles={['se']}
        allowOverlap={false} // Allow overlap only in edit mode
        compactType={'vertical'} // Allow free dragging in edit mode
        width={'100%'}
        margin={{ lg: [20, 20], md: [20, 20], sm: [20, 20], xs: [20, 20], xxs: [20, 20] }}
        preventCollision={!isEditMode} // Prevent collision only when not in edit mode
        isDraggable={isEditMode}
        isResizable={isEditMode}
        rowHeight={10}
        // draggableCancel='.dashboard-header-container'
      >
        {layout?.map((item) => {
          let gridHeight = item?.h;
          // if (item.i === 'calendar') {
          //   const contentHeight = document?.getElementsByClassName('calendar-wrap');
          //   console.log({ contentHeight });

          //   gridHeight = contentHeight?.[0]?.offsetHeight
          //     ? Math.ceil(contentHeight?.[0]?.offsetHeight / 30)
          //     : item.h;
          // }
          const actualHeight = gridHeight * 10 + 20 * (gridHeight - 1);
          return (
            <div
              key={item?.i}
              data-grid={{
                ...item,
                h: gridHeight,
                isDraggable: isEditMode,
                isResizable: isEditMode,
              }}
              style={{
                '--grid-height': actualHeight + 'px',
                padding: item.i === widgetConstant.status_counts ? '0px' : '10px',
                background: item.i == widgetConstant.status_counts ? 'transparent' : 'white',
                height: 'auto',
                border: item.i === widgetConstant.status_counts ? 'none' : '',
              }}
            >
              <div className='dashboard-header-container' style={{ paddingBottom: '5px' }}>
                <div className='dashboard-widget-header'>{widgetTitles[item?.i]}</div>
                <div className='d-flex gap-2'>
                  <div>
                    {isEditMode && !selectedWidget.is_default && (
                      <section className='d-flex align-items-center gap-2'>
                        {/* <ReactSelect
                          value={orderItems.find(
                            (option) => option.value === OrderSelection(item.i),
                          )}
                          onChange={(selectedOption) =>
                            handleOrderChange(selectedOption.value, item.i)
                          }
                          styles={{
                            ...customFlagSelectBoxStyles,
                            control: (provided) => ({
                              ...provided,
                              width: 50,
                              border: 0,
                              fontSize: 12,
                              minHeight: 20,
                              cursor: 'pointer',
                            }),
                            menu: (provided) => ({
                              ...provided,
                              width: 70,
                            }),
                          }}
                          className='w-100'
                          options={orderItems}
                          menuPortalTarget={document.getElementById('MODAL')}
                          placeholder='Order'
                        /> */}

                        {deleteConfirmation.id != item.i && (
                          <div
                            className='menu-item-outline-widget'
                            onClick={() => setIsWidgetDropDown({ modal: true, key: item.i })}
                            style={{ zIndex: 9999 }}
                          >
                            {/* <Trash size={14} color='var(--Second-text-color)' /> */}
                            <Ellipsis size={18} color='var(--Second-text-color)' />
                          </div>
                        )}
                        {deleteConfirmation.isDelete && deleteConfirmation.id == item.i && (
                          <div className='d-flex gap-1'>
                            <div className='menu-item-outline-widget'>
                              <X
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setDeleteConfirmation({ id: null, isDelete: false });
                                }}
                                size={16}
                              />
                            </div>
                            <div className='menu-item-outline-widget'>
                              <Check
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleRemove(deleteConfirmation?.id);
                                }}
                                size={16}
                              />
                            </div>
                          </div>
                        )}
                      </section>
                    )}
                    {!isEditMode &&
                      (item.i === 'updates' || item.i === 'chats' || item.i === 'my_projects') && (
                        <ToolTip tooltipText={'Full view'} isModern>
                          <section className='pointer me-1' onClick={() => navigateTo(item.i)}>
                            <Maximize size={16} />
                          </section>
                        </ToolTip>
                      )}
                  </div>
                  {isWidgetDropDown.modal && isWidgetDropDown.key == item.i && (
                    <ContextMenu
                      positionClass='drop p-0 right-30px'
                      onRequestClose={() => {
                        setIsWidgetDropDown({ modal: false, key: null });
                      }}
                      isContextVisible={true}
                    >
                      <div className='dashboard-widget-dropdown'>
                        <section
                          className='dashboard-widget-dropdown-item'
                          onClick={() => handleEditWidget(item.i)}
                        >
                          <Pen size={12} /> Edit
                        </section>
                        <section
                          className='dashboard-widget-dropdown-item'
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            setIsWidgetDropDown({ modal: false, key: '' });
                            handleDeleteWidget(item.i);
                          }}
                        >
                          <Trash size={12} /> Delete
                        </section>
                      </div>
                    </ContextMenu>
                  )}
                </div>
              </div>
              <section className='widget'>{getDashBoardWidget(item?.i)}</section>
            </div>
          );
        })}
      </ResponsiveGridLayout>
      {layout.length == 0 && (
        <CustomEmptyContainer
          Icon={() => {
            return <LayoutDashboard />;
          }}
          text={TASK_LIST_EMPTY_MESSAGE.dashboard_empty}
          handleAdd={() => {
            setIsEditMode(true);
            handleOpenModal();
          }}
        />
      )}
      {isEditMode && (
        <>
          {layout?.length > 0 && (
            <button
              style={{ background: 'var(--Base-Color)' }}
              className='taskAdd-FAB'
              onClick={handleOpenModal}
            >
              <Plus color='#fff' />
            </button>
          )}
          {isWidgetModal && (
            <Modal.Container handleClose={handleCloseModal}>
              <Modal.View
                className={'widget-form-container'}
                containerClass={'d-flex justify-content-end'}
              >
                <AddWidgetModal
                  user={user}
                  isOpen={isWidgetModal}
                  onClose={handleCloseModal}
                  onSubmit={handleSubmit}
                  isSaving={isPending}
                  currentLayoutId={selectedWidget.id}
                />
              </Modal.View>
            </Modal.Container>
          )}
        </>
      )}
      {editWidgetModal.isEdit && (
        <Modal.Container handleClose={handleCloseModal}>
          <Modal.View
            className={'widget-form-container'}
            containerClass={'d-flex justify-content-end'}
          >
            <AddWidgetModal
              user={user}
              isOpen={editWidgetModal.isEdit}
              onClose={() => {
                setEditWidgetModal({ editData: null, isEdit: false });
              }}
              onSubmit={handleSubmit}
              isSaving={isPending}
              currentLayoutId={selectedWidget.id}
              editWidgetData={editWidgetModal.editData}
              isEdit={true}
            />
          </Modal.View>
        </Modal.Container>
      )}
    </DashboardContext.Provider>
  );
};

export default DashBoardCustom;
