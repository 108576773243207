/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import moment from 'moment';
import React, { useContext, useEffect, useState } from 'react';
import ReactSelect from 'react-select';
import { convertPriorityLabelToList, getSettingsByName, GlobalContext, taskFilter } from '../Utils';
import { Button, ToggleButton } from '../_components';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { MENU } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { clientService, projectService } from '../_services';
import { labelService } from '../_services/label.service';
import { templateService } from '../_services/template.service';
import { STATUS, TASK_TABS } from './taskConstants';

export const TaskListFilter = ({
  handleClose = () => {},
  setSearchParams,
  projectId,
  templateId,
  clientId,
  currentView = TASK_TABS.TASKS,
}) => {
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const priorityLabel = getSettingsByName(globalSettings, 'priority-label');
  const priorityLabelList = convertPriorityLabelToList(priorityLabel?.labels);

  const [templateSearch, setTemplateSearch] = useState('');
  const [projectSearch, setProjectSearch] = useState('');
  const debouncedTemplate = useDebounce(templateSearch, 500);
  const debounceProject = useDebounce(projectSearch, 500);
  const [filterData, setFilterData] = useState(taskFilter);
  const [isLoading, setIsLoading] = useState(false);
  let today = moment().format('YYYY-MM-DD');

  // const positionClassName = position === POSITION.RIGHT ? 'right-30px' : 'left-30px';

  const { data: templateData, isLoading: isTemplateLoading } = useQuery({
    queryKey: ['template', debouncedTemplate],
    queryFn: () =>
      templateService.getTemplateList({
        searchKey: templateSearch,
        select: ['id', 'name'],
        project_id: projectId,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const { data: projectData, isLoading: isProjectLoading } = useQuery({
    queryKey: ['projectList', debounceProject],
    queryFn: () =>
      projectService.projectListAdvanced({
        searchKey: projectSearch,
        select: ['id', 'name'],
        listFor: 'active-inactive',
        template_id: templateId,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  // Client list
  const { data: clientList, isLoading: isClientLoading } = useQuery({
    queryKey: ['clientListForTaskFilter'],
    queryFn: () => clientService.clientList({ select: ['id', 'name'] }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const { data: labelData } = useQuery({
    queryKey: ['labelList'],
    queryFn: () =>
      labelService.labelList({
        select: ['id', 'name', 'label_color', 'text_color'],
        pageVo: {
          // pageNo: parseInt(searchParams.get('pageNo')) || 1,
          noOfItems: 20,
        },
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  useEffect(() => {
    console.log('meeting');

    const fetchTemplatesByIds = async (ids) => {
      const promises = ids.map((id) =>
        templateService
          .getSingleTemplate({ id: id, select: ['id', 'name'] })
          .then((res) => res.data),
      );
      return Promise.all(promises);
    };

    const fetchProjectsByIds = async (ids) => {
      const promises = ids.map((id) =>
        projectService.projectGet({ id: id, select: ['id', 'name'] }).then((res) => res.data),
      );
      return Promise.all(promises);
    };

    const fetchClientsByIds = async (ids) => {
      const promises = ids.map((id) =>
        clientService.clientGet({ id: id, select: ['id', 'name'] }).then((res) => res.data?.[0]),
      );
      return Promise.all(promises);
    };

    const params = new URLSearchParams(window.location.search);
    const newFilterData = structuredClone(taskFilter);

    const templateIds = [];
    const projectIds = [];
    const clientIds = [];

    params.forEach((value, key) => {
      if (
        key === 'priority_id' ||
        key === 'label_id' ||
        key === 'template_id' ||
        key === 'project_id' ||
        key === 'client_id'
      ) {
        if (!newFilterData[key]) newFilterData[key] = [];
        newFilterData[key].push(isNaN(value) ? value : parseInt(value));
        if (key === 'template_id') {
          templateIds.push(parseInt(value));
        }
        if (key === 'project_id') {
          projectIds.push(parseInt(value));
        }
        if (key === 'client_id') {
          clientIds.push(parseInt(value));
        }
      } else if (key === 'task_start_date' || key === 'task_end_date') {
        const date = moment(parseInt(value));
        newFilterData[key] = date.format('YYYY-MM-DD');
      } else if (key === 'task_status') {
        newFilterData[key] = parseInt(value);
      } else if (key !== 'status') {
        newFilterData[key] = value;
      }
    });

    const updateFilterDataWithTemplatesAndProjects = async () => {
      if (templateIds.length > 0) {
        const templates = await fetchTemplatesByIds(templateIds);
        newFilterData.template_id = templates.map((template) => ({
          id: template[0].id,
          name: template[0].name,
        }));
      }

      if (projectIds.length > 0) {
        const projects = await fetchProjectsByIds(projectIds);
        newFilterData.project_id = projects.map((project) => ({
          id: project.id,
          name: project.name,
        }));
      }

      if (clientIds.length > 0) {
        const clients = await fetchClientsByIds(clientIds);
        newFilterData.client_id = clients.map((client) => ({
          id: client.id,
          name: client.name,
        }));
      }

      setFilterData(newFilterData);
    };

    updateFilterDataWithTemplatesAndProjects();
  }, []);

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === 'template_id' || name === 'project_id' || name === 'client_id') {
      console.log('client2', value);
      setFilterData((prev) => ({
        ...prev,
        [name]: value.map((item) => item),
      }));
      return;
    }

    setFilterData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleClickItem = (name, value) => {
    if (name === 'priority_id' || name === 'label_id') {
      const exists = filterData[name]?.includes(value);
      const updatedArray = exists
        ? filterData[name]?.filter((item) => item !== value)
        : [...filterData[name], value];

      setFilterData((prev) => ({
        ...prev,
        [name]: updatedArray,
      }));
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsLoading(true);

    // Get the current URL parameters
    const params = new URLSearchParams(window.location.search);

    // Remove the unwanted parameters
    // params.delete('pageNo');
    params.delete('task_start_date');
    params.delete('task_end_date');
    params.delete('priority_id');
    params.delete('label_id');
    params.delete('template_id');
    params.delete('project_id');
    params.delete('client_id');

    let project = filterData?.project_id?.map((item) => parseInt(item?.id));
    let template = filterData?.template_id?.map((item) => parseInt(item?.id));
    let client = filterData?.client_id?.map((item) => parseInt(item?.id));

    // Convert task_start_date and task_end_date to milliseconds if they exist
    const updatedFilterData = {
      ...filterData,
      project_id: project,
      template_id: template,
      client_id: client,
    };

    if (updatedFilterData?.task_start_date) {
      updatedFilterData.task_start_date = moment(
        updatedFilterData.task_start_date,
        'YYYY-MM-DD',
      ).valueOf();
    }
    if (updatedFilterData.task_end_date) {
      updatedFilterData.task_end_date = moment(
        updatedFilterData.task_end_date,
        'YYYY-MM-DD',
      ).valueOf();
    }

    // Add the filter data to the URL parameters
    Object.keys(updatedFilterData).forEach((key) => {
      if (Array.isArray(updatedFilterData[key])) {
        updatedFilterData[key].forEach((item) => params.append(key, item));
      } else if (updatedFilterData[key]) {
        params.set(key, updatedFilterData[key]);
      }
    });
    params.set('pageNo', 1);

    // Update the URL
    setSearchParams(params, { replace: true });
    setIsLoading(false);
    handleClose(event);
  };

  const handleClear = (event) => {
    const params = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();

    for (const [key, value] of params) {
      // Retain the parameters that should not be removed
      if (
        key === 'status' ||
        key === 'user_id' ||
        key === 'type' ||
        key === 'milestone_id' ||
        key === 'sort' ||
        key === 'list_type' ||
        key === 'search' ||
        key === 'currentView'
      ) {
        newParams.append(key, value);
      }
    }

    // Update the URL with the new parameters
    setSearchParams(newParams);
    // Clear the filter data
    setFilterData(taskFilter);
    handleClose(event);
  };

  const handleArchiveOption = () => {
    // Toggle between 1 and 2
    setFilterData((prevData) => ({
      ...prevData,
      task_status: prevData.task_status === STATUS.ACTIVE ? STATUS.ARCHIVE : STATUS.ACTIVE,
    }));
  };
  return (
    <div className={`menu_popup filter-container `} style={{ padding: 20, right: 0 }}>
      <form onSubmit={handleSubmit}>
        <div className='filter-head mb-2'>
          <h6 className='menu-item-head'>Filter</h6>
          <div
            className='menu-item-clear'
            style={{ fontSize: 12, cursor: 'pointer' }}
            onClick={handleClear}
          >
            Clear
          </div>
        </div>
        <div className='filter-body'>
          <div className=''>
            {!templateId && (
              <div className='row mb-1'>
                <div className='filter-item-name'>TEMPLATE</div>
                <ReactSelect
                  name='template_id'
                  options={templateData}
                  placeholder='Choose Template'
                  styles={{
                    control: (styles, { isFocused }) => ({
                      ...styles,
                      minHeight: '34px',
                      fontSize: '13px',
                      // borderColor: ' #879096',
                      borderColor: isFocused ? '#3454D1' : styles.borderColor,
                      boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
                      '&:hover': {
                        borderColor: isFocused
                          ? '#3454D1'
                          : styles['&:hover']?.borderColor || styles.borderColor,
                      },
                    }),
                    indicatorSeparator: () => ({
                      display: 'none',
                    }),
                    menu: (styles) => ({
                      ...styles,
                      width: '100%',
                    }),
                  }}
                  getOptionLabel={(option) => option?.name}
                  isMulti
                  getOptionValue={(option) => parseInt(option?.id)}
                  filterOption={null}
                  onInputChange={(inputString) => setTemplateSearch(inputString)}
                  inputValue={templateSearch}
                  value={filterData?.template_id}
                  onChange={(selectedOptions) => {
                    handleChange({
                      target: {
                        name: 'template_id',
                        value: selectedOptions,
                      },
                    });
                  }}
                  isLoading={isTemplateLoading}
                />
              </div>
            )}

            {!projectId && hasExist(MENU.project.default_name) && (
              <div className='row'>
                <div className=' filter-item-name text-uppercase'>{LABEL_OF_PROJECT}</div>
                <ReactSelect
                  name='project_id'
                  options={projectData}
                  placeholder={`Choose ${LABEL_OF_PROJECT}`}
                  styles={{
                    control: (styles, { isFocused }) => ({
                      ...styles,
                      minHeight: '34px',
                      fontSize: '13px',
                      // borderColor: ' #879096',
                      borderColor: isFocused ? '#3454D1' : styles.borderColor,
                      boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
                      '&:hover': {
                        borderColor: isFocused
                          ? '#3454D1'
                          : styles['&:hover']?.borderColor || styles.borderColor,
                      },
                    }),
                    indicatorSeparator: (styles) => ({
                      display: 'none',
                    }),
                    menu: (styles) => ({
                      ...styles,
                      width: '100%',
                    }),
                  }}
                  isMulti
                  getOptionLabel={(option) => option?.name}
                  getOptionValue={(option) => parseInt(option?.id)}
                  filterOption={null}
                  onInputChange={(inputString) => setProjectSearch(inputString)}
                  inputValue={projectSearch}
                  value={filterData?.project_id}
                  onChange={(selectedOptions) => {
                    handleChange({
                      target: {
                        name: 'project_id',
                        value: selectedOptions,
                      },
                    });
                  }}
                  isLoading={isProjectLoading}
                />
              </div>
            )}
          </div>

          {!clientId && !projectId && hasExist(MENU.contact.default_name) && (
            <div className='row'>
              <div className='filter-item-name text-uppercase'>{LABEL_OF_CLIENT}</div>
              <SearchableQuerySelect
                queryKey='client-list-task'
                queryFn={clientService.clientList}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  select: ['id', 'name'],
                })}
                initialValue={clientList}
                className={` client-select-box `}
                isCustomFunction
                styles={{
                  control: (styles, { isFocused }) => ({
                    ...styles,
                    minHeight: '34px',
                    fontSize: '13px',
                    borderColor: isFocused ? '#3454D1' : styles.borderColor,
                    boxShadow: isFocused ? '0 0 0 1px #3454D1' : styles.boxShadow,
                    '&:hover': {
                      borderColor: isFocused
                        ? '#3454D1'
                        : styles['&:hover']?.borderColor || styles.borderColor,
                    },
                  }),
                  indicatorSeparator: (styles) => ({
                    display: 'none',
                  }),
                  menu: (styles) => ({
                    ...styles,
                    width: '100%',
                  }),
                }}
                isMulti
                isLoading={isClientLoading}
                placeholder={`Select ${LABEL_OF_CLIENT}`}
                getOptionLabel={(option) => option?.name}
                getOptionValue={(option) => option.id}
                value={filterData?.client_id}
                onChange={(selectedOptions) => {
                  handleChange({
                    target: {
                      name: 'client_id',
                      value: selectedOptions,
                    },
                  });
                }}
              />
            </div>
          )}

          {/* archive task list filter  */}
          {/* {currentView === TASK_TABS.TASKS && ( */}
          <>
            {currentView === TASK_TABS.TASKS && (
              <div className='d-flex justify-content-between my-2 filter-item-name fz-16px'>
                Archive Tasks
                <ToggleButton
                  key={1}
                  id={1}
                  isChecked={filterData?.task_status === STATUS.ARCHIVE}
                  // isChecked={expandAll ? 1 : 0}
                  handleToggle={() => handleArchiveOption()}
                  size={25}
                  // disabled={currentView != TASK_TABS.TASKS}
                />
              </div>
            )}
            <div className='row'>
              <span className='filter-item-name'>DATE</span>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 menu-item-clear'>From</label>
                  <input
                    type='date'
                    className='input-box'
                    style={{ height: 34, fontSize: 13 }}
                    placeholder={today}
                    value={moment(filterData?.task_start_date).format('YYYY-MM-DD')}
                    name='task_start_date'
                    onChange={(e) => handleChange(e)}
                  />
                </div>
              </div>
              <div className='col-md-6'>
                <div>
                  <label className='mb-1 menu-item-name'>To</label>
                  <div>
                    <input
                      type='date'
                      className='input-box'
                      style={{ height: 34, fontSize: 13 }}
                      placeholder={today}
                      min={moment(filterData?.task_start_date).format('YYYY-MM-DD')}
                      value={moment(filterData?.task_end_date).format('YYYY-MM-DD')}
                      name='task_end_date'
                      onChange={(e) => handleChange(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div>
              <span className='filter-item-name'>PRIORITY</span>
              <div className='labels-container'>
                {priorityLabelList.map((item, index) => (
                  <div
                    className='priority-item'
                    key={index}
                    onClick={() => handleClickItem('priority_id', parseInt(item.value))}
                    style={{
                      color: filterData?.priority_id?.includes(parseInt(item.value))
                        ? '#3454D1'
                        : '#87909E',
                    }}
                  >
                    {item?.text}
                  </div>
                ))}
              </div>
            </div>
            <div className=''>
              <span className='filter-item-name'>LABEL</span>
              <div className='labels-container'>
                {labelData?.map((item, index) => (
                  <div
                    className='label-item-filter'
                    key={index}
                    onClick={() => handleClickItem('label_id', parseInt(item?.id))}
                    style={{
                      background: item?.label_color,
                      color: item?.text_color,
                      border: filterData?.label_id?.includes(parseInt(item?.id))
                        ? `1px solid ${item?.text_color}`
                        : '',
                    }}
                  >
                    {item?.name}
                  </div>
                ))}
              </div>
            </div>
          </>
          {/* )} */}
        </div>

        <div className='row mt-3'>
          <div className='d-flex justify-content-end gap-2'>
            <Button.Container
              className={'outline-cancel-button'}
              type='button'
              handleOk={handleClose}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' className='blue-btn' isDisabled={isLoading}>
              <Button.Title title={'Apply'} />
              <Button.Loading />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
};
