/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { Check, Copy, Flag, MessageCircleMore, Pause, Play } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import ReactSelect, { components } from 'react-select';
import { Td, Tr } from 'react-super-responsive-table';
import { Archive } from '../../Archive/Archive';
import { useUser } from '../../Hook/useUser';
import { GlobalContext, Images, TaskListIcon, updatedGetTaskListActionData } from '../../Utils';
import { ChatIcon, CompletedDot, ParentTaskIcon } from '../../Utils/SvgIcons';
import { Button } from '../../_components';
import StackedAvatars from '../../_components/Avatar/StackedAvatars';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { RT } from '../../_constants';
import { POSITION } from '../../_constants/contextMenu.constants';
import { TASK_TYPES } from '../../_constants/template.constants';
import { miscService, taskService } from '../../_services';
import { statusService } from '../../_services/status.service';
import { StatusOption } from '../TaskComponents/CustomInputs';
import { TaskDelete } from '../TaskComponents/TaskDelete';
import {
  CustomStatusSelectBoxStylesTaskList,
  STATUS_ACTION,
  STATUS_NAME,
  taskButtonLabel,
  taskButtonName,
} from '../taskConstants';
import { getLabelIsActive } from '../taskValidation';
// import { ChatIcon } from '../../Utils/SvgIcons';

export function TaskTableBody({
  taskData,
  expandedRows,
  toggleRow,
  refetch,
  tableContainerRef,
  handleDuplicate = () => {},
  urlQuery,
  expandAll,
  setExpandAll,
  totalTaskCount,
  projectId = null,
  templateId = null,
  isArchivedList = false,
}) {
  const navigate = useNavigate();
  const [params] = useSearchParams();
  // eslint-disable-next-line no-unused-vars
  const [searchParams, setSearchParams] = useSearchParams();
  const { makeAlert, permissionsMap } = useContext(GlobalContext);

  const loggedUser = useUser().id;
  const [taskStatusUpdateLoading, setTaskStatusUpdateLoading] = useState({
    isLoad: false,
    taskId: null,
  });

  // const selectedStatus = searchParams.get('status')

  const { globalSettings } = useContext(GlobalContext);

  const priorityLabels =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings.name === 'priority-label')?.labels;

  // for get settings label value and subtask value - get values by context API
  const isMenuLabelOn = getLabelIsActive();

  const convertToEditorState = (desc) => {
    const regex = /<[^>]*>/g;

    if (regex.test(desc)) {
      // Create a temporary element to parse the HTML
      const tempElement = document.createElement('div');
      tempElement.innerHTML = desc;

      // Extract all text within the temporary element
      const allText = tempElement.textContent;
      return allText;
    } else {
      return desc;
    }
    // return JSON.parse(desc);
  };

  const { currentTaskRefetch } = useContext(GlobalContext);

  // handle task action
  const handleAction = async (data, id = null) => {
    if (taskStatusUpdateLoading.isLoad) {
      return;
    }
    setTaskStatusUpdateLoading({ isLoad: true, taskId: id ? parseInt(id) : parseInt(data?.id) });
    try {
      if (id) {
        data.id = parseInt(id);
      }
      const res = await taskService.updateStatus(data);
      if (res?.data?.default_name) {
        searchParams.set('status', res?.data?.default_name);
        searchParams.set('pageNo', 1);
        setSearchParams(searchParams);
      }
      // currentTaskRefetch();
      // refetch();
      await Promise.all([currentTaskRefetch(), refetch()]);
      // setTaskStatusUpdateLoading({ isLoad: false, taskId: null });
    } catch (e) {
      // setTaskStatusUpdateLoading({ isLoad: false, taskId: null });
      makeAlert(e?.message);
    } finally {
      setTaskStatusUpdateLoading({ isLoad: false, taskId: null });
    }
  };

  const getPriorityName = (priorityId) => {
    return priorityLabels && priorityLabels[priorityId]?.text;
  };

  const handleToggleRow = (taskId) => {
    if (expandAll) {
      // If in expand all mode and clicking to collapse a row
      const newExpandedRows = new Map(taskData.map((task) => [task.id, task.id !== taskId]));
      setExpandAll(false);
      toggleRow(newExpandedRows);
    } else {
      // Normal toggle behavior
      const newExpandedRows = new Map(expandedRows);
      newExpandedRows.set(taskId, !newExpandedRows.get(taskId));
      toggleRow(newExpandedRows);

      // Check if all rows are now expanded
      const allExpanded = taskData.every((task) => newExpandedRows.get(task.id));
      if (allExpanded) {
        setExpandAll(true);
      }
    }
  };

  const pathName = window.location.pathname;
  // let prePageParam = '';
  // useMemo(() => {
  //   const pathParams = pathName.split('/');
  //   const pathState = pathParams?.[1];
  //   if (pathState == 'project') {
  //     prePageParam = `${pathState} = ${pathParams[2]}&&prevPath =${pathParams[3]}`;
  //   } else if (pathState == 'template') {
  //     prePageParam = `${pathState} = ${pathParams[2]}`;
  //   }
  // }, [pathName]);
  //

  let tableData = taskData?.map((task, index) => {
    // const slNo = (taskData?.data?.page - 1) * taskData?.data?.limit + index + 1;
    // const currentIndex = (taskData?.data?.page - 1) * taskData?.data?.limit + index || 0;
    const slNo = index + 1;
    const currentIndex = index || 0;
    const priorityName = getPriorityName(task?.priority);
    const action = task?.status_actions?.[0];
    const isNonTimeBound = task?.task_type === 3;
    const isTaskStartByOwner = task?.is_task_ongoing;
    const isTaskOnProgress = !action && !isTaskStartByOwner;
    const isTaskOnTodo = !action && task?.status_default_name === STATUS_NAME.TODO;

    // const actionPossible = !task?.has_only_visibility && !isTaskStartByOwner && !action;
    const actionPossible = !task?.has_only_visibility;
    const isTaskOwner = loggedUser == parseInt(task?.user_details?.id);
    const tab =
      isTaskOwner && task?.task_type != TASK_TYPES.NON_TIME_BOUND_TASK ? 'subtask' : 'description';
    const isExpanded = expandedRows?.get(task?.id) || expandAll;
    return (
      <Tr key={task?.id}>
        <Td className=''>
          <div className='d-flex gap-2'>
            <div
              onClick={
                () => handleToggleRow(task.id)
                // toggleRow(index)
              }
              className='pointer'
            >
              {!isExpanded ? (
                <Play color='#87909E' fill='#87909E' size={10} />
              ) : (
                <img src={Images.PlayDown} />
              )}
            </div>
            {slNo}
          </div>
        </Td>
        <Td
          className='pointer task-title'
          // onClick={() => navigate(`/${RT.TASK}/${task?.id}?tab=${tab}&&${params}`)}
          // let url = `/${RT.TASK}/${task?.id}?tab=${tab}&&${params}&&${urlQuery}&&currentIndex=${currentIndex}
          // &&nextAndPrevFromTaskList=true&&taskTotalCount=${totalTaskCount}&&prevpath=${pathName}`;

          onClick={() => {
            let url = `/${RT.TASK}/${task?.id}?tab=${tab}&&${params}&&currentIndex=${currentIndex}
          &&nextAndPrevFromTaskList=true&&taskTotalCount=${totalTaskCount}&&prevpath=${pathName}`;
            if (urlQuery) {
              url += `&&${urlQuery}`;
            }
            if (projectId) {
              url += `&&project_id=${projectId}`;
            }
            if (templateId) {
              url += `&&template_id=${templateId}`;
            }

            navigate(url);
          }}
        >
          {!isExpanded ? (
            <div>
              {task?.parent_id && parseInt(task?.parent_id) ? (
                <span className='d-flex gap-2 align-items-center parent-task-name'>
                  <span>
                    <ParentTaskIcon />
                  </span>
                  <div>{task?.parent_task_name}</div>
                </span>
              ) : (
                ''
              )}
              <div className='d-flex align-items-center gap-4 '>
                <div className='overflow-multiline task-list-name'>
                  <span className='two-line-title gap-1'>
                    {/* {task?.parent_id && parseInt(task?.parent_id) ? (
                      <span>
                        <ChildTreeDownIcon height={15} width={13} />
                      </span>
                    ) : (
                      ''
                    )} */}
                    {task?.name}
                  </span>
                </div>
                {task?.last_message_details?.unread_count > 0 && (
                  <div className='d-flex gap-1 align-items-center'>
                    <ChatIcon />
                    <div className='message-count'>{task?.last_message_details?.unread_count} </div>
                  </div>
                )}
              </div>
            </div>
          ) : (
            <>
              {task?.parent_id && parseInt(task?.parent_id) ? (
                <span className='d-flex gap-2 align-items-center parent-task-name'>
                  <span>
                    <ParentTaskIcon />
                  </span>
                  <div>{task?.parent_task_name}</div>
                </span>
              ) : (
                ''
              )}
              <div className='overflow-multiline '>
                <span className='d-flex align-items-center gap-1'>
                  {/* {task?.parent_id && parseInt(task?.parent_id) ? (
                    <span>
                      <ChildTreeDownIcon height={15} width={13} />
                    </span>
                  ) : (
                    ''
                  )} */}
                  {task?.name}
                </span>
              </div>
              {task?.recent_chat_message?.length > 0 && (
                <div style={{ margin: '5px 0' }} className='text-capitalize'>
                  <MessageCircleMore size={20} color='#808080' />
                  {task?.recent_chat_message?.length > 140
                    ? convertToEditorState(task?.recent_chat_message).substring(0, 140) + '...'
                    : convertToEditorState(task?.recent_chat_message)}
                </div>
              )}
              {task?.last_message_details?.last_message && (
                <div className='task-list-chat'>
                  <img src={TaskListIcon.Message} alt='' />
                  {loggedUser == parseInt(task?.last_message_details?.user_details?.id)
                    ? 'You'
                    : task?.last_message_details?.user_details?.first_name}{' '}
                  :{task?.last_message_details?.last_message}
                  {task?.last_message_details?.unread_count > 0 && (
                    <div className='message-count'>
                      {' '}
                      {task?.last_message_details?.unread_count}{' '}
                    </div>
                  )}
                </div>
              )}
              {Boolean(task?.note) && (
                <div className='task-list-description'>
                  {task?.note?.length > 140
                    ? convertToEditorState(task?.note).substring(0, 140) + '...'
                    : convertToEditorState(task?.note)}
                </div>
              )}

              {Boolean(task?.milestone_name) && (
                <span
                  className='priority-label fz-11px-rem mt-1'
                  style={{ padding: '2px 7px', borderRadius: '4px' }}
                >
                  {task?.milestone_name}
                </span>
              )}

              {(task?.label_details?.length > 0 || Boolean(task?.order_seq)) && isMenuLabelOn && (
                <div style={{ fontSize: 10, display: 'flex', gap: '10px', margin: '8px 0' }}>
                  {task?.order_seq > 0 && task?.order_seq < 1000 && (
                    <span
                      style={{
                        fontSize: 14,
                        fontWeight: 600,
                        display: 'flex',
                        alignItems: 'center',
                        gap: 2,
                      }}
                    >
                      <Flag size={13} color='#BC4841' />
                      {task?.order_seq}
                    </span>
                  )}

                  <span style={{ display: 'flex', gap: '5px' }}>
                    {task?.label_details?.map((item, idx) => (
                      <div
                        key={idx}
                        className='label-item-task-list'
                        style={{ background: item?.label_color, color: item?.text_color }}
                      >
                        {item.name} {/* Display the name property of the label object */}
                      </div>
                    ))}
                  </span>
                </div>
              )}
            </>
          )}
        </Td>
        {!isArchivedList ? (
          <Td>
            {(action || isNonTimeBound || isTaskOnProgress || isTaskOnTodo) && actionPossible && (
              <TaskAction
                action={action}
                onSave={(data) => handleAction(data, task?.id)}
                isNonTimeBound={isNonTimeBound}
                task={task}
                isTaskOnProgress={isTaskOnProgress}
                isTaskOnTodo={isTaskOnTodo}
                taskStatusUpdateLoading={taskStatusUpdateLoading}
              />
            )}

            {isTaskStartByOwner && !action && (
              <div className='d-flex align-items-center gap-2 action-button '>
                <CompletedDot color='#1CA977' size={7} /> Started
              </div>
            )}

            {!((action || isNonTimeBound || isTaskOnProgress || isTaskOnTodo) && actionPossible) &&
              !isTaskStartByOwner &&
              updatedGetTaskListActionData(
                task?.status_default_name,
                task?.current_status?.[0]?.color,
                task?.current_status?.[0]?.name === STATUS_NAME.DONE
                  ? task?.status_name
                  : task?.current_status?.[0]?.name,
              )}
            {}
          </Td>
        ) : (
          <Td>
            <div style={{ width: 'fit-content' }}>
              <Archive
                taskId={task?.id}
                status={task?.status}
                refetch={refetch}
                tableContainerRef={tableContainerRef}
              />
            </div>
          </Td>
        )}

        {/* {isArchivedList && (
          <Td>
            <div style={{ width: 'fit-content' }}>
              <Archive
                taskId={task?.id}
                status={task?.status}
                refetch={refetch}
                tableContainerRef={tableContainerRef}
              />
            </div>
          </Td>
        )} */}
        <Td>
          <StackedAvatars
            justifyPosition={' '}
            getSourceByKey={(task) =>
              task?.compressed_image_url ? task?.compressed_image_url : task?.image_url
            }
            getNameByKey={(task) => task?.first_name}
            getGenderByKey={(task) => task?.gender === 1}
            imageList={[task?.user_details]}
            showInitials={false}
            size={28}
            tooltipPosition={POSITION.LEFT}
            tooltipPopOver
            scrollRefForTooltip={tableContainerRef}
          />
        </Td>
        <Td> {priorityName && <span className='priority-label'>{priorityName}</span>}</Td>
        <Td>
          <span>{moment(parseInt(task?.task_start_date)).format('MMM DD, YYYY')}</span>
        </Td>
        <td>
          <span
            style={{
              color:
                task?.status_default_name === STATUS_NAME.done
                  ? '#1CA977'
                  : task?.days_difference < 0
                  ? '#EA4D4D'
                  : '',
            }}
            className='table-due-date'
          >
            {task?.duration}
          </span>
        </td>
        <td>
          {/* only current user task can duplicate */}
          <div className='d-flex gap-1'>
            {action && (
              <div
                className='d-flex gap-4 justify-content-end icon-div invisible'
                style={{ width: 'fit-content' }}
              >
                <ToolTip tooltipText={'Duplicate'} isModern popOver scrollRef={tableContainerRef}>
                  <div className='subtask-icon-bg' onClick={() => handleDuplicate(task)}>
                    <Copy size={13} />
                  </div>
                </ToolTip>
              </div>
            )}
            {permissionsMap.task_delete && (
              <div className='icon-div invisible' style={{ width: 'fit-content' }}>
                <TaskDelete
                  taskId={task?.id}
                  refetch={refetch}
                  isTaskStarted={task?.is_task_ongoing}
                  tableContainerRef={tableContainerRef}
                  actionFrom={'list'}
                />
              </div>
            )}
          </div>
        </td>
      </Tr>
    );
  });
  return tableData;
}

const TaskAction = ({
  action,
  onSave,
  isNonTimeBound = false,
  task,
  // isTaskStartByOwner,
  // actionPossible,
  isTaskOnProgress,
  isTaskOnTodo,
  taskStatusUpdateLoading,
}) => {
  if (isNonTimeBound || isTaskOnProgress || isTaskOnTodo) {
    const [dropDownActionsList, setDropDownActionsList] = useState([]);

    const selectedDropDownItem = {
      label: task?.status_name,
      selected: true,
      value: task?.status_id,
      status_group_item_id: task?.status_group_item_id,
      color: task?.current_status?.[0]?.color,

      // label: task?.status_name,
      // value: task?.status_id,
      // status_group_item_id: task?.status_group_item_id,
    };

    const fetchStatusList = async () => {
      const statusData = await statusService.getStatusListNonTimeBound({
        task_id: task?.id,
        select: ['color', 'name', 'id'],
      });

      const statuses = statusData?.data.reduce((acc, currentItem) => {
        const { type, inputs } = currentItem;

        const updatedInputs = inputs.map((item) => ({ ...item, type }));
        return acc.concat(updatedInputs);
      }, []);

      // const statuses = statusData?.data
      //   .filter((item) => item?.type === STATUS_ACTION.DROPDOWN)
      //   .flatMap((item) => item?.inputs || []);
      const uniqueStatusData = statuses.filter((newItem) => {
        return (
          newItem.type !== STATUS_ACTION.DROPDOWN ||
          parseInt(selectedDropDownItem.status_group_item_id) !==
            parseInt(newItem.status_group_item_id)
        );
      });
      setDropDownActionsList((prev) => [...prev, ...uniqueStatusData]);
      return statusData;
    };

    const { isLoading, refetch, data } = useQuery({
      queryKey: ['statusList', task?.id],
      queryFn: fetchStatusList,
      enabled: false,
      // enabled: dropDownActionsList?.length === 1 && !isLoading, // Disable automatic fetching
      onError: (error) => {
        console.error('Failed to fetch status list:', error);
      },
      select: (res) => res.data,
    });

    const handleMenuOpen = () => {
      if (dropDownActionsList?.length <= 0) {
        refetch();
      }
    };

    const updateStatusData = (selectedOption) => {
      const selectedOptionWithIntValue = {
        ...selectedOption,
        value: parseInt(selectedOption.value, 10),
        status_group_item_id: parseInt(selectedOption.status_group_item_id, 10),
      };
      setDropDownActionsList((prev) =>
        prev.map((item) =>
          item.status_group_item_id === selectedOption.status_group_item_id
            ? { ...item, selected: true }
            : { ...item, selected: false },
        ),
      );

      const selectedStatusForSave = {
        ...selectedOptionWithIntValue,
        type: STATUS_ACTION.DROPDOWN,
        id: task?.id,
      };
      return selectedStatusForSave;
    };

    return (
      <div role='button' className={`col d-flex align-items-center }`}>
        <ReactSelect
          options={dropDownActionsList}
          // value={dropDownActionsList.find(
          //   (status) =>
          //     parseInt(status?.status_group_item_id) ===
          //     parseInt(selectedDropDownItem?.status_group_item_id),
          // )}
          value={selectedDropDownItem}
          isLoading={isLoading}
          onMenuOpen={handleMenuOpen}
          isDisabled={task?.status_disabled || data?.length === 0}
          // openMenuOnClick={isSuccess}
          defaultMenuIsOpen={false}
          menuPortalTarget={document.getElementById('MODAL')}
          onChange={(selectedOption) => {
            // if (
            //   selectedOption?.status_group_item_id !== selectedDropDownItem?.status_group_item_id
            // ) {
            const updatedStatusData = updateStatusData(selectedOption);

            onSave(updatedStatusData);
            // }
          }}
          components={{
            Option: CustomOptionActionList,
          }}
          styles={{
            ...CustomStatusSelectBoxStylesTaskList,
            control: (style, state) => ({
              ...style,
              height: 34,
              minHeight: 34,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderRadius: 4,

              border: state.isFocused ? '1px solid  #4a6dc6' : '1px solid  #F1F1F1',
              background: miscService.hexToRgbAndReduceIntensity(selectedDropDownItem.color, 20),
              padding: '0 2px 0 2px',
              minWidth: 130,
              cursor: 'pointer',
              boxShadow: state.isFocused ? '0 0 0 1px ' + selectedDropDownItem.color : 'none',
            }),
          }}
          formatOptionLabel={(data, metaData) => {
            return (
              <StatusOption
                data={data}
                metaData={metaData}
                isLoading={
                  isLoading ||
                  (taskStatusUpdateLoading.isLoad &&
                    taskStatusUpdateLoading?.taskId == parseInt(task?.id))
                }
              />
            );
          }}
        />
      </div>
    );
  }

  if (action?.type === STATUS_ACTION.DROPDOWN) {
    // eslint-disable-next-line no-unused-vars
    const [dropDownActionsList, setDropDownActionsList] = useState([
      {
        label: task?.status_name,
        selected: true,
        value: task?.status_id,
        status_group_item_id: task?.status_group_item_id,
        color: task?.current_status?.[0]?.color,
      },
    ]);

    const selectedDropDownItem = {
      label: task?.status_name,
      value: task?.status_id,
      status_group_item_id: task?.status_group_item_id,
    };
    const fetchStatusList = async () => {
      const statusData = await statusService.getStatusListNonTimeBound({
        task_id: task?.id,
        select: ['color', 'name', 'id'],
      });
      const statuses = statusData?.data
        .filter((item) => item?.type === STATUS_ACTION.DROPDOWN)
        .flatMap((item) => item?.inputs || []); // Flatten the array of arrays and handle potential undefined inputs
      const defaultInputs =
        task.status_actions?.[0]?.input?.map((item) => ({
          ...item,
          type: STATUS_ACTION.BUTTON,
        })) ?? [];
      const mergedStatuses = [...defaultInputs, ...statuses];

      setDropDownActionsList([...mergedStatuses]);
    };

    const { isLoading, refetch } = useQuery({
      queryKey: ['statusList', task?.id],
      queryFn: fetchStatusList,
      enabled: false,
      // enabled: dropDownActionsList?.length === 1 && !isLoading, // Disable automatic fetching
      onError: (error) => {
        console.error('Failed to fetch status list:', error);
      },
    });

    const handleMenuOpen = () => {
      if (dropDownActionsList?.length === 1) {
        refetch();
      }
    };

    return (
      <div
        role='button'
        className={`col d-flex align-items-center ${dropDownActionsList?.length === 0 && 'd-none'}`}
      >
        <ReactSelect
          options={dropDownActionsList}
          value={dropDownActionsList.find(
            (status) =>
              parseInt(status?.status_group_item_id) ===
              parseInt(selectedDropDownItem?.status_group_item_id),
          )}
          onMenuOpen={handleMenuOpen}
          isDisabled={task?.status_disabled}
          onChange={(selectedOption) => {
            // Create a shallow copy of the selectedOption with the added type for onSave
            const selectedForSave = {
              ...selectedOption,
              type:
                selectedOption.type === STATUS_ACTION.BUTTON
                  ? STATUS_ACTION.BUTTON
                  : STATUS_ACTION.DROPDOWN, //we are handling button as dropdown here
              id: task?.id,
            };
            // If there is no value we don't want it.
            if (!selectedOption?.value) {
              delete selectedForSave.value;
            }

            onSave(selectedForSave);
          }}
          components={{ Option: CustomOptionActionList }}
          styles={{
            ...CustomStatusSelectBoxStylesTaskList,
            control: (style, state) => ({
              ...style,
              height: 30,
              minHeight: 30,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              borderRadius: 4,
              border: state.isFocused ? '1px solid  #4a6dc6' : '1px solid  #F1F1F1',
              background: '#FFF',
              padding: '0 2px 0 2px ',
              minWidth: 130,
              cursor: 'pointer',
              boxShadow: state.isFocused ? '0 0 0 1px #4a6dc6' : 'none', // Optional: set box shadow to red when focused
            }),
          }}
          menuPortalTarget={document.getElementById('MODAL')}
          formatOptionLabel={(data, MetaData) => {
            return (
              <StatusOption
                data={data}
                metaData={MetaData}
                isLoading={
                  isLoading ||
                  (taskStatusUpdateLoading.isLoad &&
                    taskStatusUpdateLoading?.taskId == parseInt(task?.id))
                }
              />
            );
          }}
        />
      </div>
    );
  }

  if (action?.type === STATUS_ACTION.BUTTON) {
    const data = action?.input?.[0];

    return (
      <Button.Container
        className={
          data?.name === taskButtonName.STOP
            ? 'action-button action-button-stop'
            : 'action-button action-start-button'
        }
        key={data?.label}
        handleOk={() => onSave({ ...data, type: action?.type })}
        isDisabled={data?.disabled}
      >
        {data?.name === taskButtonName.STOP ? (
          data?.label === taskButtonLabel.PAUSE ? (
            <Pause fill='#ff3807' size={12} color='#ff3807' />
          ) : (
            <CompletedDot color='#ff3807' />
          )
        ) : (
          <Play fill='#546272' size={10} color='#546272' />
        )}
        <Button.Title
          title={data?.label}
          className={
            data?.name === taskButtonName.STOP
              ? 'action-button-stop-text  mb-0'
              : 'action-button-text action-button-start-text  mb-0'
          }
        ></Button.Title>
        <Button.Loading
          size={18}
          color='#2A2E34'
          isLoading={
            taskStatusUpdateLoading?.isLoad && taskStatusUpdateLoading?.taskId == parseInt(task?.id)
          }
        />
      </Button.Container>
    );
  }
  // if (isTaskStartByOwner) {
  //   return (
  //     <div className='d-flex align-items-center gap-2 '>
  //       <CompletedDot color='#1CA977' /> Started
  //     </div>
  //   );
  // }

  return null;
};

// Custom Option Component to include a tick mark
export const CustomOptionActionList = (props) => {
  if (props.data.isDisabled) {
    return null;
  }

  return (
    <components.Option {...props}>
      {props.children}
      {props.data.selected && (
        <Check
          style={{
            position: 'absolute',
            right: 10, // Right align the checkmark
          }}
          size={16}
          color={'#4a6dc6'} // Match the color of the tick mark to the selected item color
        />
      )}
    </components.Option>
  );
};
