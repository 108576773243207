import {
  Calendar,
  CalendarClock,
  GanttChartSquare,
  LineChart,
  Newspaper,
  NotepadText,
  PieChart,
} from 'lucide-react';
import React from 'react';
import { ChatIcon, ProjectIconSidebar } from '../../Utils/SvgIcons';

export const widgetIcons = {
  calendar: <Calendar color='White' size={18} />,
  upcoming_deadlines: <CalendarClock color='White' size={18} />,
  workload_statics: <PieChart color='White' size={18} />,
  graphical_report: <LineChart color='White' size={18} />,
  my_projects: <ProjectIconSidebar mainColor='white' secondaryColor='white' size={18} />,
  chats: <ChatIcon color='white' height={18} width={18} />,
  updates: <Newspaper color='White' size={18} />,
  task_statics: <NotepadText color='white' size={18} />,
  status_counts: <GanttChartSquare color='White' size={18} />,
};

export const layoutDropdownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('field-error');
    return {
      ...styles,
      padding: '0px 1px 0px 4px',
      // height: '34px',
      minHeight: '25px',
      height: '25px',
      borderColor: hasError ? '#EA4D4D' : isFocused ? '#3454D1' : '#87909E',
      boxShadow:
        isFocused && !hasError
          ? '0 0 0 3px rgba(52, 84, 209, 0.25)'
          : hasError && isFocused
          ? ' 0 0 0 3px rgba(255, 0, 0, 0.15)'
          : styles.boxShadow,
      '&:hover': {
        borderColor:
          isFocused && !hasError
            ? '#3454D1'
            : hasError
            ? '#EA4D4D'
            : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      // height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
      // width:'fit-content'
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      width: '100%',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
  valueContainer: (styles) => {
    return {
      ...styles,
      height: '25px',
      padding: '0px 8px',
    };
  },
  input: (styles) => {
    return {
      ...styles,
      padding: '0px',
    };
  },
};
