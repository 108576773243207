import { ChevronRight, CircleX, UserRound } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { useWhoAmI } from '../Hook/useWhoAmI';
import { fullNameUser } from '../Utils';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants, RT } from '../_constants';
import { logout } from '../_helpers';
import { authService } from '../_services';
import './profile.css';

const LogOutModal = ({ onClose }) => {
  const navigate = useNavigate();
  const { containerRef } = useOutsideClickHandler({ onClose: () => onClose() });
  const { data: newUser } = useWhoAmI();

  const Name = fullNameUser(newUser?.first_name, newUser?.last_name);
  const isMale = newUser?.gender === 1;
  const isNeutral = newUser?.gender === 3;
  const url = newUser?.compressed_image_url ? newUser?.compressed_image_url : newUser?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

  const handleLogout = async () => {
    await authService.tokenRemove();

    logout();
    onClose();
  };
  return (
    <div ref={containerRef} className='log-out-modal-container'>
      <div className='d-flex '>
        <div className=' mb-2'>
          <CircularAvatar
            size={35}
            source={finalUrl}
            isMale={isMale}
            isNeutral={isNeutral}
            className='fz-none zoom-in employee-img-with-low-border'
          />
        </div>
        <div className='ms-2 mt-1'>
          <div className='user-name'>{Name}</div>
        </div>
      </div>
      <div className=' mt-3' style={{ borderBottom: '1px solid #EBEBEB' }}>
        <div
          className='d-flex mb-3 justify-content-between cursor-pointer'
          onClick={() => navigate(`${RT.PROFILE}/details`)}
        >
          <div className='d-flex'>
            <UserRound color='grey' /> <div className='logout-text ms-3'>Profile</div>
          </div>
          <ChevronRight color='grey' />
        </div>
      </div>
      <div className='d-flex pt-3 cursor-pointer' onClick={() => handleLogout()}>
        <CircleX color='grey' /> <div className='logout-text ms-3'>Logout</div>
      </div>
    </div>
  );
};

export default LogOutModal;
