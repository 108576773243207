import { useInfiniteQuery, useQuery, useQueryClient } from '@tanstack/react-query';
import { ChevronLeft, MessageSquareMore } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useObserver from '../Hook/useObserver';
import {
  filterNullValues,
  formatString,
  getChatUpdateInputId,
  getChatUpdateRowData,
} from '../Utils';
import { RT, USER_LIST_ACTIONS } from '../_constants';
import { CHAT_TABS, CHAT_UPDATE_TYPE } from '../_constants/chat.constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';
import { chatService } from '../_services/chat.service';
import ChatContainer from './ChatContainer';
import ChatUpdateContainer from './ChatUpdate/ChatUpdateContainer';
import ChatUpdateInputs from './ChatUpdate/ChatUpdateInputs';
import ChatUpdateList from './ChatUpdate/ChatUpdateList';
import ChatUpdateListWrapper from './ChatUpdate/ChatUpdateListWrapper';
import ChatUpdateTabs from './ChatUpdate/ChatUpdateTabs';
import ChatUserRow from './ChatUserRow';
import './chat.css';
export function Chat() {
  const [searchParam, setSearchParam] = useSearchParams();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const [isChatInvalid] = useState(false);
  const updateIdFromUrl = searchParam.get('id');
  const userId = searchParam.get('chat_user_id');
  const hasUserId = Boolean(userId);
  const searchUpdateId = searchParam.get('update_id');
  const from = searchParam.get('from');

  const loggedUser = JSON.parse(localStorage.getItem('user'));
  const isUnread = Boolean(searchParam.get('unread'));
  // const chatUserId = searchParam.get('chat_user_id');
  const [searchKey, setSearchKey] = useState('');

  const debouncedSearchKey = useDebounce(searchKey, 500);

  // Remove chat_user_id from url, if  searchKey is empty
  if (hasUserId && !debouncedSearchKey && !from) {
    const searchParam = new URLSearchParams(location.search);
    searchParam.delete('chat_user_id');
    setSearchParam(searchParam);
  }

  const margin = 46;
  const windowHeight = window.innerHeight;

  const currentTab = CHAT_TABS.find((item) => item.path === searchParam.get('tab'));

  const chatUpdateAdditionalPayloads = filterNullValues(currentTab.getPayload());
  const updateSelectFields = [
    'id',
    'last_message',
    'last_message_time',
    'chat_update_id',
    'project_id',
    'group_id',
    'team_id',
    'task_id',
    'update_type',
    'group_name',
    'project_name',
    'task_name',
    'user_ids',
  ];

  const isTabActive = (tabPath) => {
    const params = new URLSearchParams(location.search);
    return params.get('tab') === formatString(tabPath);
  };

  const chatUpdateQueryKey = [
    'chat-update-list',
    chatUpdateAdditionalPayloads?.update_type,
    debouncedSearchKey,
    isUnread,
    userId,
    searchUpdateId,
  ];

  const {
    data: updateData,
    isError,
    error,
    isLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: chatUpdateQueryKey,
    queryFn: ({ pageParam = 1 }) =>
      chatService.getChatUpdateListAdvanced({
        payload: {
          ...(userId ? { user_id: userId } : {}),
          searchKey: debouncedSearchKey,
          unread: isUnread,
          select: updateSelectFields,
          ...chatUpdateAdditionalPayloads,
          ...(searchUpdateId ? { id: searchUpdateId } : {}),
          pageVo: {
            sort: 'last_message_time',
            sort_order: 'DESC',
            noOfItems: 10,
            pageNo: pageParam,
          },
        },
      }),
    select: (res) => res.pages,
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
  });

  const {
    data: updateCount,
    refetch: refetchUpdateCount,
    isSuccess: isUpdateCountSuccess,
  } = useQuery({
    queryKey: ['update-tab-counts'],
    queryFn: () => chatService.getUpdateCount(),
    select: (response) => response.data,
  });

  const updateUnreadCount = (updateParam) => {
    // Update the count in the update list.
    queryClient.setQueryData(chatUpdateQueryKey, (oldData) => {
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => ({
        ...page,
        data: {
          ...page.data,
          rows: page.data.rows.map((update) =>
            parseInt(update.id) === parseInt(updateParam.id)
              ? { ...update, unread_count: 0 }
              : update,
          ),
        },
      }));

      return {
        ...oldData,
        pages: newPages,
      };
    });

    // Update the total tab count

    if (updateParam.unread_count > 0) {
      refetchUpdateCount();
    }

    // queryClient.setQueryData(['update-tab-counts'], (oldData) => {
    //   if (!oldData) return;

    //   const newData = oldData.data;
    //   const currentCountKey = currentTab.countKey;

    //   if (newData[currentCountKey] > 0 && update.unread_count > 0) {
    //     newData[currentCountKey] -= 1;
    //   }

    //   return {
    //     ...oldData,
    //     data: newData,
    //   };
    // });
  };

  // const sortAndUpdateLastMsg = (updateId)=>{

  // }

  const { data: searchedUserList } = useQuery({
    queryKey: ['update-user-list', debouncedSearchKey, userId],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.CHAT_UPDATE,
        select: ['id', 'first_name', 'last_name', 'gender', 'image_url'],
        searchKey: debouncedSearchKey,
        ...(userId ? { id: userId } : {}),
      }),
    select: (res) => res.data.rows,
    enabled: Boolean(debouncedSearchKey || hasUserId),
  });

  // console.log(Boolean(debouncedSearchKey || chatUserId), 'chatUserId');

  const { observerRef } = useObserver({
    onIntersection: () => hasNextPage && fetchNextPage(),
    dependency: [hasNextPage],
  });

  const flattedUpdateData = React.useMemo(
    () => updateData?.flatMap((page) => page.data.rows),
    [updateData],
  );

  const allUpdateList = flattedUpdateData ? [...flattedUpdateData] : [];

  const getSelectedUser = (userList) => {
    if (!userList) {
      return null;
    }
    const selectedUser = userList.find((item) => parseInt(item.id) === parseInt(userId));
    if (!selectedUser) {
      return null;
    }

    return {
      ...selectedUser,
      receiver_id: selectedUser.id,
      creator_id: loggedUser.id,
      update_type: CHAT_UPDATE_TYPE.PERSONAL,
    };
  };

  const activeUpdate = hasUserId
    ? getSelectedUser(searchedUserList)
    : allUpdateList?.find(
        (item) => parseInt(item.id) === parseInt(searchUpdateId ?? updateIdFromUrl),
      );

  useEffect(() => {
    // Unread will only call, if one update is selected and the update and update counts apis are success
    isUpdateCountSuccess &&
      isSuccess &&
      !hasUserId &&
      activeUpdate?.id &&
      updateUnreadCount(activeUpdate);
  }, [activeUpdate?.id, hasUserId, isSuccess, isUpdateCountSuccess]);

  if (isChatInvalid) {
    return (
      <div
        className='d-flex flex-column align-items-center justify-content-center'
        style={{ height: windowHeight }}
      >
        <div>No Access</div>
        <div>
          <a className='fz-13px pointer' onClick={() => navigate('/chat?tab=all')}>
            Go Back
          </a>
        </div>
      </div>
    );
  }

  const handleTabClick = (tab) => {
    const updatedSearchParams = new URLSearchParams(searchParam);
    updatedSearchParams.set('tab', formatString(tab.path));
    updatedSearchParams.delete('id');
    updatedSearchParams.delete('chat_user_id');
    updatedSearchParams.delete('from');
    updatedSearchParams.delete('update_id');
    setSearchParam(updatedSearchParams);
  };

  const isUpdateListEmpty =
    (!allUpdateList || allUpdateList?.length <= 0) &&
    (!searchedUserList || searchedUserList?.length <= 0);

  return (
    <div className='  fade-entry chat-top-container'>
      <ChatUpdateContainer isNeedSafetyOffset>
        <ChatUpdateTabs
          counts={updateCount}
          onTabClick={handleTabClick}
          isTabActive={isTabActive}
          refetch={refetch}
        />
        <ChatUpdateInputs onSearch={(searchKey) => setSearchKey(searchKey)} searchKey={searchKey} />
        <ChatUpdateListWrapper
          isLoading={isLoading}
          refetchUpdates={refetch}
          isError={isError}
          error={error}
          isEmpty={isUpdateListEmpty}
        >
          {/* The searched user list will appear here */}
          <SearchUserList
            isVisible={searchKey || hasUserId}
            list={
              searchedUserList
                ? searchedUserList.map((item) => ({
                    ...item,
                    User: item,
                    update_type: CHAT_UPDATE_TYPE.PERSONAL,
                  }))
                : []
            }
            isActive={(user) => parseInt(user.id) === parseInt(searchParam.get('chat_user_id'))}
            onUserClick={(user) => {
              const updatedSearchParams = new URLSearchParams(searchParam);
              updatedSearchParams.set('chat_user_id', formatString(user.id));
              updatedSearchParams.delete('id');
              setSearchParam(updatedSearchParams);
            }}
            searchKey={searchKey}
          />
          {/* Update list */}
          <ChatUpdateList
            isActive={({ id }) => {
              return parseInt(id) === parseInt(searchUpdateId ?? updateIdFromUrl);
            }}
            onChatHomeClick={() => {
              const searchParam = new URLSearchParams(location.search);
              searchParam.delete('update_id');
              navigate(`/chat?${searchParam.toString()}`);
            }}
            updates={allUpdateList}
            onUpdateClick={(update) => {
              const updatedSearchParams = new URLSearchParams(searchParam);
              updatedSearchParams.set('id', formatString(update.id));
              updatedSearchParams.delete('chat_user_id');
              setSearchParam(updatedSearchParams);
              // updateUnreadCount(update); //Updating unread count in the useEffect above.
            }}
          />

          {/* For detecting the scroll end. Refetch is done by using the end of the list */}
          <div ref={observerRef} style={{ height: 10, width: '100%' }} />
        </ChatUpdateListWrapper>
      </ChatUpdateContainer>
      {/* Chat Container is used in task */}
      {activeUpdate && (updateIdFromUrl || hasUserId || searchUpdateId) ? (
        <ChatContainer
          key={updateIdFromUrl}
          updateRefetch={() => refetch()}
          // 5 is a safety offset
          initialHeight={windowHeight - margin - 5}
          listClassName={'chat-content'}
          headerVisibility={true}
          type={activeUpdate?.update_type}
          activeUpdate={{ ...activeUpdate, ...getChatUpdateRowData(activeUpdate) }}
          listPayload={{ chat_update_id: hasUserId ? 0 : searchUpdateId ?? updateIdFromUrl }}
          inputPayload={getChatUpdateInputId(activeUpdate)}
          onMessageSendSuccess={(response, payload) => {
            console.log(response, payload, 'PayloadData');
            refetch();
            setSearchKey('');
          }}
        />
      ) : (
        <ChatWelcomeView initialHeight={windowHeight - margin - 5} />
      )}
    </div>
  );
}

const SearchUserList = ({ list, isVisible, onUserClick, isActive }) => {
  const [searchParam] = useSearchParams();
  const navigate = useNavigate();

  if (!isVisible || list?.length <= 0) {
    return null;
  }
  return (
    <>
      <div className='px-2 pb-2 sticky-head'>
        {searchParam.get('from') ? (
          <div
            role='button'
            className='fz-13px chat_msg ps-3'
            onClick={() => navigate(`/${RT.CHAT}?tab=all`)}
          >
            <ChevronLeft size={13} />
            Back
          </div>
        ) : (
          <div className='chat_msg fz-14px update-padding'>Users</div>
        )}
      </div>
      {list.map((item, key) => {
        return (
          <ChatUserRow
            key={key}
            onClick={() => onUserClick(item)}
            update={item}
            active={() => isActive(item)}
          />
        );
      })}
    </>
  );
};

const ChatWelcomeView = ({ windowHeight }) => {
  return (
    <div
      className='d-flex welcome-container  chat-welcome-container flex-column gap-2 justify-content-center align-items-center fw-500'
      style={{
        flex: 1,
        height: `max(calc(100vh - var(--top-bar-height) - 82px) , ${windowHeight}px - var(--top-bar-height))`,
      }}
    >
      <div className='grey-circular-bg' style={{ width: 70, height: 70 }}>
        <MessageSquareMore size={35} color='#C1C1C1' />
      </div>
      Welcome to Chats
    </div>
  );
};
