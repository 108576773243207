import { SearchIcon } from 'lucide-react';
import React, { useState } from 'react';
import { Modal } from '../_components';
import { GlobalSearch } from './GlobalSearch';
import './GlobalSearch.css';

export default function GlobalSearchContainer() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <div className='d-flex pointer clickable' role='button' onClick={() => setIsOpen(true)}>
        <SearchIcon size={19} />
      </div>

      {isOpen && (
        <Modal.Container handleClose={() => setIsOpen(false)}>
          <Modal.View className='global-search-modal'>
            <GlobalSearch setIsOpen={setIsOpen} />
          </Modal.View>
        </Modal.Container>
      )}
    </>
  );
}
