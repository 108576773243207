import React, { useContext, useState } from 'react';
import { Button } from '../../_components';
import { MENU } from '../../_constants';
import {
  TASK_TYPES,
  VALIDATION_VALUES,
  VALIDATIONS_OPTIONS,
} from '../../_constants/template.constants';
import { GlobalContext } from '../../Utils';

function ValidationOptions({ templateDetails, setTemplateDetails, onClose = () => {} }) {
  const [validationFields, setValidationFields] = useState(templateDetails.validation_fields);
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);

  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };

  const UPDATED_VALIDATION_OPTIONS = hasExist(MENU.contact.default_name)
    ? [...VALIDATIONS_OPTIONS, { label: LABEL_OF_CLIENT, field: 'client_id', id: 11 }]
    : [...VALIDATIONS_OPTIONS];

  const handleValidationChange = (event) => {
    const { value, name } = event.target;
    setValidationFields((prev) => ({
      ...prev,
      [name]: parseInt(value),
    }));
  };

  const validateWithTemplateForm = (item) => {
    const verifyId = 10;
    const expectedHourId = 7;

    return (
      (templateDetails.task_type !== TASK_TYPES.NON_TIME_BOUND_TASK ||
        item.id !== expectedHourId) &&
      ((templateDetails.is_verification_required && templateDetails.is_verify_user_changeable) ||
        item.id !== verifyId)
    );
  };

  return (
    <div className='addon-settings-container sub-section'>
      <div
        role='button'
        className='d-flex gap-1 align-items-center justify-content-between  btn-wrapper w-fit-content py-1 pe-1'
      >
        <div className='fw-600 fz-16px-rem'>Validations</div>
        {/* <ChevronUp width={18} className={`${isValidationExpanded ? 'rotate-down' : 'rotate-up'}`} /> */}
      </div>

      <div className={`anim-hide fz-13px-rem`}>
        <div className='overflow-hidden'>
          <div className='mt-5px'>
            {UPDATED_VALIDATION_OPTIONS.filter(validateWithTemplateForm).map((option) => {
              return (
                <div className='row mt-5px' key={option.id}>
                  <div className='col mt-5px'>
                    <div>{option.label}</div>
                  </div>
                  <div className='col-auto d-flex gap-2 align-items-center mt-5px'>
                    <input
                      key={`${option.field}-required-${validationFields[option.field]}`} // Added key here
                      checked={validationFields[option.field] === VALIDATION_VALUES.REQUIRED}
                      onChange={handleValidationChange}
                      type='radio'
                      id={`${option.field}-required`}
                      name={option.field}
                      value={VALIDATION_VALUES.REQUIRED}
                      className='pointer'
                    />
                    <label htmlFor={`${option.field}-required`}>Required</label>
                  </div>
                  <div className='col-auto d-flex gap-2 align-items-center mt-5px'>
                    <input
                      key={`${option.field}-not-required-${validationFields[option.field]}`} // Added key here
                      checked={validationFields[option.field] === VALIDATION_VALUES.NOT_REQUIRED}
                      onChange={handleValidationChange}
                      type='radio'
                      id={`${option.field}-not-required`}
                      name={option.field}
                      value={VALIDATION_VALUES.NOT_REQUIRED}
                      className='pointer'
                    />
                    <label htmlFor={`${option.field}-not-required`}>No Required</label>
                  </div>
                  <div className='col-auto d-flex gap-2 align-items-center mt-5px'>
                    <input
                      key={`${option.field}-hide-${validationFields[option.field]}`} // Added key here
                      type='radio'
                      id={`${option.field}-hide`}
                      onChange={handleValidationChange}
                      name={option.field}
                      checked={validationFields[option.field] === VALIDATION_VALUES.HIDE}
                      value={VALIDATION_VALUES.HIDE}
                      className='pointer'
                    />
                    <label htmlFor={`${option.field}-hide`}>Hide</label>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className='d-flex gap-2 justify-content-end ' style={{ marginTop: 27 }}>
        <Button.Container
          type='button'
          className='cancel-btn'
          handleOk={(event) => {
            event.stopPropagation();
            onClose();
          }}
        >
          <Button.Title title='Cancel' />
        </Button.Container>
        <Button.Container
          type='button'
          handleOk={(event) => {
            event.stopPropagation();
            setTemplateDetails((prev) => ({
              ...prev,
              validation_fields: validationFields,
            }));
            onClose();
          }}
        >
          <Button.Title title={'Apply'} />
          <Button.Loading />
        </Button.Container>
      </div>
    </div>
  );
}

export default ValidationOptions;
