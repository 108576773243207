export const DropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },

  control: (styles, { isFocused, selectProps }) => {
    const hasError = selectProps?.className?.includes('has-error');
    return {
      ...styles,
      // height: '34px',
      minHeight: '25px',
      borderColor: hasError ? 'red' : isFocused ? '#3454D1' : '#87909E',
      boxShadow: isFocused ? '0 0 0 3px rgba(52, 84, 209, 0.25)' : styles.boxShadow,
      '&:hover': {
        borderColor: isFocused ? '#3454D1' : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      zIndex: 100, // Set your desired z-index value here
      minWidth: '170px',
      fontSize: '13px',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
      // zIndex: 100000, // Set your desired z-index value here
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};
export const documentTypeDropDownStyle = {
  container: (styles) => {
    return {
      ...styles,
      fontSize: '13px',
      fontWeight: '400',
    };
  },
  input: (defaultStyles) => {
    return {
      ...defaultStyles,
      gridTemplateColumns: '0 1fr',
    };
  },
  control: (styles, { isFocused }) => {
    return {
      ...styles,
      // height: '34px',
      minHeight: '25px',
      borderColor: isFocused ? '#3454D1' : '#87909E',
      boxShadow: isFocused ? '0 0 0 3px rgba(52, 84, 209, 0.25)' : styles.boxShadow,
      '&:hover': {
        borderColor: isFocused ? '#3454D1' : styles['&:hover']?.borderColor || styles.borderColor,
      },
    };
  },
  indicatorsContainer: (styles) => {
    return {
      ...styles,
      minHeight: '25px',
      height: '33px',
      color: '#87909E',
    };
  },
  menu: (styles) => {
    return {
      ...styles,
      minWidth: '170px',
      fontSize: '13px',
    };
  },
  menuList: (styles) => {
    return {
      ...styles,
      maxHeight: '200px',
    };
  },
  singleValue: (styles) => {
    return {
      ...styles,
      fontWeight: '500',
    };
  },
  indicatorSeparator: () => ({
    display: 'none',
  }),
  dropdownIndicator: (styles) => {
    return {
      ...styles,
      color: '#2A2E34',
      '&:hover': {
        color: '#2A2E34',
      },
    };
  },
};

export const sharedUserSelect = ['id', 'first_name', 'last_name', 'gender', 'image_url','compressed_image_url'];
export const USER_ALL_OPTION = {
  id: 0,
  first_name: 'All',
  last_name: '',
  image_url: '',
  gender: 1,
};
export const TEAM_ALL_OPTION = { id: 0, name: 'All' };

export const FILE_TYPE = { DIGITAL: 1, PHYSICAL: 2 };

export const FILTER_SELECTION_BOX = {
  control: (provided, state, isFocused) => ({
    ...provided,
    background: '#fff',
    minHeight: '33px',
    borderColor: isFocused ? '#3454D1' : '#87909E',
    fontSize: '13px',
    boxShadow: state.isFocused ? 'none' : provided.boxShadow,
    minWidth: 'max-content',
    height: 33,
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    minWidth: '250px',
    overflowWrap: 'break-word',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    overflow: 'auto',
  }),
  indicatorSeparator: (provided) => ({
    ...provided,
    display: 'none',
  }),
  valueContainer: (style) => ({
    ...style,
    scrollbarWidth: 'none',
    overflow: 'auto',
    flexWrap: 'nowrap',
  }),
  multiValue: (style) => ({
    ...style,
    minWidth: 'none',
  }),
  menuList: (style) => ({
    ...style,
    fontSize: '13px',
  }),
};

export const PreviewFileTypesList = [
  'jpg',
  'JPG',
  'jpeg',
  'png',
  'gif',
  'bmp',
  'mp4',
  'avi',
  'mkv',
  'mov',
  'wmv',
  'flv',
  'mp3',
  'wav',
  'aac',
  'ogg',
  'wma',
  'flac',
  'pdf',
  'JPEG',
  'PNG',
  'GIF',
  'BMP',
  'MP4',
  'AVI',
  'MKV',
  'MOV',
  'WMV',
  'FLV',
  'MP3',
  'WAV',
  'AAC',
  'OGG',
  'WMA',
  'FLAC',
  'PDF',
];

export const EXPIRY = { TODAY: 1, EXPIRED: 0, NOT_EXPIRED: 2 };
export const ADMIN = 1;
