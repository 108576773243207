/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import { Heading } from '../Misc';
import { GlobalContext } from '../Utils';
import { MENU, RT } from '../_constants';
import { SettingsProtectedRoute } from './SettingsProtectedRoute';

export function SettingsLayout() {
  const { globalSettings, getMenuLabelName } = useContext(GlobalContext);
  // const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  const [settingsTabs, setSettingsTabs] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchSettings = () => {
      const generalSettings =
        globalSettings &&
        globalSettings.length &&
        globalSettings.find((settings) => settings?.name === 'general-settings');

      const menuSettings =
        globalSettings &&
        globalSettings.length &&
        globalSettings.find((settings) => settings?.name === 'menu-settings');

      const labelSettings =
        generalSettings?.labels.length &&
        generalSettings.labels.find((settings) => settings?.label === 'Label');

      const projectSettings =
        menuSettings?.labels.length &&
        menuSettings.labels.find((settings) => settings?.name === LABEL_OF_PROJECT);

      const SETTINGS_TABS = [
        { name: 'General', path: RT.GENERAL },
        { name: 'Menu', path: RT.MENU },
        { name: `${LABEL_OF_PROJECT}`, path: RT.PROJECT },
        { name: 'Task', path: RT.TASK },
        { name: 'Employee', path: RT.EMPLOYEE_SETTINGS },
        { name: 'User Group', path: RT.TEAM },
        { name: 'Chat', path: 'chat' },
        { name: 'Organization', path: `${RT.WORK_SPACE}/${RT.ORGANIZATION}` },
        { name: 'Label', path: RT.LABEL },
        // { name: 'Permission', path: RT.PERMISSION },
        { name: 'Theme', path: RT.THEME },
        // { name: ` ${LABEL_OF_CLIENT}`, path: RT.CLIENT },
      ];

      if (!labelSettings?.value) {
        SETTINGS_TABS.splice(8, 1);
      }
      if (!projectSettings?.value) {
        SETTINGS_TABS.splice(2, 1);
      }

      setSettingsTabs(SETTINGS_TABS);
      setLoading(false);
    };

    fetchSettings();
  }, [globalSettings]);

  if (loading) {
    return <div>Loading...</div>;
  }

  const location = useLocation();
  // Check if "work-space" is included in the current URL path
  const isWorkSpaceActive = location.pathname.includes('work-space');

  return (
    <SettingsProtectedRoute>
      <div className='settings-page  sub_padding fade-entry'>
        <Heading title={'Settings'} />
        <br />
        <section className='settings_head'>
          <div className='menu_line'></div>
          {settingsTabs?.map((tab, index) => {
            return (
              <NavLink
                key={index}
                className={({ isActive }) => {
                  if (isWorkSpaceActive && tab.name === 'Company') return 'menu_active';
                  return isActive ? 'menu_active' : 'menu_inactive';
                }}
                to={tab?.path}
              >
                {tab.name}
              </NavLink>
            );
          })}
        </section>
        <section className='outlet-contain'>
          <Outlet />
        </section>
      </div>
    </SettingsProtectedRoute>
  );
}
