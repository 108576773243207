/* eslint-disable no-unused-vars */
import { keepPreviousData, useQuery, useQueryClient } from '@tanstack/react-query';
import { Buffer } from 'buffer';
import 'cropperjs/dist/cropper.css';
import { Edit, SquareCheck } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { Cropper } from 'react-cropper';
import { Modal } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants } from '../_constants';
import { miscService, userService } from '../_services';
import EditableField from '../Task/TaskComponents/EditableField';
import {
  compressImageOnly,
  fullNameUser,
  getFileType,
  GlobalContext,
  sanitizeFileName,
} from '../Utils';
import ChangePasswordModal from './ChangePasswordModal';

const ProfileDetails = () => {
  let User = JSON.parse(localStorage.getItem('user'));
  const { makeAlert, globalSettingsRefetch } = useContext(GlobalContext);
  const imageRef = useRef(null);
  const [changePassword, setChangePassword] = useState(false);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);
  const [file, setFile] = useState();
  const [image, setImage] = useState();
  const [isUploading, setIsUploading] = useState(false);

  const query = {
    select: [
      'id',
      'first_name',
      'last_name',
      'address',
      'phone_no',
      'email_id',
      'gender',
      'department_id',
      'designation_id',
      'employee_code',
      'role',
      'reported_users',
      'image_url',
      'username',
      'password',
    ],

    id: User?.id,
  };
  const { data: userData, refetch } = useQuery({
    queryKey: ['user'],
    queryFn: () => userService.userGet(query),
    select: (data) => data?.data,
    placeholderData: keepPreviousData,
  });
  const isMale = userData?.gender === 1;
  const isNeutral = userData?.gender === 3;
  const url = userData?.compressed_image_url ? userData?.compressed_image_url : userData?.image_url;
  const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
  const Name = fullNameUser(userData?.first_name, userData?.last_name);
  const isMainAdmin = User?.role == 1 && !User?.is_sub_admin;

  const queryClient = useQueryClient();

  //function to edit admin data
  const handleEditAdmin = async (name, value) => {
    const payload = {
      ...userData,
      id: User?.id,
      [name]: value,
    };
    try {
      const res = await userService.userAdd(payload);
      if (res.error_code == 0) {
        refetch();
        makeAlert('Success');
      }
    } catch (error) {
      makeAlert(error?.message);
    }
  };
  ///function to change image
  const changePicture = (event) => {
    const { name, files, value } = event.target;
    setFile(URL.createObjectURL(event.target.files[0]));
    setImage((prev) => ({
      ...prev,
      imageFile: files[0],
    }));
    setIsPreviewOpen(true);
  };
  //use to crop image choose
  const onCrop = () => {
    const imageElement = imageRef?.current;
    const cropper = imageElement?.cropper;
    setImage((prev) => ({
      ...prev,
      imageUrl: cropper.getCroppedCanvas().toDataURL(),
    }));
  };
  //function to save image
  const editProfileImage = async () => {
    setIsUploading(true);
    const data = dataUrlToFile(image?.imageUrl, image?.imageFile?.name);
    const { compressedData, uploadedData } = await onFileSelect(data);
    const img = uploadedData.pop();
    const payload = {
      ...userData,
      id: User?.id,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed,
    };
    const updatedUser = {
      ...User,
      image_url: img,
      compressed_image_url: compressedData?.fileNameCompressed,
    };
    try {
      const res = await userService.userAdd(payload);
      if (res.error_code == 0) {
        refetch();
        makeAlert('Success');
        setIsPreviewOpen(false);
        queryClient.invalidateQueries('whoami');
        localStorage.setItem('user', JSON.stringify(updatedUser));
        setIsUploading(false);
      }
    } catch (error) {
      makeAlert(error?.message);
      setIsUploading(false);
    }
  };

  //function to change url to file
  function dataUrlToFile(dataUrl, filename) {
    const arr = dataUrl.split(',');
    if (arr.length < 2) {
      return undefined;
    }
    const mimeArr = arr[0].match(/:(.*?);/);
    if (!mimeArr || mimeArr.length < 2) {
      return undefined;
    }
    const mime = mimeArr[1];
    const buff = Buffer.from(arr[1], 'base64');
    return new File([buff], filename, { type: mime });
  }
  const onFileSelect = async (fileData) => {
    console.log({ fileData });
    let cloneSelectedFiles = [];
    cloneSelectedFiles.push({
      name: fileData.name.replace(/[^\w.-]|[\s&]/g, ''),
      sanitizeName: sanitizeFileName(fileData.name),
      fileType: getFileType(fileData.name.split('.').pop()),
      size: fileData.size,
      extension: fileData.name.split('.').pop(),
      file: fileData,
    });
    const response = await handleFileUpload(cloneSelectedFiles);
    return response;
  };
  const handleFileUpload = async (data) => {
    if (!data.length) {
      return;
    }
    const uploadedData = [];
    const compressedData = { compressedImageBlobUrl: '', fileNameCompressed: '' };

    for (let fileObj of data) {
      try {
        if (fileObj.fileType === 'image') {
          let { compressedFile, compressedImageBlobUrl } = await compressImageOnly(fileObj.file);

          let signedDataCompressed = await miscService.createSignedUploadUrl({
            type: fileObj.fileType, //--video,image,audio
            ext: fileObj.extension, //--jpg or mp4
            name: `${fileObj.sanitizeName}_compressed`,
          });

          if (signedDataCompressed?.data?.signedUrl) {
            let signedUrlCompressed = signedDataCompressed.data.signedUrl;
            let fileNameCompressed = signedDataCompressed.data.filename;

            // Upload the compressed file
            await fetch(signedUrlCompressed, {
              method: 'PUT',
              headers: { 'Content-Type': 'multipart/form-data' },
              body: compressedFile,
            }).then((response) => response.text());

            compressedData.fileNameCompressed = fileNameCompressed;
            compressedData.compressedImageBlobUrl = compressedImageBlobUrl;

            // compressedBlobData.push(compressedImageBlobUrl);
          }
        }

        let signedData = await miscService.createSignedUploadUrl({
          type: fileObj.fileType, //--video,image,audio
          ext: fileObj.extension, //--jpg or mp4
          name: fileObj.sanitizeName,
        });

        if (signedData?.data?.signedUrl) {
          let signedUrl = signedData.data.signedUrl;
          let fileName = signedData.data.filename;

          await fetch(signedUrl, {
            method: 'PUT',
            headers: { 'Content-Type': 'multipart/form-data' },
            body: fileObj.file,
          }).then((response) => response.text());

          uploadedData.push(fileName);
        }
      } catch (error) {
        console.log({ error });
      }
    }
    return { uploadedData, compressedData };
  };
  return (
    <div className='mt-4'>
      <div className=' d-flex  ms-1'>
        <div>
          <CircularAvatar
            size={45}
            className='fz-none zoom-in employee-img-with-low-border'
            source={finalUrl}
            isMale={isMale}
            isNeutral={isNeutral}
          />
          <label className='details-heading cursor-pointer' htmlFor='image_upload'>
            Change
          </label>
          <input
            type='file'
            name='image_url'
            id='image_upload'
            hidden
            onChange={changePicture}
            accept='image/*'
          />
        </div>
        <div className='ml-4 ms-2'>
          <EditableField
            onSave={(val) => handleEditAdmin('first_name', val.name)}
            key={Name}
            name='name'
            type='input'
            placeholder='Type Task Name'
            isEditable={isMainAdmin}
            data={{ name: Name }}
            inputClassName='taskname-input w-100 form-control'
          >
            <div className='user-name font-15'>{Name}</div>
          </EditableField>
          <div className='font-14'>{userData?.designation?.name}</div>
        </div>
      </div>
      <div className='row mt-4 gap-2'>
        <div className='col-md-4'>
          <div className='bb-solid'>
            <div className='d-flex gap-1'>
              <div>
                <SquareCheck color='#44546F' size={15} />
              </div>
              <div className='details-heading mt-1'>Organization</div>
            </div>
            <div className='mb-2 details-value'>{userData?.Company?.name}</div>
          </div>
        </div>
        {!isMainAdmin && (
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Department</div>
              </div>
              <div className='mb-2 details-value'>{userData?.department?.name}</div>
            </div>
          </div>
        )}
      </div>
      {!isMainAdmin && (
        <div className='row mt-4 gap-2'>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Address</div>
              </div>
              <div className='mb-2 details-value'>{userData?.address}</div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Employee ID</div>
              </div>
              <div className='mb-2 details-value'>{userData?.employee_code}</div>
            </div>
          </div>
        </div>
      )}
      {!isMainAdmin && (
        <div className='row mt-4 gap-2'>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Email ID</div>
              </div>
              <div className='mb-2 details-value'>{userData?.email_id}</div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Phone Number</div>
              </div>
              <div className='mb-2 details-value'>{userData?.phone_no}</div>
            </div>
          </div>
        </div>
      )}
      {!isMainAdmin && (
        <div className='row mt-4 gap-2'>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Gender</div>
              </div>
              <div className='mb-2 details-value'>{userData?.gender == 1 ? 'Male' : 'Female'}</div>
            </div>
          </div>
          <div className='col-md-4'>
            <div className='bb-solid'>
              <div className='d-flex gap-1'>
                <div>
                  <SquareCheck color='#44546F' size={15} />
                </div>
                <div className='details-heading mt-1'>Reported To</div>
              </div>
              <div className='mb-2 details-value'>
                {' '}
                {userData?.reported_users?.map((item, index) => {
                  return (
                    <>
                      <span>{item.name}</span>

                      {index != userData?.data?.reported_users.length - 1 && <span>,</span>}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      )}
      <div className='row mt-4 gap-2'>
        <div className='col-md-4'>
          <div className='bb-solid'>
            <div className='d-flex gap-1'>
              <div>
                <SquareCheck color='#44546F' size={15} />
              </div>
              <div className='details-heading mt-1'>Username</div>
            </div>
            <EditableField
              onSave={(val) => handleEditAdmin('username', val.name)}
              key={Name}
              name='name'
              type='input'
              placeholder='Type Task Name'
              isEditable={isMainAdmin}
              data={{ name: userData?.username }}
              inputClassName='taskname-input w-100 form-control'
            >
              <div className='mb-2 details-value'>{userData?.username}</div>
            </EditableField>
          </div>
        </div>
        <div className='col-md-4'>
          <div className='bb-solid'>
            <div className='d-flex gap-1'>
              <div>
                <SquareCheck color='#44546F' size={15} />
              </div>
              <div className='details-heading mt-1 '>Password</div>
            </div>
            <div className='d-flex justify-content-between me-2'>
              <div className='mb-2 details-value'>. . . .</div>{' '}
              <Edit size={15} onClick={() => setChangePassword(true)} />
            </div>
          </div>
        </div>
      </div>

      {changePassword && (
        <Modal.Container handleClose={() => setChangePassword(false)}>
          <Modal.View className={'modal_view_change_password'}>
            <Modal.Head
              Heading={'Change Password'}
              handleClose={() => {
                setChangePassword(false);
              }}
            />
            <ChangePasswordModal
              handleClose={() => {
                setChangePassword(false);
              }}
            />
          </Modal.View>
        </Modal.Container>
      )}

      {isPreviewOpen && (
        <div className='image-preview-container'>
          <div className='image-preview-card'>
            <div className='image-preview'>
              <Cropper
                crop={onCrop}
                src={file}
                initialAspectRatio={1}
                aspectRatio={1}
                ref={imageRef}
                style={{ maxHeight: '50vh' }}
              />
            </div>
            <div className='image-preview-button-div'>
              <button
                disabled={isUploading}
                className='btn image-preview-button'
                onClick={editProfileImage}
              >
                {isUploading ? 'Saving...' : 'Save'}
              </button>
              <button onClick={() => setIsPreviewOpen(false)} className='btn image-preview-button'>
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProfileDetails;
