/* eslint-disable no-unused-vars */
import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { ArrowLeft, Home } from 'lucide-react';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TaskListView from '../Task/TaskListView';
import { STATUS, TASK_TABS, taskAssignUserSelect } from '../Task/taskConstants';
import { jsonToUrlEncoded, MyTeamIcon, taskSortPayload, TemplateIcon } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { ErrorComponent } from '../_components/ErrorComponent';
import IconLabelSet from '../_components/IconLabelSet';
import { RT, USER_LIST_ACTIONS } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { taskService, userService } from '../_services';
import { statusService } from '../_services/status.service';
import { templateService } from '../_services/template.service';
// TODO: TEMP SOLUTION, NEED TO CHANGE TO API
// let taskBasedUserList = null;

export function Template() {
  const id = useParams()?.id;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isSortProcessed, setIsSortProcessed] = useState(false);
  let User = JSON.parse(localStorage.getItem('user'));
  const [urlQuery, setULRQuery] = useState();
  // const [currentView, setCurrentView] = useState(TASK_TABS.TASKS);
  const currentTaskViewType = searchParams.get('currentView');

  const [currentView, setCurrentView] = useState(
    currentTaskViewType ? currentTaskViewType : TASK_TABS.TASKS,
  );

  useEffect(() => {
    if (currentTaskViewType) {
      setCurrentView(currentTaskViewType);
    } else {
      setCurrentView(TASK_TABS.TASKS);
    }
  }, [currentTaskViewType]);

  const isAdmin = User?.role == 1;

  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'note',
      'created_time',
      'task_end_date',
      'order_seq',
      'status',
      'priority',
      'recent_chat_message',
      'progress_status',
      'label',
      'project_id',
      'task_start_date',
      'created_time',
      'days_difference',
      'status_group_item_id',
      'status_id',
      'parent_id',
    ],
    // searchKey: '',
    pageVo: {
      pageNo: parseInt(searchParams.get('pageNo')) || 1,
      noOfItems: 10,
    },
    is_duration_required: true,
  });

  if (searchParams.get('pageNo') && query?.pageVo?.pageNo !== searchParams.get('pageNo')) {
    setQuery({
      ...query,
      pageVo: { ...query.pageVo, pageNo: searchParams.get('pageNo') },
    });
  }

  const {
    data: templateData,
    error,
    isLoading: isTemplateLoading,
  } = useQuery({
    queryKey: ['template-single-view', id],
    queryFn: () =>
      templateService.getSingleTemplate({
        select: ['id', 'name', 'is_unlisted', 'isPopUpAdd', 'status_group'],
        id: id,
      }),
    placeholderData: keepPreviousData,
    enabled: Boolean(id),
  });
  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  const getParam = (param) => {
    const value = searchParams.get(param);
    return value !== null ? value : null;
  };

  const { ...restQuery } = query;
  const taskSearchKey = getParam('search') ? getParam('search') : '';
  const debouncedSearch = useDebounce(taskSearchKey, 500);

  const priority = getArrayParam('priority_id');
  const filter_start_date = getParam('task_start_date');
  const filter_end_date = getParam('task_end_date');
  const label = getArrayParam('label_id');
  const project_id = getArrayParam('project_id');
  const sort = getArrayParam('sort');
  const assignee_id = getArrayParam('user_id');
  const task_status = parseInt(getParam('task_status'));
  const client_id = getArrayParam('client_id');

  // const status = getArrayParam('status');

  const filterToUrl = { template_id: id };
  if (priority) filterToUrl.priority = priority;
  if (filter_start_date) filterToUrl.filter_start_date = filter_start_date;
  if (filter_end_date) filterToUrl.filter_end_date = filter_end_date;
  if (label) filterToUrl.label = label;
  if (project_id) filterToUrl.project_id = project_id;
  if (assignee_id) filterToUrl.assignee_id = assignee_id;
  if (client_id) filterToUrl.client_id = client_id;

  const filterCount =
    Object.keys(filterToUrl).filter((key) => key !== 'template_id' && key !== 'assignee_id')
      .length + (task_status === STATUS.ARCHIVE ? 1 : 0);

  const {
    data: taskStatusList,
    isLoading: statusLoading,
    refetch: taskStatusListRefetch,
  } = useQuery({
    queryKey: [
      'taskStatusList',
      filterToUrl,
      debouncedSearch,
      templateData?.data?.[0]?.id,
      currentView,
    ],
    queryFn: () =>
      statusService.statusGroupItemsWithTaskCount({
        select: ['id', 'name', 'color', 'default_name', 'icon'],
        group_id: templateData.data[0].status_group,
        task_filter: {
          ...filterToUrl,
          searchKey: debouncedSearch,
          is_unlisted: templateData.data[0].is_unlisted,
        },
      }),
    select: (data) => data.data,
    placeholderData: keepPreviousData,
    enabled:
      Boolean(templateData?.data?.[0]?.id) &&
      currentView == TASK_TABS.TASKS &&
      task_status !== STATUS.ARCHIVE,
  });

  const defaultStatusName = searchParams.get('status');
  const selectedStatus = taskStatusList?.find(
    (status) =>
      status.default_name?.toString()?.toLowerCase() ===
      defaultStatusName?.toString()?.toLowerCase(),
  );

  useEffect(() => {
    const sortToUrl = taskSortPayload(sort);

    if (sortToUrl?.length > 0) {
      setQuery((prev) => ({
        ...prev,
        pageVo: { ...prev.pageVo, sort: sortToUrl },
      }));
    } else {
      setQuery((prev) => {
        // eslint-disable-next-line no-unused-vars
        const { sort, ...restPageVo } = prev.pageVo; // Destructure to remove 'sort' key
        return {
          ...prev,
          pageVo: restPageVo,
        };
      });
    }
    setIsSortProcessed(true); // Mark sort processing as done
  }, [JSON.stringify(sort)]);

  const sortCount = sort?.length;

  const queryKey = [
    'task',
    restQuery,
    query?.pageVo?.pageNo,
    debouncedSearch,
    selectedStatus?.id,
    filterToUrl,
    selectedStatus?.default_name,
    currentView,
    task_status,
  ];

  const getPayLoad = (query) => {
    const encodedQuery = Object.keys(query).reduce((acc, key) => {
      acc[key] = typeof query[key] === 'object' ? JSON.stringify(query[key]) : query[key];
      return acc;
    }, {});
    setULRQuery(jsonToUrlEncoded(encodedQuery));
    return query;
  };

  const {
    data: taskData,
    error: taskError,
    isLoading: taskLoading,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    // isFetching,
    refetch,
  } = useInfiniteQuery({
    queryKey,
    queryFn: ({ pageParam = 1 }) =>
      taskService.taskListAdvanced(
        getPayLoad({
          ...query,
          status_id: task_status === STATUS.ARCHIVE ? -1 : selectedStatus?.id,
          default_name: task_status === STATUS.ARCHIVE ? 'all' : selectedStatus?.default_name,
          status_group_item_id: selectedStatus?.status_group_item_id,
          is_status_group_item: true,
          is_unlisted: templateData.data[0].is_unlisted ?? false,
          ...(selectedStatus?.is_verified?.toString()
            ? { is_verified: selectedStatus.is_verified }
            : {}),
          ...filterToUrl,
          ...(task_status ? { status: task_status } : {}),
          searchKey: debouncedSearch,
          pageVo: { pageNo: pageParam, noOfItems: 10 },
        }),
      ),
    placeholderData: keepPreviousData,
    enabled:
      Boolean(templateData?.data?.[0]?.id) &&
      (!sort || isSortProcessed) &&
      Boolean(selectedStatus?.id) &&
      currentView == TASK_TABS.TASKS, // Enable query only if no sort or sort is processed
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    refetchOnWindowFocus: false,
  });

  // to fetch task user list
  const { data: templateUserList, refetch: userListRefetch } = useQuery({
    queryKey: ['task-list-user-list', id],
    queryFn: () =>
      userService.getPermittedUser({
        action: USER_LIST_ACTIONS.TASK_FILTER,
        select: taskAssignUserSelect,
        template_id: id,
        is_unlisted: templateData.data[0].is_unlisted ?? false,
      }),
    select: (response) => response.data.rows,
    enabled: Boolean(templateData?.data?.[0]?.id),
  });

  // to refetch after task add - task list and task status list
  const refetchData = () => {
    refetch();
    taskStatusListRefetch();
    userListRefetch();
  };

  if (isTemplateLoading || statusLoading) {
    return (
      <div className='h-100 d-flex justify-content-center align-items-center'>
        <LoadingIcon size={50} />
      </div>
    );
  }

  // check the template has related tasks
  const isSimpleTask = templateData?.data?.[0]?.has_related_tasks ? true : false;

  return (
    <div className=' fade-entry'>
      {/* component will active when error occurs   */}
      <ErrorComponent error={error?.message || taskError?.message} />
      {/* {!templateData?.data?.[0]?.include_in_menu && (
        <div
          className='mb-3'
          onClick={() => {
            navigate(`/${RT.TEMPLATE_LIST}`);
          }}
          style={{ cursor: 'pointer', width: 'fit-content' }}
        >
          <MoveLeft size={16} />{' '}
          <span style={{ textDecoration: 'underline', fontSize: 14 }}> Back</span>
        </div>
      )} */}
      {/* <div className='row py-3' style={{ borderBottom: '2px solid #f0f0f0', marginLeft: '2px' }}>
        <div className=' d-flex col-md-6 gap-2 align-items-center'>
          <div className='first_Letter_bg'>{templateFirstLetter}</div>
          <Heading title={templateData?.data?.[0]?.name} />
          <span
            style={{ textDecoration: 'underline', fontSize: 14, marginLeft: 20, cursor: 'pointer' }}
            onClick={() => {
              navigate(`/${RT.TEMPLATE_ADD}/${id}`);
            }}
          >
            Edit
          </span>
        </div>
      </div>{' '} */}
      <div className=' url-container '>
        <div className='d-flex align-items-center breadcrumbs'>
          <div className='me-2 px-1' style={{ borderRight: '1px solid #F0F0F0' }}>
            {' '}
            <ArrowLeft
              color='#87909E'
              height={23}
              width={23}
              onClick={() => {
                navigate(-1);
              }}
            />{' '}
          </div>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            icon={<Home size={13} />}
            onClick={() => navigate(`/`)}
          />
          {isAdmin && (
            <>
              <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
              <IconLabelSet
                label={'Templates'}
                fontWeight={400}
                icon={<img src={TemplateIcon.Template} alt='' />}
                onClick={() => {
                  navigate(`/${RT.TEMPLATE_LIST}`);
                }}
              />
            </>
          )}
          <img className='slash-img-icon-size ms-1 mt-1' src={MyTeamIcon.Slash} alt='' />
          <div className='fz-12px text-nowrap text-capitalize' style={{ fontWeight: 500 }}>
            {templateData?.data?.[0]?.name}
          </div>
        </div>
      </div>
      <div>
        <TaskListView
          // key={'template-task-list' + status}
          templateData={templateData?.data?.[0]}
          query={query}
          setQuery={setQuery}
          taskData={taskData}
          userList={templateUserList}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          loading={taskLoading}
          isSuccess={isSuccess}
          taskStatusList={taskStatusList}
          filterCount={filterCount}
          sortCount={sortCount}
          templateId={id}
          afterTaskAddFn={() => {
            const todoStatus = taskStatusList.find((item) => item.status_default_name === 'todo');
            searchParams.set('status', todoStatus.status_group_item_id);
            setSearchParams(searchParams);
          }}
          refetch={refetchData}
          assignee_id={assignee_id ?? null}
          actInlineAsSimple={isSimpleTask}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
          isFetchingNextPage={isFetchingNextPage}
          urlQuery={urlQuery}
          currentView={currentView}
          setCurrentView={setCurrentView}
          isArchivedList={task_status === STATUS.ARCHIVE}

          // pending={isFetching}
        />
      </div>
    </div>
  );
}
