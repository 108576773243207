/* eslint-disable no-unused-vars */
import { useQueryClient } from '@tanstack/react-query';
import { ChevronDown, GalleryHorizontalEnd, PanelLeftClose, PanelLeftOpen } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { TaskListTypeConst } from '../BoardView/BoardView.constant';
import { favTypes } from '../Favorites/favCostants';
import { useUser } from '../Hook/useUser';
import { getSettingsByName, getTemplateMenu, GlobalContext, Images, ReturnIcon } from '../Utils';
import {
  FileIconSidebar,
  GroupUser,
  HiDeskIcon,
  LoadingIcon,
  PinIconSideBar,
  ProgressIcon,
  ReportsIcon,
  TemplateIcon,
  TimeHistoryIcon,
  WorkActivityIcon,
} from '../Utils/SvgIcons';
import { apiConstants, MENU, RT } from '../_constants';
import './sidebar.css';

export function SideBarMenu({ isOpen, toggleSidebar }) {
  const { globalSettings, isSettingLoading, permissionsMap, getMenuLabelName } =
    useContext(GlobalContext);

  const navigate = useNavigate();
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [isCheckedPin, setIsCheckedPin] = useState(false);

  // reports dropdown
  const [isChecked, setIsChecked] = useState(false);
  const handleMouseEnter = () => {
    setIsChecked(true);
  };
  const handleMouseLeave = () => {
    setIsChecked(false);
  };

  //date's
  const todayStart = moment().startOf('day').valueOf();
  const todayEnd = moment().endOf('day').valueOf();
  const todayCurrent = moment().valueOf();
  const monthStartDate = moment().startOf('month').valueOf();

  // fetching company logo
  const GeneralSettings = getSettingsByName(globalSettings, 'general-settings');
  const companyLogo =
    GeneralSettings?.labels?.find((settings) => settings?.label === 'Logo').value || '';
  let companyIcon = apiConstants.imgUrlS3 + companyLogo;

  // checking if menu exist
  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');
  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels?.some((label) => label?.default_name === default_name)
    );
  };
  const pinnedCheckRef = useRef(null); // reference for pin drop-down
  const todoCheckboxRef = useRef(null); // reference for todo drop-down
  const handleNavLinkClick = () => {
    if (todoCheckboxRef?.current) {
      todoCheckboxRef.current.checked = false;
    }
    // scrollbar close on responsive
    isOpen && toggleSidebar();
  };

  // responsibility progress
  function getLabelProperty(defaultName, property) {
    if (!menuSettings?.labels) return null;
    const item = menuSettings?.labels.find((label) => label?.default_name === defaultName);
    return item ? item[property] || null : null;
  }
  const team = getLabelProperty('team-responsibility', 'in_progress_id');
  const self = getLabelProperty('my-responsibility', 'in_progress_id');
  const user = useUser()?.id;
  // Function to get the name of a label based on its default_name
  function getLabelName(defaultName) {
    const label =
      menuSettings && menuSettings?.labels?.find((label) => label.default_name === defaultName);
    console.log({ getLabelName: label });

    return label ? label?.name : null;
  }

  //extra menu's
  const extraMenus = menuSettings?.labels?.filter((label) => label?.type === 'template') || [];

  //contact and project
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const LABEL_OF_PROJECT = getMenuLabelName(MENU.project.default_name);

  // get notification count from react query cache   queryKey: ['notificationCount'],
  const queryClient = useQueryClient();
  // Get notification count from React Query cache
  const notificationCount = queryClient.getQueryData(['notificationCount']);
  const chatCount = notificationCount?.data?.chatNotificationCount;

  // favorites item map
  const favorites =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'favorites');
  const handleMouseEnterPin = () => {
    setIsCheckedPin(true);
  };
  const handleMouseLeavePin = () => {
    setIsCheckedPin(false);
  };
  const favoriteItem = (data, index) => {
    const name =
      data.type == favTypes.TASK
        ? data['Task.name']
        : data.type == favTypes.PROJECT
        ? data['Project.name']
        : 'Other';

    const link =
      data.type == favTypes.TASK
        ? `${RT.TASK}/${data?.task_id}?tab=work-history`
        : data.type == favTypes.PROJECT
        ? `${RT.PROJECT}/${data?.project_id}/${RT.PROJECT_TASK_LIST}?status=${
            data.navigation_id ?? 'in-progress'
          }&list_type=${TaskListTypeConst.Project}`
        : 'Other';

    return (
      <li>
        <NavLink
          style={index === 0 ? { marginTop: 2 } : {}}
          className={({ isActive }) => {
            return isActive ? 'active_tab' : 'non_active';
          }}
          to={link}
        >
          <img className='tab-img' src={Images.Pin} />
          <span className='text'>{name}</span>
        </NavLink>
      </li>
    );
  };

  // return report visibility
  const reportVisible =
    !permissionsMap?.work_log &&
    // !permissionsMap?.work_history &&
    // !permissionsMap?.project_report &&
    !permissionsMap?.time_sheet;

  return (
    <>
      <div className='hamburger' onClick={toggleSidebar}>
        <img src='/icons/hamburger.svg' />
      </div>
      <div
        className={`sidebar side_bar_contain ${isCollapsed ? 'active' : ''} ${
          isOpen ? 'show' : 'hide'
        }`}
      >
        <div className='company_icon_contain pointer' onClick={() => navigate('/')}>
          {!isSettingLoading ? (
            isCollapsed ? (
              <HiDeskIcon />
            ) : (
              <img src={companyLogo ? companyIcon : Images.CompanyLogo} />
            )
          ) : (
            <LoadingIcon size={40} />
          )}
        </div>
        <div>
          <div className='menu'>
            <section className='menu-btn' onClick={() => setIsCollapsed(!isCollapsed)}>
              {isCollapsed ? (
                <section className='d-flex'>
                  <PanelLeftOpen size={15} color='#767676' />
                  <section className='button_side_bar_section'>Expand</section>
                </section>
              ) : (
                <section className='d-flex'>
                  <PanelLeftClose size={15} color='#767676' />
                  <section className='button_side_bar_section'>Collapse</section>
                </section>
              )}
            </section>
            <ul>
              {/* dashboard link start */}
              <li>
                {hasExist(MENU.dashboard.default_name) && (
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab ' : 'non-active';
                    }}
                    to='/'
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Dashboard'} />
                    <span className='text'> Dashboard</span>
                  </NavLink>
                )}
              </li>
              {/* dashboard link ends */}
              {/* my task */}
              <li>
                {hasExist(MENU?.myResponsibility?.default_name) && (
                  <NavLink
                    className={() => {
                      const params = new URLSearchParams(location?.search);
                      return params.get('list_type') === 'my-task' ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.TASK_LIST}?status=${self}&user_id=${user}&list_type=my-task`}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'My-Task'} />
                    <span className='text'>
                      {getLabelName(MENU?.myResponsibility?.default_name)}
                    </span>
                  </NavLink>
                )}
              </li>
              {/* my task end */}
              {/* team task */}
              <li>
                {hasExist(MENU.teamResponsibility.default_name) && (
                  <NavLink
                    className={() => {
                      const params = new URLSearchParams(location.search);
                      return params.get('list_type') === 'team-task' ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.TASK_LIST}?status=${team}&list_type=team-task`}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Task'} />
                    <span className='text'>
                      {getLabelName(MENU.teamResponsibility.default_name)}
                    </span>
                  </NavLink>
                )}
              </li>
              {/* team task end */}
              {/* extra template menu's */}
              {extraMenus && extraMenus.length ? ( // if only one extra menu
                <ExtraMenuItems data={extraMenus} handleNavLinkClick={handleNavLinkClick} />
              ) : null}
              {/* extra template menu's end */}
              {/* my team */}
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab' : 'non-active';
                  }}
                  to={`${RT.MY_TEAM_LIST}?status=active-users`}
                  onClick={handleNavLinkClick}
                >
                  <ReturnIcon name={'Team'} />
                  <span className='text'>My Team</span>
                </NavLink>
              </li>
              {/*end my team */}
              {/* OKR */}
              {hasExist(MENU.okr.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.OKR_LIST}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'OKR'} />
                    <span className='text'>OKR</span>
                  </NavLink>
                </li>
              )}
              {/*end OKR*/}
              {/* Projects */}
              {hasExist(MENU.project.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.PROJECT_LIST}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Project'} />
                    <span className='text'>{LABEL_OF_PROJECT}</span>
                  </NavLink>
                </li>
              )}
              {/*end Projects*/}
              {/* Contact */}
              {hasExist(MENU.contact.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.CLIENT}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={LABEL_OF_CLIENT} />
                    <span className='text'>{LABEL_OF_CLIENT}</span>
                  </NavLink>
                </li>
              )}
              {/*end Contact*/}
              {/* Chats */}
              {hasExist(MENU.chat.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive
                        ? 'active_tab position-relative'
                        : 'non-active position-relative';
                    }}
                    to={`${RT.CHAT}?tab=all`}
                    onClick={handleNavLinkClick}
                  >
                    <ReturnIcon name={'Chat'} />
                    <span className='text'>Chat</span>
                    {parseInt(chatCount) > 0 && <div className='chat-count'>{chatCount}</div>}
                  </NavLink>
                </li>
              )}
              {/*end Chats*/}
              {favorites?.labels && favorites?.labels?.length ? (
                <li>
                  <div onMouseEnter={handleMouseEnterPin} onMouseLeave={handleMouseLeavePin}>
                    <input
                      checked={isCheckedPin}
                      type='checkbox'
                      id='pinned'
                      name='pinned'
                      value='pinned'
                      ref={pinnedCheckRef}
                    />
                    <label htmlFor='pinned' style={{ width: '100%' }}>
                      <PinIconSideBar />
                      <span className='text'>{RT.PINNED}</span>
                      <ChevronDown className='arrow' size={14} />
                    </label>
                    <div id='pinnedDiv' className='sub-menu-container'>
                      <div className='hidden_contain overflow-hidden'>
                        {favorites?.labels?.map((label, index) => (
                          <>{favoriteItem(label, index)}</>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
              ) : null}
              {/* Calendar */}
              <li>
                <NavLink
                  className={({ isActive }) => {
                    return isActive ? 'active_tab' : 'non-active';
                  }}
                  to={`${RT.CALENDAR}?user_id=${user}`}
                  onClick={handleNavLinkClick}
                >
                  <ReturnIcon name={'Calendar'} />
                  <span className='text'>Calendar</span>
                </NavLink>
              </li>
              {/* Calendar end */}
              {/* Filesystem */}
              {hasExist(MENU.files.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.FILESYSTEM}/${RT.FILELIST}`}
                  >
                    <FileIconSidebar />
                    <span className='text'>Files</span>
                  </NavLink>
                </li>
              )}
              {/*end files*/}
              {/* template */}
              {hasExist(MENU.template.default_name) && (
                <li>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={RT.TEMPLATE_LIST}
                    onClick={handleNavLinkClick}
                  >
                    <TemplateIcon />
                    <span className='text'>Template</span>
                  </NavLink>
                </li>
              )}
              {/* template end */}
              {/* report */}
              {hasExist(MENU.reports.default_name) && (
                <li>
                  <div
                    style={{ display: 'flex', flexDirection: 'column' }}
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                  >
                    <input
                      checked={isChecked}
                      readOnly
                      type='checkbox'
                      id='todo'
                      name='todo'
                      value='todo'
                      ref={todoCheckboxRef}
                    />
                    {!reportVisible && (
                      <label htmlFor='todo' style={{ width: '100%' }}>
                        <ReportsIcon />
                        <span className='text'>{RT.REPORT}</span>
                        <ChevronDown className='arrow' size={14} />
                      </label>
                    )}
                    <div id='hiddenDiv' className='sub-menu-container'>
                      <div className='hidden_contain overflow-hidden'>
                        {permissionsMap?.work_log && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non_active';
                              }}
                              to={`${RT.WORK_ACTIVITY}?user_id=${user}&fromDate=${todayStart}&toDate=${todayEnd}`}
                            >
                              <WorkActivityIcon />
                              <span className='text'>Time Log</span>
                            </NavLink>
                          </li>
                        )}
                        {permissionsMap?.time_sheet && (
                          <li>
                            <NavLink
                              className={({ isActive }) => {
                                return isActive ? 'active_tab' : 'non_active';
                              }}
                              to={`${RT.TIME_HISTORY}`}
                            >
                              <TimeHistoryIcon />
                              <span className='text'>Time History</span>
                            </NavLink>
                          </li>
                        )}
                        <li>
                          <NavLink
                            className={({ isActive }) => {
                              return isActive ? 'active_tab' : 'non_active';
                            }}
                            to={`${RT.TEAM_REPORT}?fromDate=${todayStart}&toDate=${todayEnd}`}
                          >
                            <GroupUser height={17} width={19} isSidebar={true} />
                            <span className='text'>Team Report</span>
                          </NavLink>
                        </li>
                        <li>
                          <NavLink
                            className={({ isActive }) => {
                              return isActive ? 'active_tab' : 'non_active';
                            }}
                            to={`${RT.GRAPH_REPORT}/${RT.BAR_CHART}?user_id=${user}&fromDate=${monthStartDate}&toDate=${todayCurrent}`}
                          >
                            <ProgressIcon width={20} height={20} isSidebar={true} />
                            <span className='text'>Graphical Report</span>
                          </NavLink>
                        </li>
                      </div>
                    </div>
                  </div>
                </li>
              )}
              {/*end report */}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}

// extra menu item map
const ExtraMenuItems = ({ data = [], handleNavLinkClick = () => {} }) => {
  const [mouseOver, setMouseOver] = useState(false);
  const ref = useRef();
  const handleMouseEnter = () => {
    setMouseOver(true);
  };
  const handleMouseLeave = () => {
    setMouseOver(false);
  };
  if (data?.length == 1) {
    return (
      <li>
        <NavLink
          className={({ isActive }) => {
            return isActive ? 'active_tab' : 'non-active';
          }}
          to={`${RT.TEMPLATE}/${data?.[0]?.id}?status=${
            data?.[0]?.navigationId ?? 'in-progress'
          }&list_type=${TaskListTypeConst.Template}`}
          onClick={handleNavLinkClick}
        >
          {data?.[0]?.icon ? getTemplateMenu(data?.[0]?.icon) : <ReturnIcon name={'Template'} />}
          <span className='text'>{data?.[0]?.name}</span>
        </NavLink>
      </li>
    );
  }
  return (
    <li>
      <div onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <input
          checked={mouseOver}
          type='checkbox'
          id='pinned'
          name='pinned'
          value='pinned'
          ref={ref}
        />
        <label htmlFor='pinned' style={{ width: '100%' }}>
          <GalleryHorizontalEnd size={18} />
          <span className='text'>{RT.QUICK_SET}</span>
          <ChevronDown className='arrow' size={14} />
        </label>
        <div id='pinnedDiv' className='sub-menu-container'>
          <div className='hidden_contain overflow-hidden'>
            {data?.map((item, index) => {
              return (
                <li key={index}>
                  <NavLink
                    className={({ isActive }) => {
                      return isActive ? 'active_tab' : 'non-active';
                    }}
                    to={`${RT.TEMPLATE}/${item?.id}?status=${
                      item?.navigationId ?? 'in-progress'
                    }&list_type=${TaskListTypeConst.Template}`}
                    onClick={handleNavLinkClick}
                  >
                    {item?.icon ? getTemplateMenu(item?.icon) : <ReturnIcon name={'Template'} />}
                    <span className='text'>{item?.name}</span>
                  </NavLink>
                </li>
              );
            })}
          </div>
        </div>
      </div>
    </li>
  );
};
