/* eslint-disable no-unused-vars */
import { Maximize } from 'lucide-react';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Heading } from '../Misc';
import { ToggleButton } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT } from '../_constants';
import './notification.css';

const NotificationModalHead = ({
  tabValue,
  setIsNotificationVisible = () => {},
  isUnReadFlag,
  onHandleUnread = () => {},
}) => {
  const navigate = useNavigate();

  return (
    <div>
      <div className='d-flex justify-content-between align-items-center'>
        <Heading className={'notification_heading'} title={'Notifications'} />
        <div className='d-flex gap-3'>
          <div className='d-flex align-items-center justify-content-between gap-1 toggle-mt'>
            <ToolTip isModern tooltipText={isUnReadFlag ? 'Un read' : 'All'} popOver>
              <ToggleButton
                isChecked={isUnReadFlag}
                size={26}
                id={'is_unlisted'}
                handleToggle={onHandleUnread}
              />
            </ToolTip>
          </div>

          <div>
            <ToolTip isModern tooltipText={'View all'} popOver>
              <Maximize
                color='#87909E'
                width={16}
                height={15}
                onClick={() => {
                  navigate(`${RT.NOTIFICATION}/task`);
                  setIsNotificationVisible(false);
                }}
              />
            </ToolTip>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NotificationModalHead;
