import { keepPreviousData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { Home, Hourglass } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Table, Tbody, Td, Th, Thead, Tr } from 'react-super-responsive-table';
import { TableRow } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import DatePicker from '../_components/DatePicker';
import IconLabelSet from '../_components/IconLabelSet';
import SearchableQuerySelect from '../_components/SearchableQuerySelect';
import { CustomDropdownIndicator } from '../_components/SelectionDropDownIcon';
import UserDropDown from '../_components/UserDropDown';
import { apiConstants, RT, USER_LIST_ACTIONS } from '../_constants';
import useDebounce from '../_helpers/useDebounce';
import { userService } from '../_services';
import { labelService } from '../_services/label.service';
import { reportServices } from '../_services/reports.service';
import useObserver from '../Hook/useObserver';
import useOutsideClickHandler from '../Hook/useOutsideClickHandler';
import { CustomLabelSelectModal } from '../Task/TaskComponents/CustomInputs';
import { taskAssignUserSelect } from '../Task/taskConstants';
import { TagsIcon } from '../Task/taskIcons';
import {
  floatToStringTime,
  floatToTime,
  formatMillisecondFeatured,
  fullNameUser,
  GlobalContext,
  Images,
} from '../Utils';
import {
  Clock,
  FileIcon,
  LoadingIcon,
  ReportProgressIcon,
  SubtaskIcon,
  User,
} from '../Utils/SvgIcons';
import { NEW_TIME_HISTORY_SELECTION_BOX } from './Report.constant';
import { CustomSingleValue } from './TimeHistory';
export function TeamReport() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedUser, setSelectedUser] = useState();
  const { makeAlert } = useContext(GlobalContext);
  const [labelListVisible, setLabelListVisible] = useState(false);
  const [filterLabels, setFilterLabels] = useState([]);

  const getArrayParam = (param) => {
    const value = searchParams.getAll(param);
    return value.length > 0 ? value : null;
  };

  // filter ids in url---
  const labels = getArrayParam('label');
  const user_id = searchParams.get('user_id');
  const fromDate = searchParams.get('fromDate');
  const toDate = searchParams.get('toDate');

  // filter objects pass with API
  const filterToUrl = {};
  if (labels) filterToUrl.labels = labels;
  if (user_id) filterToUrl.user_id = user_id;
  if (fromDate) filterToUrl.fromDate = fromDate;
  if (toDate) filterToUrl.toDate = toDate;

  const filteredLabels = getArrayParam('label');

  const [labelSearch, setLabelSearch] = useState('');

  const debouncedLabel = useDebounce(labelSearch, 500);

  // to get label/tag list for change label/tag
  const { data: labelData, isLoading: isLabelLoading } = useQuery({
    queryKey: ['labelData', debouncedLabel],
    queryFn: () =>
      labelService.labelList({
        searchKey: debouncedLabel,
        select: ['id', 'name', 'label_color', 'text_color'],
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  const fetchLabel = async () => {
    // Filter out labels that don't need to be fetched (either in state or from labelData)
    const labelsToFetch = filteredLabels.filter((labelId) => {
      // Skip labels already in filterLabels state or available in labelData

      return (
        !filterLabels.some((label) => label.id == labelId) &&
        !labelData.some((label) => label.id == labelId)
      );
    });

    // Fetch new label data for labelsToFetch
    const fetchedLabels = await Promise.all(
      labelsToFetch.map(async (labelId) => {
        const { data } = await labelService.labelGet({
          id: labelId,
          select: ['id', 'name', 'label_color', 'text_color'],
        });
        return data?.[0]; // Return the first label from the API response
      }),
    );

    // Create a copy of filterLabels array
    const updatedFilterLabels = [...filterLabels];

    // Add labels from labelData matching the labelId in the URL directly to filterLabels state
    labelData.forEach((label) => {
      // Check if label is in filteredLabels and not already in filterLabels
      if (
        filteredLabels.includes(label.id) &&
        !updatedFilterLabels.some((l) => l.id === label.id)
      ) {
        updatedFilterLabels.push(label);
      }
    });

    // Update filterLabels state with newly fetched labels if any were fetched
    if (fetchedLabels.length > 0) {
      updatedFilterLabels.push(...fetchedLabels); // Spread fetched labels into the array
    }

    // Update the state with the updated labels
    setFilterLabels(updatedFilterLabels);
  };

  // for fetch log
  const {
    data: teamReportData,
    error: reportError,
    isLoading,
    isSuccess,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    // isLoading:reportLoading
  } = useInfiniteQuery({
    queryKey: ['team-report-list', filterToUrl],
    queryFn: ({ pageParam = 1 }) =>
      reportServices.teamReportList({
        pageVo: { noOfItems: 10, pageNo: pageParam },
        ...filterToUrl,
      }),
    getNextPageParam: (response) =>
      response.data.page < response.data.pages ? response.data.page + 1 : undefined,
    placeholderData: keepPreviousData,
    refetchOnWindowFocus: false,
  });
  if (reportError) makeAlert(reportError.message);

  const { observerRef } = useObserver({
    onIntersection: () => {
      hasNextPage && fetchNextPage();
    },
    dependency: [hasNextPage],
  });

  // fetch file details
  // eslint-disable-next-line no-unused-vars
  const { data: label } = useQuery({
    queryKey: ['label-data', filteredLabels],
    queryFn: () => fetchLabel(),
    enabled:
      !!filteredLabels?.length &&
      filteredLabels.some(
        (labelId) => !filterLabels.some((label) => parseInt(label.id) === parseInt(labelId)),
      ), //   !labelData.includes(), // Only run the query if the id is set
    placeholderData: keepPreviousData,
  });

  // handle to set params like user_id
  const handleSetSearchParams = (name, value, isAppend = false, isRemove = false) => {
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    if (isRemove) {
      updatedSearchParams.delete(`${name}`, value);
    } else if (isAppend) {
      updatedSearchParams.append(`${name}`, value);
    } else {
      if (value) {
        updatedSearchParams.set(`${name}`, value);
      } else {
        updatedSearchParams.delete(name);
      }
    }
    setSearchParams(updatedSearchParams);
  };

  // handle change of date
  const handleDateChange = (dates) => {
    const startDate = moment(dates.startDate).startOf('day');
    const endDate = moment(dates.endDate).endOf('day');
    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    updatedSearchParams.set('fromDate', startDate.valueOf());
    updatedSearchParams.set('toDate', endDate.valueOf());
    setSearchParams(updatedSearchParams);
  };

  // handler to change user
  const handleChange = (e) => {
    const { name, value } = e.target;
    handleSetSearchParams(name, value);
  };
  const { containerRef: labelRef } = useOutsideClickHandler({
    onClose: () => {
      setLabelSearch('');
      setLabelListVisible(false);
    },
  });
  // fetch user Data assignee's with current user
  const { data: userData, error: assignUserFetchError } = useQuery({
    queryKey: ['user-list'],
    queryFn: () =>
      userService.getPermittedUser({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.WORK_HISTORY,
      }),
    select: (data) => {
      const userData = data?.data?.rows?.map((item) => {
        return {
          value: item.id,
          label: item.first_name + ' ' + item.last_name,
          id: item.id,
          ...item,
        };
      });
      return userData;
    },
  });
  if (assignUserFetchError) makeAlert(assignUserFetchError.message);

  // to change label to required format and save
  const handleLabelUpdate = (item) => {
    handleSetSearchParams('label', item, true);
  };

  // to remove label
  const handleLabelRemove = (item) => {
    handleSetSearchParams('label', item, false, true);
    setFilterLabels((prev) => prev.filter((label) => label.id !== item));
  };

  const filterCount = 0;

  // const flattedUpdateData = React.useMemo(
  //   () => teamReportData?.pages?.flatMap((page) => page.data.data),
  //   [teamReportData],
  // );
  const { flattedUpdateData, totalSumOfEffort, totalEstimatedHours } = React.useMemo(() => {
    // Initialize variables for flattened data, total effort, and total estimated hours
    let flattedData = [];
    let totalSumOfEffort = 0;
    let totalEstimatedHours = 0;

    if (teamReportData?.pages) {
      // Iterate over each page to accumulate data, total effort, and total estimated hours
      teamReportData.pages.forEach((page) => {
        if (page?.data?.data) {
          flattedData = [...flattedData, ...page.data.data]; // Flatten data
        }

        if (page?.data?.totalSumOFEffort) {
          totalSumOfEffort = page.data.totalSumOFEffort; // Accumulate total effort
        }

        if (page?.data?.totalEstimatedHours) {
          totalEstimatedHours = page.data.totalEstimatedHours; // Accumulate total estimated hours
        }
      });
    }

    return { flattedUpdateData: flattedData, totalSumOfEffort, totalEstimatedHours };
  }, [teamReportData]);

  // Use the flattedUpdateData, totalSumOFEffort, and totalEstimatedHours wherever necessary

  const allUpdateList = flattedUpdateData ? [...flattedUpdateData] : [];

  const convertedTotalHour = floatToTime(totalEstimatedHours);
  const totalEstimatedHour = convertedTotalHour.hours;
  const totalEstimatedMinute = convertedTotalHour.minutes;

  const tableData = allUpdateList?.map((row, index) => {
    const slNo = index + 1;
    const userName = fullNameUser(row.first_name, row.last_name);
    const isMale = row.gender === 1;
    const isNeutral = row.gender === 3;
    const url = row?.compressed_image_url ? row?.compressed_image_url : row?.image_url;
    const finalUrl = url ? apiConstants.imgUrlS3 + url : null;

    const expectedHourObj = floatToTime(row.total_expected_hours);
    const expectedHourStr = floatToStringTime(expectedHourObj.hours, expectedHourObj.minutes);
    // const expectedHourStr =
    //   expectedHourObj.hours && expectedHourObj.minutes
    //     ? `${expectedHourObj.hours} hrs ${expectedHourObj.minutes}min`
    //     : expectedHourObj.hours
    //     ? `${expectedHourObj.hours} hr`
    //     : expectedHourObj.minutes
    //     ? ` ${expectedHourObj.minutes}min`
    //     : '0 hr';

    return (
      <Tr key={index + 1}>
        <td style={{ fontWeight: 500, paddingLeft: 24 }}>{slNo}</td>

        <Td>
          <div className='d-flex align-items-center'>
            <CircularAvatar
              size={25}
              className='fz-none zoom-in employee-img-with-low-border'
              source={finalUrl}
              isMale={isMale}
              isNeutral={isNeutral}
            />
            <span style={{ fontWeight: 500 }}>{userName}</span>
          </div>
        </Td>
        <Td> {row.total_tasks}</Td>
        <Td>{row.total_subtasks}</Td>

        <Td>{row.total_non_time_bound_tasks}</Td>
        <Td>{formatMillisecondFeatured(parseInt(row.total_time), true)}</Td>

        <Td>{expectedHourStr}</Td>
      </Tr>
    );
  });

  // url define function
  const buildTeamReportUrl = () => {
    const userId = searchParams.get('user_id');
    const fromDate = searchParams.get('fromDate');
    const toDate = searchParams.get('toDate');

    let url = `/${RT.TEAM_REPORT}?`;
    if (fromDate) url += `fromDate=${fromDate}&`;
    if (toDate) url += `toDate=${toDate}&`;
    if (userId) url += `user_id=${userId}`;

    return url.endsWith('&') ? url.slice(0, -1) : url; // Remove trailing '&' if needed
  };

  return (
    <div className=' fade-entry'>
      {/* breadcrumbs area  */}
      <section>
        <div className='d-flex align-items-center url-container  breadcrumbs'>
          <IconLabelSet
            label={'Home'}
            fontWeight={400}
            color={'#67677F'}
            icon={<Home size={16} />}
            onClick={() => navigate(`/`)}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={'Reports'}
            icon={<ReportProgressIcon width={14} height={14} />}
            gap={3}
            fontWeight={400}
            color={'#67677F'}
            onClick={() => navigate(buildTeamReportUrl())}
          />
          <img className='slash-img-icon-size ms-1 mt-1' src='/icons/Lineicon.png' alt='' />
          <IconLabelSet
            label={'Team Report'}
            fontWeight={500}
            icon={<Clock width={14} height={14} color='#2A2E34' />}
            color={'#2A2E34'}
            gap={3}
            onClick={() => navigate(buildTeamReportUrl())}
          />
        </div>
      </section>

      <div className='time-log-container'>
        <div className='mb-2'>
          <div className='report-heading '>Team Report</div>
          <div className='report-filter'>
            <div style={{ width: 'fit-content' }}>
              <SearchableQuerySelect
                isClearable
                queryKey={'document-type-list'}
                queryFn={userService.getPermittedUser}
                getFunctionParams={(searchKey) => ({
                  searchKey,
                  action: USER_LIST_ACTIONS.WORK_HISTORY,
                  select: ['id', 'first_name', 'last_name', 'image_url', 'compressed_image_url'],
                })}
                isCustomFunction
                initialValue={userData}
                menuPortalTarget={document.getElementById('MODAL')}
                formatOptionLabel={(data, metaData) => (
                  <UserDropDown data={data} metaData={metaData} hideDesignation={true} />
                )}
                getOptionValue={(option) => option.id}
                onChange={(selectedOptions) => {
                  setSelectedUser(selectedOptions);
                  handleChange({
                    target: { value: parseInt(selectedOptions?.id), name: 'user_id' },
                  });
                }}
                styles={NEW_TIME_HISTORY_SELECTION_BOX}
                components={{
                  SingleValue: (props) => (
                    <CustomSingleValue {...props} icon={<User color='#07082F' />} />
                  ),
                  DropdownIndicator: CustomDropdownIndicator,
                }}
                placeholder={
                  <div className='d-flex align-items-center gap-1'>
                    <User /> User
                  </div>
                }
                value={selectedUser}
              />
            </div>
            <DatePicker
              inputContainerClass={'date-picker fit-width'}
              isDatePrefixNeed={false}
              onDateChange={handleDateChange}
              initialStartDate={parseInt(searchParams.get('fromDate'))}
              initialEndDate={parseInt(searchParams.get('toDate'))}
              isEditable
              monthFormat='short'
            />
            <div
              className='date-picker fit-width gap-1 position-relative'
              style={{
                background: filterCount ? '#e9f2ff' : '',
                color: filterCount ? '#3454d1' : '',
              }}
              onClick={() => {
                setLabelListVisible(true);
              }}
              ref={labelRef}
            >
              <TagsIcon color={filterCount ? '#3454d1' : '#2A2E34'} width='14' height='14' />
              Label
              {Boolean(filterCount) && (
                <div className='filter-count' style={{ right: 0, left: '', bottom: 20 }}>
                  {filterCount}
                </div>
              )}
              {labelListVisible && (
                <CustomLabelSelectModal
                  defaultLabelDetails={filterLabels}
                  // labelData={labelData}
                  labelData={
                    // const unselectedLabels =
                    filteredLabels
                      ? labelData?.filter((element) => !filteredLabels.includes(element.id))
                      : labelData
                  }
                  key={1}
                  labelDataLoading={isLabelLoading}
                  setLabelSearch={(value) => setLabelSearch(value)}
                  handleAdd={(item) => handleLabelUpdate(item)}
                  handleRemove={(item) => handleLabelRemove(item)}
                  top=''
                />
              )}
            </div>
          </div>
        </div>

        <section className='mt-2'>
          <div className='team-report-table table-container no-padding'>
            <Table className='table table-hover tbl mb-0'>
              <Thead>
                <Tr style={{ backgroundColor: '#fff' }} className='table-head'>
                  {TEAM_REPORT_TABLE_HEADINGS.map((heading, index) => {
                    return (
                      <Th
                        key={index}
                        scope='col'
                        style={{ minWidth: heading.minWidth, maxWidth: heading.maxWidth }}
                      >
                        {heading.name}
                      </Th>
                    );
                  })}
                </Tr>
              </Thead>
              <Tbody style={{ verticalAlign: 'middle' }}>
                {isLoading && (
                  <TableRow noOfCols={8}>
                    <LoadingIcon color='blue' size={60} />
                  </TableRow>
                )}
                {/* {!listItemCount && isSuccess && <TableRow noOfCols={8}>No data found</TableRow>} */}
                {/* {listItemCount > 0 && isSuccess && tableData} */}
                {isSuccess && tableData}

                <div
                  ref={observerRef}
                  className='d-flex justify-content-center'
                  style={{ minHeight: '2px' }}
                >
                  {hasNextPage && isFetchingNextPage && isLoading && (
                    <div
                      className='cursor-pointer'
                      onClick={() => hasNextPage && fetchNextPage()}
                      style={{ height: '100px' }}
                    >
                      <LoadingIcon size={60} />
                    </div>
                  )}
                </div>
              </Tbody>
            </Table>
          </div>
          <div className='mt-3 d-flex justify-content-between'>
            <div className='table-info-footer'>
              *the user list is only accessible for the reporting user
            </div>
            <div className='fz-14px'>
              <div>
                <span>Total team working hours : </span>
                <span style={{ fontWeight: 500, marginLeft: 10 }}>
                  {formatMillisecondFeatured(parseInt(totalSumOfEffort), true)}
                </span>
              </div>
              <div>
                <span>Total team expected hours : </span>
                <span style={{ fontWeight: 500, marginLeft: 10 }}>
                  {floatToStringTime(totalEstimatedHour, totalEstimatedMinute)}
                </span>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  );
}
const TEAM_REPORT_TABLE_HEADINGS = [
  { name: <img src={Images.SerialNo} height={14} width={14} />, minWidth: 50 },
  {
    name: (
      <div className='heading-title'>
        <div className='heading-icon'>
          <User height={16} width={16} color='#87909E' />
        </div>
        Users
      </div>
    ),
    minWidth: '10vw',
    maxWidth: '20vw',
  },
  {
    name: (
      <div className='heading-title'>
        <div className='heading-icon'>
          <FileIcon color='#87909E' />
        </div>
        Total Tasks
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='heading-title'>
        <div className='heading-icon'>
          <SubtaskIcon color='#87909E' height='15' width='21' strokeWidth='2' />
        </div>
        Total Sub Tasks
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='heading-title'>
        <div className='heading-icon'>
          <Clock height={16} width={16} />
        </div>
        Non time bound tasks
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='heading-title'>
        <div className='heading-icon'>
          <Clock height={16} width={16} />
        </div>
        Total Working Hours
      </div>
    ),
    minWidth: 50,
  },
  {
    name: (
      <div className='heading-title'>
        <div className='heading-icon'>
          <Hourglass color='#879096' size={16} />
        </div>
        Total Expected Hours
      </div>
    ),
    minWidth: 50,
  },
];
