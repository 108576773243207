// import { useQuery } from '@tanstack/react-query';
import { Search } from 'lucide-react';
import React, { useState } from 'react';
import { Button } from '../_components';
import CircularAvatar from '../_components/Avatar/CircularAvatar';
import { apiConstants } from '../_constants';
import { fullNameUser } from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
// import { userService } from '../_services';

export const UserListSelectionPopup = ({
  userList,
  selectedUsers,
  handleSave = () => {},
  // eslint-disable-next-line no-unused-vars
  handleClose = () => {},
  setSearchKey,
  searchKey,
  isLoading,
  isUserList = true,
}) => {
  const [checkedItems, setCheckedItems] = useState(selectedUsers || []);

  const handleCheckBoxChange = (user) => {
    if (isUserList) {
      setCheckedItems((prevState) => {
        const userId = parseInt(user?.id);
        if (prevState.some((item) => parseInt(item.id) === userId)) {
          return prevState.filter((item) => parseInt(item.id) !== userId);
        } else {
          return [...prevState, user];
        }
      });
    } else if (!isUserList) {
      setCheckedItems([user]);
      // setCheckedItems((prevState) => {
      //   const userId = parseInt(user?.id);
      //   if (prevState.some((item) => parseInt(item.id) === userId)) {
      //     return prevState.filter((item) => parseInt(item.id) !== userId);
      //   } else {
      //     return [...prevState, user];
      //   }
      // });
    }
  };

  return (
    <div className='menu_popup filter-container'>
      <div className='d-flex align-items-center px-3 gap-1'>
        <Search size={15} color='grey' />
        <input
          placeholder='Search'
          className='w-100 unset-btn'
          value={searchKey}
          onChange={(e) => setSearchKey(e.target.value)}
          style={{ height: 40 }}
        />
      </div>
      <div className='list-section pt-1'>
        {isLoading && <LoadingIcon size={20} />}
        {userList?.length === 0 && (
          <div className='text-center' style={{ color: '#878787' }}>
            No Data
          </div>
        )}
        {userList?.length > 0 &&
          userList?.map((user) => {
            if (isUserList) {
              const isMale = user?.gender === 1;
              const isNeutral = user?.gender === 3;
              const url = user?.compressed_image_url ? user?.compressed_image_url : user?.image_url;
              const finalUrl = url ? apiConstants.imgUrlS3 + url : null;
              const fullName = fullNameUser(user?.first_name, user?.last_name);
              return (
                <div
                  className='d-flex align-items-center mb-2 px-3 pointer'
                  key={user?.id}
                  onClick={() => handleCheckBoxChange(user)}
                >
                  <input
                    className='project_menu_check_box '
                    type='checkbox'
                    checked={checkedItems?.some((item) => parseInt(item.id) === parseInt(user?.id))}
                    // onChange={() => handleCheckBoxChange(user)}
                  />
                  <span className='flex-grow-1'>
                    <div className='d-flex align-items-center'>
                      <CircularAvatar
                        size={25}
                        className='fz-none zoom-in employee-img-with-low-border'
                        source={finalUrl}
                        isMale={isMale}
                        isNeutral={isNeutral}
                      />
                      <span className='name-overflow'>{fullName}</span>
                    </div>
                  </span>
                </div>
              );
            } else if (!isUserList) {
              return (
                <div className='d-flex align-items-center mb-2 px-3 gap-1' key={user?.id}>
                  <input
                    className='project_menu_check_box cursor-pointer '
                    type='radio'
                    checked={checkedItems?.some(
                      (item) => parseInt(item?.id) === parseInt(user?.id),
                    )}
                    onChange={() => handleCheckBoxChange(user)}
                  />
                  <span className='flex-grow-1'>
                    <div className='d-flex align-items-center'>
                      <span className='name-overflow'>{user?.name}</span>
                    </div>
                  </span>
                </div>
              );
            }
          })}
      </div>

      <div className=' d-flex gap-2 justify-content-end mt-2 me-2'>
        <Button.Container
          type='button'
          className='cancel-btn'
          handleOk={(event) => {
            handleClose(event);
          }}
        >
          <Button.Title title='Cancel' />
        </Button.Container>
        <Button.Container
          type='submit'
          handleOk={(event) => {
            handleSave(checkedItems);
            handleClose(event);
          }}
        >
          <Button.Title title={'Save'} />
          <Button.Loading />
        </Button.Container>
      </div>
    </div>
  );
};
