import { File, Headphones, Image, Mic, Video } from 'lucide-react';
import moment from 'moment';
import React from 'react';
import CircularAvatar from '../../_components/Avatar/CircularAvatar';
import OkrAudioPlayer from '../../_components/OkrAudioPlayer';
import { apiConstants } from '../../_constants';
import { MESSAGE_TYPES } from '../../_constants/chat.constants';
import { fullNameUser } from '../../Utils';
import { DownArrow, SmileyIcon } from '../../Utils/SvgIcons';
import { ChatMessageCards } from './ChatMessageCards';

// ---------------------------- Newer Message Cards -----------------
const MessageCardWrapper = ({ children, hide, hasEmoji }) => {
  if (hide) {
    return null;
  }
  return <div style={hasEmoji ? { marginBottom: 15 } : {}}>{children}</div>;
};

const MessageCardContainer = ({
  children,
  data,
  isOtherUser = false,
  replyRef,
  showProfile = true,
  separatedMsgs = true,
  isConsecutiveUserMsg,
  onMoreOptionClick,
  selectedPosition,
}) => {
  const { image_url, gender, compressed_image_url } = data.creator_details;
  const imageUrl = compressed_image_url
    ? apiConstants.imgUrlS3 + compressed_image_url
    : apiConstants.imgUrlS3 + image_url;

  return (
    <div
      className={`d-flex box-message-container ${data.isUploading ? 'blink' : ''} ${
        isConsecutiveUserMsg ? 'pt-0' : ''
      } ${!isOtherUser ? `${separatedMsgs ? 'right-container' : ''} ` : ''}`}
      style={{ gap: 2 }}
      ref={replyRef}
    >
      {showProfile && (
        <div style={isConsecutiveUserMsg ? { visibility: 'hidden' } : {}}>
          <CircularAvatar
            isMale={gender === 1}
            isCentered={false}
            size={33}
            source={imageUrl}
            className='sticky-profile-pic'
          />
        </div>
      )}
      <MessageCard
        data={data}
        isOtherUser={isOtherUser}
        showProfile={showProfile}
        isConsecutiveUserMsg={isConsecutiveUserMsg}
        separatedMsgs={separatedMsgs}
      >
        {children}
      </MessageCard>
      <div
        className={`box-message-options ${
          selectedPosition?.item?.id === (data.id ?? data.tempId)
            ? 'box-message-options-active'
            : ''
        } `}
        onClick={(event) => {
          onMoreOptionClick(event.clientX, event.clientY + 20);
        }}
      >
        <div className='d-flex gap-1 align-items-center'>
          <SmileyIcon />
          <DownArrow />
        </div>
      </div>
    </div>
  );
};

const MessageCard = ({
  data,
  children,
  isOtherUser = false,
  showProfile,
  separatedMsgs,
  isConsecutiveUserMsg,
}) => {
  const { first_name, last_name } = data.creator_details;
  const fullName = fullNameUser(first_name, last_name);
  return (
    <div
      className={`box-message-card talk-bubble ${
        !isConsecutiveUserMsg ? 'tri-right' : ''
      } left-top box-left ${
        !isOtherUser ? `${separatedMsgs ? 'right-grey-card' : 'left-grey-card'}` : ''
      }`}
    >
      {!isConsecutiveUserMsg && showProfile && (
        <div className='fz-13px fw-600 box-message-full-name'>{fullName}</div>
      )}
      {/* Content comes  */}
      <div>{children}</div>
      <div className='fz-10px box-message-time'>{moment(data.createdAt).format('hh:mm a')}</div>
    </div>
  );
};

const ReplyCard = ({
  children,
  onClick = () => {},
  isOtherUser = false,
  separatedMsgs = false,
}) => {
  return (
    <div
      className={`box-reply-card d-flex align-items-center ${
        !isOtherUser && separatedMsgs ? 'white-reply-card' : ''
      }`}
      onClick={onClick}
      style={{ fontSize: 11, gap: 8 }}
    >
      <div className='box-reply-left-strip' />
      {children}
    </div>
  );
};

const ReplyItem = ({ data }) => {
  const { first_name, last_name } = data.creator_details;
  const fullName = fullNameUser(first_name, last_name);
  const isFileReply = data?.files && data?.files?.length > 0;

  return (
    <div className='box-reply-content-container'>
      <div className=' fw-600 box-reply-full-name'>{fullName}</div>
      <div className='box-reply-content'>
        {!isFileReply ? (
          <ChatMessageCards.Text data={data} containerClass='clamp-text' />
        ) : (
          <FileReply hasMessage={Boolean(data?.message)} containerClass='clamp-text' item={data} />
        )}
      </div>
    </div>
  );
};

/***
 * For displaying the file reply
 */
const FileReply = ({ hasMessage, item, containerClass = '' }) => {
  const attachedType = item?.files?.[0]?.attachedType;

  const typeObject = {
    3: { label: 'Audio', Icon: <Headphones size={10} /> }, //Audio
    7: { label: 'Video', Icon: <Video size={10} /> }, //video
    2: { label: 'Document', Icon: <File size={10} /> }, //Docs
    9: { label: 'Voice', Icon: <Mic size={10} /> }, //Voice Record
    8: { label: 'Image', Icon: <Image size={10} /> }, //image
  };

  const requiredType = attachedType && typeObject[attachedType];

  if (!requiredType) {
    return <div className={`fz-11px-rem chat-text-message`}>Unknown type</div>;
  }

  const { Icon } = requiredType;

  return (
    <div
      className={`fz-11px-rem chat-text-message d-flex gap-1 align-items-center ${containerClass}`}
    >
      {Icon && Icon}
      {hasMessage ? <div>{item.message}</div> : <div>{requiredType.label}</div>}
    </div>
  );
};

const RecordedAudio = ({ file, isLocal, iconSize = 40 }) => {
  // const [play, setPlay] = useState(false);
  // const [isAudioLoading, setIsAudioLoading] = useState(true);

  const audioUrl = isLocal ? file.blobUrl : apiConstants.imgUrlS3 + file?.uploaded_path;

  if (file.attachedType !== MESSAGE_TYPES.RECORDED_AUDIO) {
    return null;
  }

  return (
    <div className='d-flex recorder-audio-preview'>
      <OkrAudioPlayer
        src={audioUrl}
        iconSize={iconSize}
        disableAvatar
        containerClassName='box-msg-recorded-audio'
      />
    </div>
  );
};

// For reactions
const SelectedReactions = ({ emojiList = [], onClick = () => {} }) => {
  if (!emojiList || emojiList.length <= 0) {
    return null;
  }

  return (
    <div className='reaction-container mt-1 d-flex gap-1 fz-12px '>
      {emojiList.map((emojiObj, index) => {
        return (
          <React.Fragment key={index}>
            <div
              onClick={() => onClick(emojiObj.emoji)}
              style={{ borderRadius: '100px' }}
              className={`btn-wrapper p-1 ${emojiObj.count ? 'selected' : ''}`}
            >
              {emojiObj.emoji}
              {Boolean(emojiObj.count) && emojiObj.count}
            </div>
          </React.Fragment>
        );
      })}
    </div>
  );
};

// Box styled Message Cards
export const BoxMessageCards = {
  MessageCardWrapper,
  MessageCardContainer,
  ReplyCard,
  ReplyItem,
  RecordedAudio,
  SelectedReactions,
  FileReply,
};
