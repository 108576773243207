/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Button } from '../_components/CustomButton';
import { alertConstants } from '../_constants';
import { labelService } from '../_services/label.service';
import { ColorPicker } from '../Settings/UtilsInSettings';
import { LabelState } from '../Utils';

export function LabelAdd({ id, handleClose = () => {}, afterAdd = () => {} }) {
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState({ message: '' });
  const [label, setLabel] = useState(LabelState);
  console.log(id);
  const [query, setQuery] = useState({
    select: ['id', 'name', 'text_color', 'label_color'],
  });

  useEffect(() => {
    if (id && id != '0') {
      query.id = id;
      getAllLabel(query);
    }
  }, [id]);

  /**
   * Get Label
   * */
  const getAllLabel = async (queryTemp) => {
    let queryNew = queryTemp ? queryTemp : query;

    setQuery(queryNew);
    setIsLoading(true);

    try {
      const org = await labelService.labelGet(queryNew);
      if (org?.data?.length) {
        setLabel(org.data[0]);
      }
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
    }
    console.log(error);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setLabel({
      ...label,
      [name]: value,
    });
  };

  const handleColorChange = (name, value) => {
    setLabel({
      ...label,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    setSubmitted(true);

    if (label?.name.toString().trim() === '') {
      setIsLoading(false);
      return null;
    }

    try {
      await labelService.labelAdd(label).then((data) => {
        afterAdd({
          id: data?.data?.id,
          name: data?.data?.name,
          label_color: data?.data?.label_color,
          text_color: data?.data?.text_color,
        });
        setSubmitted(false);
      });
      handleClose();
    } catch (error) {
      setError({
        message: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
      setLabel(LabelState);
    }
  };
  return (
    <div className='px-4  py-3' style={{ fontSize: '13px' }}>
      <form className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div className='row mb-2'>
          <div>
            <label htmlFor='name' className='mb-1 fz-13px-rem'>
              Label Name<span className='field-required'> *</span>
            </label>
            <input
              style={{ fontSize: '13px' }}
              type='text'
              // className='form-control'
              className='input-box'
              id='name'
              name='name'
              value={label?.name}
              placeholder='Enter label name '
              onChange={(event) => {
                handleChange(event);
              }}
              autoFocus
            />
            {submitted && !label?.name.trim() && (
              <div className='help-block'>Label name is required</div>
            )}
          </div>
        </div>

        <div className='row mb-4 '>
          <div className='col-md-6  '>
            <ColorPicker
              heading={'Label Color'}
              value={label?.label_color}
              handleChange={(e) => handleColorChange('label_color', e.target.value)} // Pass the handleColorChange function directly
              id='label_color'
              onBlur={() => {}}
            />
          </div>
          <div className='col-md-6'>
            <ColorPicker
              heading={'Text Color'}
              value={label?.text_color}
              handleChange={(e) => handleColorChange('text_color', e.target.value)} // Pass the handleColorChange function directly
              id='text_color'
              onBlur={() => {}}
            />
          </div>
        </div>
        <div className='row '>
          <div className=' d-flex justify-content-end gap-2'>
            <Button.Container
              className={'cancel_btn'}
              type='reset'
              handleOk={() => {
                handleClose();
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>

            <Button.Container type='submit' isDisabled={isLoading}>
              <Button.Title title={'Save'} />
              <Button.Loading isLoading={isLoading} />
            </Button.Container>
          </div>
        </div>
      </form>
    </div>
  );
}
