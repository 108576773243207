export const userConstants = {};
export const USER_LIST_ACTIONS = {
  TASK_ADD: 'TASK-ADD',
  TASK_EDIT: 'TASK-EDIT',
  OKR_ADD: 'OKR-ADD',
  TASK_VERIFY: 'TASK-VERIFY',
  TASK_FILTER: 'TASK-FILTER',
  WORK_HISTORY: 'WORK-HISTORY',
  CHAT_UPDATE: 'CHAT-UPDATE',
  FILE_SYSTEM: 'FILE-SYSTEM',
  OKR: 'OKR',
  BOARD_FILTER: 'BOARD-FILTER',
};

export const USER_PLACEHOLDER = {
  GROUP: '/group-placeholder2.svg',
  MALE: '/placeholder.svg',
  FEMALE: '/placeholder.svg',
};

export const ROLE = {
  ADMIN: 1,
  USER: 2,
  MANAGER: 3,
  // Roles below are currently not in use
  BUSINESS_UNIT: 7,
  AGENCY: 5,
  CANDIDATE: 3,
  EMPLOYER: 4,
  STAFF: 6,
};

export const USER_ROLE = {
  ADMIN: 1,
  EMPLOYEE: 2,
  MANAGER: 3,
};
