/* eslint-disable no-unused-vars */
import { ChevronUp, Pen, Plus, Trash } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import ReactSelect from 'react-select';
import {
  CustomEmptyContainer,
  DateAndTimeRangePicker,
  StatusOption,
} from '../../Task/TaskComponents/CustomInputs';
import {
  CustomStatusSelectBoxStyles,
  SubtaskStatusArray,
  TASK_LIST_EMPTY_MESSAGE,
} from '../../Task/taskConstants';
import { GlobalContext, Images } from '../../Utils';
import { Close, SubTaskIcon, Tick } from '../../Utils/SvgIcons';
import { Button, Modal } from '../../_components';
import { Popover } from '../../_components/Popover';
import ToolTip from '../../_components/Tooltip/ToolTip';
import { CONTEXT_MENU_OPTIONS, POSITION } from '../../_constants/contextMenu.constants';
import { DELETE_NOTE } from '../../_constants/deletenote.constants';
import '../template.css';
import './date.css';

// eslint-disable-next-line no-unused-vars
function SubtaskListWithAdd({
  list = [],
  onAddOrUpdate = () => {},
  title = 'Subtask',
  onDelete = () => {},
  SingleClassName,
  position,
  isHeading = true,
  from = 'add',
  isChangeStatus = false,
  headVisible = true,
  disableNonEmptyView,
  textMinWidth,
  disableAdd = false,
}) {
  const { makeAlert } = useContext(GlobalContext);
  const [isTyping, setIsTyping] = useState(false);
  const [contextMenuIndex, setContextMenuIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [input, setInput] = useState('');
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [isKeyDown, setIsKeyDown] = useState(false);
  const [isSubTaskVisible, setIsSubTaskVisible] = useState(true);
  const [isDelete, setIsDelete] = useState({ modal: false, subtaskId: null });

  const textareaRef = useRef(null);

  list = list?.map((item) => {
    // ... rest of the code
    let currentDate = new Date();
    const newStartDate = new Date(currentDate.getTime() + 5 * 60000);
    const newEndDate = new Date(newStartDate.getTime() + 30 * 60000); // 30 minutes in milliseconds
    if (!item?.start_date_milli) item.start_date_milli = moment(newStartDate).valueOf();
    if (!item?.end_date_milli) item.end_date_milli = moment(newEndDate).valueOf();
    return item;
  });

  useEffect(() => {
    if (textareaRef.current) {
      window.requestAnimationFrame(() => {
        textareaRef.current?.scrollIntoView({ behavior: 'smooth', block: 'center' });
        textareaRef.current.focus();
        const length = textareaRef.current.value?.length;
        textareaRef.current?.setSelectionRange(length, length);
      });
    }
  }, [isTyping]);

  //initialize value for subtask add
  const handleSubtaskAdd = () => {
    let currentDate = new Date();
    const newStartDate = new Date(currentDate.getTime() + 5 * 60000);
    const newEndDate = new Date(newStartDate.getTime() + 30 * 60000); // 30 minutes in milliseconds
    setInput('');
    setStartDate(newStartDate);
    setEndDate(newEndDate);
    setEditIndex(null); // initial index taken as null
    setIsTyping(true);
  };

  // submit action
  const handleSubmit = () => {
    if (input.trim() === '') {
      onDelete(editIndex);
      return;
    }
    // if (isNaN(startDate)) {
    //   makeAlert('Invalid date. Please enter a valid date.');
    //   return; // Exit the function if the date is invalid
    // }
    // Get the current date and time
    const currentDate = new Date();
    // Validation checks
    if (!startDate) {
      makeAlert('Start Date is required.', 'error');
      return;
    }
    if (!endDate) {
      makeAlert('End Date is required.', 'error');
      return;
    }
    if (editIndex === null && startDate <= currentDate) {
      makeAlert('Start date and time must be greater than the current date and time.', 'error');
      return;
    }
    if (endDate < startDate) {
      makeAlert('End date and time must be greater than the start date and time.', 'error');
      return;
    }

    onAddOrUpdate(input, editIndex, 1, startDate, endDate); // added end date datetime
    setEditIndex(null);
    setInput('');
  };

  // subtask name handler
  const handleChange = (event) => {
    const { value } = event.target;
    setInput(value);
  };

  // handler for keydown to submit if enter key pressed
  const handleKeyDown = (event) => {
    if (isKeyDown) {
      setIsKeyDown(false);
      return;
    }
    if (event.key === 'Enter') {
      setIsKeyDown(true);
      handleSubmit();
      setIsSubTaskVisible(true);
    }
  };

  // handler for initialize edit state
  const handleEdit = (item, idx) => {
    setContextMenuIndex(null);
    setIsTyping(false);
    setInput(item?.content);
    setStartDate(item?.start_date_milli ? new Date(parseInt(item?.start_date_milli)) : new Date());
    setEndDate(item?.end_date_milli ? new Date(parseInt(item?.end_date_milli)) : new Date());
    setEditIndex(idx);
  };

  // deletion handler - passed subtask id
  const handleDelete = () => {
    onDelete(isDelete.subtaskId);
    setIsDelete({ subtaskId: null, modal: false });
  };

  // subtask add permission check

  const isAddOrEditable =
    from == 'add' // for task add
      ? true
      : from == 'list' // for task single page
      ? list?.length == 0
        ? true
        : list?.[0]?.isEditable
      : false;
  console.log({ isAddOrEditable, from, isEditable: list?.[0]?.isEditable });

  const isHeadVisible = (list?.length > 0 || isTyping) && headVisible; // required for display heading

  return (
    <div className={isHeadVisible ? `sub-section ` : `my-3`}>
      {isHeadVisible && isHeading && (
        <div className='fw-500 d-flex justify-content-between my-3'>
          <span className='font-14'>{title} </span>
          <ChevronUp
            size={16}
            className={`${isSubTaskVisible ? 'rotate-down' : 'rotate-up'}`}
            onClick={() => setIsSubTaskVisible((prev) => !prev)}
          />
        </div>
      )}
      {isSubTaskVisible && (
        <div
          style={{ maxHeight: from == 'list' ? 'fit-content' : 'revert-layer' }}
          className='subtask-list'
        >
          {list?.map((item, idx) => {
            if (editIndex == idx) {
              return (
                <SubTaskAddForm
                  setStartDate={setStartDate}
                  setEndDate={setEndDate}
                  startDate={startDate}
                  endDate={endDate}
                  key={item.id ?? idx}
                  input={input}
                  textMinWidth={textMinWidth}
                  setIsTyping={() => setEditIndex(null)}
                  handleChange={handleChange}
                  handleKeyDown={handleKeyDown}
                  handleSubmit={handleSubmit}
                  textareaRef={textareaRef}
                />
              );
            }
            return (
              <SingleSubtask
                key={item.id ?? idx}
                item={item}
                SingleClassName={SingleClassName}
                isContentVisible={contextMenuIndex === idx}
                setIsContextVisible={(value) => {
                  value ? setContextMenuIndex(idx) : setContextMenuIndex(null);
                }}
                position={position}
                handleContextClick={(type) => {
                  if (type === CONTEXT_MENU_OPTIONS.EDIT) {
                    handleEdit(item, idx);
                    return;
                  }
                  if (type === CONTEXT_MENU_OPTIONS.DELETE) {
                    setIsDelete({ modal: true, subtaskId: idx });
                    setContextMenuIndex(null);
                    return;
                  }
                }}
                from={from}
                isChangeStatus={isChangeStatus}
                changeStatus={(status) =>
                  onAddOrUpdate(
                    item.content,
                    idx,
                    status,
                    item?.start_date_milli,
                    item.end_date_milli,
                  )
                }
                isAddOrEditable={isAddOrEditable}
              />
            );
          })}
        </div>
      )}
      {isTyping ? (
        <SubTaskAddForm
          setStartDate={setStartDate}
          setEndDate={setEndDate}
          startDate={startDate}
          endDate={endDate}
          input={input}
          textMinWidth={textMinWidth}
          setIsTyping={setIsTyping}
          handleChange={handleChange}
          handleKeyDown={handleKeyDown}
          handleSubmit={handleSubmit}
        />
      ) : list?.length > 0 ? (
        isAddOrEditable && (
          <div
            role='button'
            onClick={handleSubtaskAdd}
            // className={``}
            style={{
              position: from != 'list' ? 'absolute' : 'relative',
              bottom: '2vh',
            }}
          >
            <ToolTip tooltipText={'Subtask Add'} isModern popOver={from == 'list'}>
              <Plus strokeWidth={1.5} color='#879096' size={18} />
            </ToolTip>
            <div className='grey-txt'></div>
          </div>
        )
      ) : (
        <CustomEmptyContainer
          Icon={() => {
            return <SubTaskIcon />;
          }}
          text={TASK_LIST_EMPTY_MESSAGE.subtask_empty}
          handleAdd={handleSubtaskAdd}
          disableNonEmptyView={disableNonEmptyView}
          disableAdd={disableAdd}
        />
      )}

      {isDelete.modal && (
        <Modal.Container>
          <Modal.View className={'status-delete'}>
            <Modal.Head
              handleClose={() => setIsDelete({ modal: false, subtaskId: null })}
              Heading={'Delete Confirmation'}
            />
            <Modal.Description>
              <img src={Images.DeleteBin} className='my-2' width={35} height={50} alt='' />
              <div className='del-description'>{DELETE_NOTE.SUBTASK}</div>
            </Modal.Description>
            <div className='status-del-btn'>
              <Button.Container
                // isDisabled={isDeleting}
                handleOk={() => setIsDelete({ modal: false, subtaskId: null })}
                className={'status-del-cancel'}
              >
                <Button.Title title={'Cancel'} />
              </Button.Container>
              <Button.Container
                // isDisabled={isDeleting}
                handleOk={handleDelete}
              >
                <Button.Title title={'Delete'} />
                <Button.Loading
                  // isLoading={isDeleting}
                  color='#FFF'
                />
              </Button.Container>
            </div>
          </Modal.View>
        </Modal.Container>
      )}
    </div>
  );
}

// Single subtask component
const SingleSubtask = ({
  item,
  setIsContextVisible,
  isContentVisible,
  handleContextClick,
  SingleClassName = 'justify-content-between',
  position = POSITION.LEFT,
  from,
  changeStatus,
  isChangeStatus = false,
  disableAdd = false,
  isAddOrEditable = false,
}) => {
  console.log({ SingleSubtask: isAddOrEditable });

  const parsedDate = moment(parseInt(item?.start_date_milli));
  const parsedEndDate = moment(parseInt(item?.end_date_milli));

  // Format the date as "MMM DD hh A"
  const formattedDate = parsedDate?.format('MMM DD - hh:mm A');
  const formattedEndDate = parsedEndDate?.format('hh:mm A');

  return (
    <div
      key={item.id}
      className={`d-flex  gap-1 subtask-view 
        border-bottom
      text-break ${SingleClassName}`}
    >
      <div className='flex-1 font-13 sub-task-name'>{item.content}</div>
      {item?.start_date_milli && (
        <div>
          <div className='sub-task-date'>
            {formattedDate} to {formattedEndDate}
          </div>
        </div>
      )}
      <div className='position-relative d-flex gap-3'>
        {from == 'list' && (
          <ReactSelect
            options={SubtaskStatusArray}
            value={SubtaskStatusArray.find((status) => status.value === parseInt(item?.status))}
            onChange={(e) => {
              changeStatus(e.value);
            }}
            isDisabled={!isChangeStatus}
            styles={{
              ...CustomStatusSelectBoxStyles,
              control: (provided, state) => ({
                ...CustomStatusSelectBoxStyles.control(provided, state),
                height: '27px',
                border: '1px solid #a5a5a5',
              }),
              indicatorsContainer: (style) => ({
                ...style,

                display: isChangeStatus ? 'flex' : 'none',
                padding: '0px !important',
              }),
            }}
            formatOptionLabel={(data, MetaData) => {
              return <StatusOption data={data} metaData={MetaData} />;
            }}
            menuPortalTarget={document.getElementById('MODAL')}
            classNames={{ menuPortal: () => 'z-index-100 fz-14px react-select-portal' }}
          />
        )}
        {isAddOrEditable && (
          <div className='d-flex gap-2 subtask-view-actions invisible'>
            <div className='subtask-icon-bg'>
              <Pen size={13} onClick={() => handleContextClick(CONTEXT_MENU_OPTIONS.EDIT)} />
            </div>
            <div className='subtask-icon-bg'>
              <Trash
                size={13}
                onClick={() => {
                  handleContextClick(CONTEXT_MENU_OPTIONS.DELETE);
                }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export const SubTaskAddForm = ({
  startDate,
  endDate,
  input,
  textMinWidth,
  setStartDate = () => {},
  setEndDate = () => {},
  handleChange = () => {},
  handleKeyDown = () => {},
  setIsTyping = () => {},
  handleSubmit = () => {},
  isSingleAdd = false,
  isSubmittedFromCalender = false,
  textareaRef,
}) => {
  const [isSubmit, setIsSubmit] = useState(false); // for display the error field in reminder
  const handleAdd = () => {
    setIsSubmit(true);
    if (!input) return;
    setIsSubmit(false);

    handleSubmit();
  };
  const handleEnter = (e) => {
    if (e.key === 'Backspace') {
      return;
    }
    setIsSubmit(true);
    if (!input) return;
    setIsSubmit(false);
    handleKeyDown(e);
  };

  //new date and time range change handler
  const onDateAndTimeRangeChange = (data) => {
    const { name, value } = data.target;
    if (name === 'date') {
      // Create a new date object from the new date string
      const newDate = new Date(value);
      // Preserve the time part of the current endDate
      const updatedEndDate = new Date(
        newDate.setHours(
          endDate.getHours(),
          endDate.getMinutes(),
          endDate.getSeconds(),
          endDate.getMilliseconds(),
        ),
      );
      setEndDate(updatedEndDate);

      // Get the existing time values from the current startDate
      const currentHours = startDate.getHours();
      const currentMinutes = startDate.getMinutes();
      const currentSeconds = startDate.getSeconds();
      const currentMilliseconds = startDate.getMilliseconds();

      // Update the new date with the existing time values
      newDate.setHours(currentHours);
      newDate.setMinutes(currentMinutes);
      newDate.setSeconds(currentSeconds);
      newDate.setMilliseconds(currentMilliseconds);

      // Set the updated start date
      setStartDate(newDate);
    } else if (name === 'startTime') {
      // Handle time change
      const [newHours, newMinutes] = value.split(':').map(Number); // Assuming value is in "HH:mm" format

      // Create a new date object based on the existing startDate
      const updatedStartDate = new Date(startDate);

      // Set the new hours and minutes
      updatedStartDate.setHours(newHours);
      updatedStartDate.setMinutes(newMinutes);

      // Update the startDate with the new time
      setStartDate(updatedStartDate);
    } else if (name === 'endTime') {
      // Handle time change for endDate
      const [newHours, newMinutes] = value.split(':').map(Number); // Assuming value is in "HH:mm" format

      const updatedEndDate = new Date(endDate);
      updatedEndDate.setHours(newHours);
      updatedEndDate.setMinutes(newMinutes);

      setEndDate(updatedEndDate);
    }
  };

  console.log({ SubtaskListWithAdd: startDate, endDate });

  return (
    <div className='reminder-container mb-2' style={{ padding: '2px 8px' }}>
      <div className='subtask-content'>
        <div className='flex-1 form-group'>
          <input
            type='text'
            className={`reminder-input-box w-100  ${
              (isSubmit || isSubmittedFromCalender) && !input && 'error-border-bottom '
            }`}
            style={{ minWidth: textMinWidth ? textMinWidth : '' }}
            id='title'
            name='title'
            onChange={(e) => {
              setIsSubmit(false);
              handleChange(e);
            }}
            onKeyDown={handleEnter}
            value={input}
            placeholder='Enter sub task'
            autoFocus
            ref={textareaRef}
          />
        </div>
        <div className=' mx-1 d-flex gap-2 justify-content-end align-items-center w-fit-content'>
          <div className='d-flex'>
            <Popover.PopoverWrapper>
              <DateAndTimeRangePicker
                subTaskDate={moment(startDate).format('DD-MM-YYYY')}
                start={moment(startDate).format('HH:mm')}
                end={moment(endDate).format('HH:mm')}
                onDateAndTimeRangeChange={onDateAndTimeRangeChange}
              />
              {/* <DatePicker
                selected={startDate ?? new Date()}
                onChange={(date) => setStartDate(date)}
                timeInputLabel='Time:'
                dateFormat='MMM dd  hh:mm a'
                showTimeInput
                className='date-field date-field-date-picker date-item px-2'
                minDate={new Date()}
              /> */}
            </Popover.PopoverWrapper>
          </div>
          <div hidden={isSingleAdd}>
            <SaveAndCancel
              onCancel={() => setIsTyping(false)}
              isSubmitting={false}
              handleSubmit={handleAdd}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
const SaveAndCancel = ({ onCancel, isSubmitting, handleSubmit }) => {
  return (
    <div className='status-btn-wrapper'>
      <Button.Container
        type='button'
        handleOk={onCancel}
        className={'inline-related-task-cancel-button'}
      >
        <Button.Title title={<Close />} />
      </Button.Container>
      <Button.Container
        isDisabled={isSubmitting}
        className={'blue-button-inline-related-task'}
        type={'submit'}
        handleOk={handleSubmit}
      >
        <Button.Title title={<Tick height={10} width={13} />} />
        <Button.Loading isLoading={isSubmitting} size={20} />
      </Button.Container>
    </div>
  );
};

const CustomData = ({ data }) => {
  // Parse the datetime string using Moment.js
  const parsedDate = moment(data);

  // Format the date as "MMM DD hh A"
  const formattedDate = parsedDate.format('MMM DD : hh:mm A');
  return <div className='date_txt'>{formattedDate}</div>;
};

export default SubtaskListWithAdd;
