import { useQuery } from '@tanstack/react-query';
import { Plus, X } from 'lucide-react';
import React, { useContext, useState } from 'react';
import { CustomFileUpload } from '../_components';
import { Button } from '../_components/CustomButton';
import ToolTip from '../_components/Tooltip/ToolTip';
import { alertConstants, apiConstants, MENU, USER_LIST_ACTIONS } from '../_constants';
import { sharedUserSelect } from '../_constants/filesystem.constant';
import { clientService, userService } from '../_services';
import { teamService } from '../_services/team.service';
import { SHARED_WITH_ALL_OPTION } from '../File/FileSystemConstant';
import { SharedWith } from '../FileSystem/SharedWith';
import { ClientState, GlobalContext, Images } from '../Utils';

export function ClientAdd({
  id,
  handleClose = () => {},
  afterClientAdd = () => {},
  shareWithListPlacement = 'top',
}) {
  const { getMenuLabelName } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);
  const [client, setClient] = useState(ClientState);
  const [isLoading, setIsLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [compressed, setCompressed] = useState(null);

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState({ common: '' });
  const [query, setQuery] = useState({
    select: [
      'id',
      'name',
      'phone',
      'email',
      'company_name',
      'description',
      'pin_code',
      'address',
      'logo',
      'shared_with_user',
      'shared_with_team',
    ],
  });

  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });

  const handleScroll = (event) => {
    // eslint-disable-next-line no-unused-vars
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  // get client details for edit if id >0
  const getClient = async () => {
    setQuery({ ...query, id: id });
    const res = await clientService.clientGet({ ...query, id: id });
    if (res?.data?.length) {
      const result = res?.data[0];
      let sharedTeam =
        result.shared_with_team.length > 0 && result.shared_with_team[0] !== 0
          ? result.sharedTeams
          : result.shared_with_team.length > 0
          ? [SHARED_WITH_ALL_OPTION.TEAM]
          : [];
      let sharedUser =
        result.shared_with_user.length > 0 && result.shared_with_user[0] !== 0
          ? result.sharedUsers
          : result.shared_with_user.length > 0
          ? [SHARED_WITH_ALL_OPTION.USER]
          : [];

      setClient({ ...result, shared_with_team: sharedTeam, shared_with_user: sharedUser });
      return res?.data[0];
    }
  };

  // eslint-disable-next-line no-unused-vars
  const { data: clients } = useQuery({
    queryKey: ['client-data', id],
    queryFn: () => getClient(),
    enabled: Boolean(id),
  });

  // handler for client add or edit
  const handleChange = (event) => {
    const { name, value, type } = event.target;
    if (type === 'shared_with') {
      const lastItem = value.length > 0 ? value[value.length - 1] : [];
      const isSelectedAll = lastItem && lastItem.id === 0;

      let filteredValue;
      if (isSelectedAll) {
        // If 'All' is selected last, show only 'All'
        filteredValue = [lastItem];
      } else {
        // Filter out 'All' if other items are selected
        filteredValue = value.filter((item) => item.id !== 0);
      }

      setClient((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));

      return;
    }
    setClient({
      ...client,
      [name]: value,
    });
  };

  // handler for client form submit
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setSubmitted(true);
    if (client?.name.toString().trim() === '') {
      setIsLoading(false);
      return null;
    }
    const shareWithUserIds = client?.shared_with_user?.map((item) => item.id);
    const shareWithTeamIds = client?.shared_with_team?.map((item) => item.id);

    const payload = {
      ...client,
      shared_with_team: shareWithTeamIds,
      shared_with_user: shareWithUserIds,
    };

    try {
      await clientService.clientAdd(payload).then((data) => {
        setSubmitted(false);
        afterClientAdd(data?.data);
      });
      handleClose();
    } catch (error) {
      setError({
        common: error?.message || alertConstants.SERVER_ERROR,
      });
    } finally {
      setIsLoading(false);
      setSubmitted(false);
    }
  };

  // handleChange image upload
  const handleImageUpload = (file, name, compressed, compressedBlob) => {
    setClient((prev) => ({
      ...prev,
      [name]: file.pop(),
      compressed_logo: compressed[0],
    }));
    setCompressed(compressedBlob);
  };

  // team list
  const { data: teamData } = useQuery({
    queryKey: ['shared-team-list'],
    queryFn: () =>
      teamService.teamList({
        select: ['id', 'name'],
      }),
    select: (data) => data.data.rows,
  });

  const { data: usersForShare } = useQuery({
    queryKey: ['user-list-file'],
    queryFn: () =>
      userService.getPermittedUser({
        select: sharedUserSelect,
        action: USER_LIST_ACTIONS.FILE_SYSTEM,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  let imageUrl = compressed
    ? compressed
    : client?.compressed_logo
    ? apiConstants.imgUrlS3 + client.compressed_logo
    : client?.logo
    ? apiConstants.imgUrlS3 + client?.logo
    : Images.ImagePreview;

  return (
    <div className='position-relative h-100'>
      <div style={{ position: 'absolute', top: 7, right: 15, cursor: 'pointer' }}>
        <ToolTip tooltipText={'Close'} isModern>
          <X size={15} onClick={() => handleClose()} />
        </ToolTip>
      </div>
      <div
        className=' '
        style={{
          boxShadow: scrollFlag.isTopReached ? '' : '0 6px 5px -5px #091e4229',
          padding: 16,
        }}
      >
        <h6 className='client-form-head'>
          {id ? 'Edit' : 'Create'} {LABEL_OF_CLIENT}
        </h6>
      </div>
      <form className='d-flex flex-column gap-2' onSubmit={handleSubmit}>
        <div onScroll={handleScroll} className='client-form-section'>
          <div className='position-relative mb-3 mt-2' style={{ width: 'fit-content' }}>
            <img src={imageUrl} alt='project-logo' className='project-image-outer' />
            <CustomFileUpload
              name='logo'
              accept='image'
              isMulti={false}
              isCompressed={true}
              handleOK={handleImageUpload}
              CustomView={
                <div className='logo-add-plus-btn-bg cursor-pointer'>
                  <Plus color='white' size={17} />
                </div>
              }
            />
          </div>
          <div className=' mb-16'>
            <label className='mb-1 fz-13px-rem '>
              Name <span className='field-required'>*</span>
            </label>
            <input
              type='text'
              className='input-box'
              id='name'
              autoFocus
              name='name'
              value={client?.name}
              placeholder={`Enter ${LABEL_OF_CLIENT} Name `}
              onChange={(event) => {
                handleChange(event);
              }}
            />
            {submitted && !client?.name.trim() && (
              <div className='help-block'>{LABEL_OF_CLIENT} Name is required</div>
            )}
          </div>
          <div className=' mb-16'>
            <div className='form-group'>
              <label className='mb-1 fz-13px-rem '>{LABEL_OF_CLIENT} Address</label>
              <textarea
                className='form-control box-focus fz-13px'
                name='address'
                value={client?.address}
                placeholder='Enter Address '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
          <div className=' mb-16'>
            <label className='mb-1 fz-13px-rem '>PIN code</label>
            <input
              type='number'
              className='input-box'
              name='pin_code'
              value={client?.pin_code}
              placeholder='Enter PIN code'
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
          <div className=' mb-16'>
            <label className='mb-1 fz-13px-rem '>Company Name</label>
            <input
              type='text'
              className='input-box'
              id='company_name'
              name='company_name'
              value={client?.company_name}
              placeholder='Enter Company Name '
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>
          <div className=' mb-16'>
            <div className=' form-group'>
              <label className='mb-1 fz-13px-rem '>Company Description</label>
              <textarea
                className='form-control box-focus fz-13px'
                id='description'
                name='description'
                value={client?.description}
                placeholder='Enter Description '
                onChange={(event) => {
                  handleChange(event);
                }}
              />
            </div>
          </div>
          <div className=' mb-16'>
            <label className='mb-1 fz-13px-rem '>Email</label>
            <input
              type='text'
              className='input-box'
              id='email'
              name='email'
              value={client?.email}
              placeholder='Enter Email'
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>

          <div className=' mb-16'>
            <label className='mb-1 fz-13px-rem '>Phone</label>
            <input
              type='text'
              className='input-box'
              id='phone'
              name='phone'
              value={client?.phone}
              placeholder='Enter Phone'
              onChange={(event) => {
                handleChange(event);
              }}
            />
          </div>

          <div className=' mb-16'>
            <SharedWith
              menuPlacement={shareWithListPlacement}
              onAdd={(userOrTeam, type) => {
                const name = type === 'user' ? 'shared_with_user' : 'shared_with_team';
                handleChange({
                  target: {
                    value: [...(client[name] ?? []), ...userOrTeam],
                    name: name,
                    type: 'shared_with',
                  },
                });
              }}
              onAvatarRemove={(userOrTeam, name) => {
                setClient((prev) => ({
                  ...prev,
                  [name]: prev[name].filter(
                    (item) => parseInt(item.id) !== parseInt(userOrTeam.id),
                  ),
                }));
              }}
              userImageList={client?.shared_with_user ? client?.shared_with_user : []}
              sharedUserSelect={sharedUserSelect}
              usersForShare={usersForShare}
              teamForShare={teamData}
              teamImageList={client?.shared_with_team ? client?.shared_with_team : []}
            />
          </div>
        </div>

        <div
          style={{
            boxShadow: scrollFlag.isBottomReached ? '' : '0 -6px 5px -5px var(--shadow, #091e4229)',
          }}
          className='client-add-button-container'
        >
          {!id && (
            <Button.Container
              className={'outline-cancel-button'}
              type='reset'
              handleOk={() => {
                setClient(ClientState);
                setCompressed(null);
                setSubmitted(false);
              }}
            >
              <Button.Title title={'Cancel'} />
            </Button.Container>
          )}
          <Button.Container type='submit' className={'blue-btn'} isDisabled={isLoading}>
            <Button.Title title={'Save'} />
            <Button.Loading isLoading={isLoading} />
          </Button.Container>
        </div>
      </form>
    </div>
  );
}
