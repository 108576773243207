/* eslint-disable no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import draftToHtml from 'draftjs-to-html';
import { CircleAlert, FilePenLine, Plus } from 'lucide-react';
import React, { useContext, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import TaskEditSettings from '../Settings/Task/TaskEditSettings';
import { taskAssignUserSelect } from '../Task/taskConstants';
import {
  GlobalContext,
  convertHtmlToRawContent,
  dayHourToFloat,
  floatToDayHour,
  floatToTime,
  generateNextId,
  generateStartModeOptions,
  getSettingsByName,
  hasCircularDependencies,
  taskState,
  templateState,
  timeToFloat,
} from '../Utils';
import { LoadingIcon } from '../Utils/SvgIcons';
import { Button, ToggleButton } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import { RT, SETTINGS, USER_LIST_ACTIONS } from '../_constants';
import {
  ALLOW_ASSIGN_TO_MAIN,
  ERROR_BLOCKS,
  START_MODES,
  TEMPLATE_ERROR_MSGS,
  TEMPLATE_VISIBLE_ALL_OPTION,
} from '../_constants/template.constants';
import { errorToString } from '../_helpers/utilFunctions';
import { userService } from '../_services';
import { labelService } from '../_services/label.service';
import { statusService } from '../_services/status.service';
import { teamService } from '../_services/team.service';
import { templateService } from '../_services/template.service';
import RelatedTaskPreviewSection from './SubComponents/RelatedTaskPreviewSection';
import RelatedTaskTemplateAddModal from './SubComponents/RelatedTaskTemplateAddModal';
import RelatedTaskVisibilitySelector from './SubComponents/RelatedTaskVisibilitySelector';
import TemplateFormSection from './SubComponents/TemplateFormSection';
import { TemplateTaskPreviewItem } from './SubComponents/TemplateTaskPreviewItem';
import ValidationOptions from './SubComponents/ValidationOptions';
import './template.css';

/***
 * For creating the template(route - /template-add/:id) - Contain multiple components in it.
 * TemplateFormSection - Contains the forms UI
 * RelatedTaskPreviewSection - Wrapper for the related task preview. Previews are the children on this component.
 * @version 0.1
 * @author SMAK
 */
export function TemplateAdd() {
  const { makeAlert, globalSettingsRefetch, permissionsMap } = useContext(GlobalContext);
  const containerRef = useRef();
  const navigate = useNavigate();
  const { id } = useParams();

  const userGroupQuery = {
    select: ['id', 'name', 'company_id', 'members', 'members_details'],
    searchKey: '',
    status: 1,
    pageVo: {
      pageNo: 1,
      noOfItems: 10,
    },
  };

  // --- States --- //
  const [loadingItemName, setLoadingItemName] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [taskModalVisible, setTaskModalVisible] = useState(false);
  const [editTaskIndex, setEditTaskIndex] = useState(null); //used for tracing the currently editing task's index.
  const [scrollFlag, setScrollFlag] = useState({ isTopReached: true, isBottomReached: false });
  const [isValidationBoxOpen, setIsValidationBoxOpen] = useState(false);
  const [isPermissionBoxOpen, setIsPermissionBoxOpen] = useState(false);
  const [expandAll, setExpandAll] = useState(false);

  const [searchParams] = useSearchParams();
  const { globalSettings = [] } = useContext(GlobalContext);
  const generalSettings = getSettingsByName(globalSettings, SETTINGS.GENERAL.label);
  // Check whether subtask is deactivated in settings or not.
  const subtaskSettings = generalSettings?.labels?.find((item) => item.id === SETTINGS.GENERAL.id);
  const isSubtaskActive = subtaskSettings?.value ?? false;

  const [templateDetails, setTemplateDetails] = useState({ ...templateState });
  // (TODO: Need to implement search)
  // eslint-disable-next-line no-unused-vars
  const [searchKeys, setSearchKeys] = useState({ user: '', label: '', team: '' });

  // --- useQuery Calls --- //
  /***
   * This function will be called on reading the template data on edit. This will not trigger if it's not an edit.
   * Here we will convert all the ids and data to the required format after reading from the api.
   */
  const getSingleTemplateData = async () => {
    const templateData = await templateService.getSingleTemplate({
      expanded_list: true,
      select: [
        'id',
        'name',
        'description',
        'expected_hours',
        'template_visible_users',
        'template_visible_groups',
        'verifying_users',
        'task_visible_users',
        'task_visibility_type',
        'related_tasks',
        'validation_fields',
        'has_time',
        'start_time',
        'end_time',
        'subtasks',
        'labels',
        'share_updates',
        'status_group',
        'assign_to_main',
        'is_verify_user_changeable',
        'task_type',
        'include_in_menu',
        'is_unlisted',
        'menu_title',
        'is_private',
        'is_verification_required',
        'type',
        'task_edit_permissions',
      ],
      id,
    });

    const expectedTime = floatToTime(templateData.data[0].expected_hours);

    const template = templateData.data[0];

    const updateData = {
      ...template,
      template_visible_groups: template.template_visible_group_details ?? [],
      labels: template.label_details,
      task_visible_users: template.task_visible_users_details,
      verifying_users: template.verify_users_details,
      status_group: { ...template.StatusGroup, items: [...(template.StatusGroupItem || [])] } ?? [],
      //if it's 'All' option, it will not have any details.
      template_visible_users:
        template.template_visible_users?.length > 0 &&
        parseInt(template.template_visible_users[0]) !== 0
          ? template.template_visible_users_details
          : template.template_visible_users.length > 0
          ? [TEMPLATE_VISIBLE_ALL_OPTION]
          : [],
      // start_time: secondsToTimeString(parseFloat(template.start_time)),
      // end_time: secondsToTimeString(parseFloat(template.end_time)),
      expected_hours: expectedTime.hours,
      expected_minutes: expectedTime.minutes,

      related_tasks: templateData.data[0].related_tasks.map((tsk) => {
        const customStartTime = floatToDayHour(tsk.custom_start_time);
        return {
          ...tsk,
          custom_start_days: customStartTime.days,
          custom_start_hours: customStartTime.hours,
          note: convertHtmlToRawContent(tsk.note),
        };
      }),
    };

    setTemplateDetails((prev) => ({ ...prev, ...updateData }));
    return templateData;
  };

  // Fetch template data on edit based on id. On add, this query will not run.
  const {
    isLoading: isTemplateDataLoading,
    isError,
    error,
    refetch,
  } = useQuery({
    queryKey: ['template-single'],
    queryFn: () => getSingleTemplateData(),
    enabled: id && Boolean(parseInt(id)),
  });

  const isTemplateLoaded = parseInt(id) === 0 || Boolean(templateDetails?.id); //If edit we need template data to load, otherwise nothing to load

  // userList
  const { data: userList, isLoading: isUserLoading } = useQuery({
    queryFn: () =>
      userService.userList({
        select: ['id', 'first_name', 'last_name', 'gender', 'image_url', 'compressed_image_url'],
      }),
    queryKey: ['user-list'],
    select: (data) => data.data.rows,
    enabled: isTemplateLoaded,
  });

  // User group(team) list
  const { data: groupList, isLoading: isTeamLoading } = useQuery({
    queryKey: ['teamList', userGroupQuery.pageVo.pageNo, searchKeys.team],
    queryFn: () => teamService.teamList(userGroupQuery),
    select: (data) => data.data.rows,
    enabled: isTemplateLoaded,
  });

  // Labels list
  const { data: labelList, isLoading: isLabelLoading } = useQuery({
    queryKey: ['label', searchKeys.label],
    queryFn: () =>
      labelService.labelList({
        select: ['id', 'name', 'text_color', 'label_color', 'createdAt', 'status'],
      }),
    select: (data) => data.data.rows,
    enabled: isTemplateLoaded,
  });

  const { data: usersForVerification } = useQuery({
    queryKey: ['user-list-verify'],
    queryFn: () =>
      userService.getPermittedUser({
        select: taskAssignUserSelect,
        action: USER_LIST_ACTIONS.TASK_VERIFY,
      }),
    select: (data) => {
      return data?.data?.rows;
    },
  });

  // Status group list
  const getAllStatusGroupList = async () => {
    const statusGroupData = await statusService.statusGroupList({ status: 1 });
    if (!templateDetails.status_group) {
      setTemplateDetails((prev) => ({
        ...prev,
        status_group: statusGroupData.data.defaultStatus[0],
      }));
    }
    return statusGroupData;
  };

  const { data: statusGroup } = useQuery({
    queryKey: ['group-status-list'],
    queryFn: () => getAllStatusGroupList(),
    select: (data) =>
      data.data ? [...data.data.defaultStatus, ...data.data.nonDefaultStatus] : [],
    enabled: isTemplateLoaded,
  });

  // --- Functions and Methods --- //
  /***
   * Will scroll to the first-found error element. 'error-block' class is considered as the error element. It will scroll to the first found parent of error-block class.
   */
  const scrollToErrorElement = () => {
    window.requestAnimationFrame(() => {
      const element = document.querySelector('.error-block');
      if (!element) {
        return;
      }
      const parentElement = element.parentElement;
      const inputElement = parentElement.querySelector('input');
      if (inputElement) {
        inputElement.focus({ preventScroll: false });
      }
      parentElement.scrollIntoView({ behavior: 'smooth', block: 'start' });
    });
  };

  /***
   * Validate the template data
   * @param data - The template data to be validated
   * @return object {isValid, msg } */
  const validateData = (data) => {
    // Field validations
    if (!data.name) {
      const errorObj = {
        isValid: false,
        msg: TEMPLATE_ERROR_MSGS.TEMPLATE_NAME_REQUIRED,
        block: ERROR_BLOCKS.TEMPLATE_NAME,
      };
      return errorObj;
    }

    if (data.include_in_menu && !data.menu_title) {
      const errorObj = {
        isValid: false,
        msg: 'Please enter the menu name',
        block: ERROR_BLOCKS.START_TIME,
      };
      // makeAlert(errorObj.msg, 'error');
      return errorObj;
    }

    if (!data.status_group) {
      const errorObj = {
        isValid: false,
        msg: 'Please select the status group',
        block: ERROR_BLOCKS.STATUS_GROUP,
      };
      // makeAlert(errorObj.msg, 'error');
      return errorObj;
    }
    return { isValid: true, msg: '' };
  };
  /***
   * For processing teh template data before sending to the backend. Will convert some data to teh required format.
   * @param data- The template data needed to be added to the db
   */
  const processData = (data) => {
    const { expected_minutes, ...requiredData } = data;

    // convert all data to corresponding Ids.
    const template_visible_users = data.template_visible_users?.map((user) => parseInt(user.id));
    const template_visible_groups = data.template_visible_groups?.map((group) =>
      parseInt(group.id),
    );

    const verifying_users = data.verifying_users?.map((user) => parseInt(user.id));
    const task_visible_users = data.task_visible_users?.map((user) => parseInt(user.id));
    const labels = data.labels?.map((label) => parseInt(label.id));
    const status_group = parseInt(data.status_group?.id);
    const expectedHours = timeToFloat(data.expected_hours, expected_minutes);

    // Convert all the related task description to html
    // Shallow copy the related tasks
    const relatedTasks = [...data.related_tasks];
    // Convert all the draftJs description to html
    for (let i in relatedTasks) {
      const { custom_start_days, custom_start_hours, ...requiredRelatedTaskData } = relatedTasks[i];
      const customStartTime = dayHourToFloat(custom_start_days, custom_start_hours);
      requiredRelatedTaskData.custom_start_time = customStartTime;
      requiredRelatedTaskData.note = draftToHtml(requiredRelatedTaskData.note);
      relatedTasks[i] = requiredRelatedTaskData;
    }

    const finalPayload = {
      ...requiredData,
      template_visible_users,
      template_visible_groups,
      verifying_users,
      task_visible_users,
      labels,
      status_group,
      related_tasks: relatedTasks,
      start_time: 0,
      end_time: 0,
      expected_hours: expectedHours,
    };

    //start time and end time not need to pre-set on template
    // // Convert the time string to seconds
    // if (data.has_time) {
    //   finalPayload.start_time = data.start_time ? timeStringToSeconds(data.start_time) : 0;
    //   finalPayload.end_time = data.end_time ? timeStringToSeconds(data.end_time) : 0;
    // }

    return finalPayload;
  };

  /***
   * Function to handle the template add. This will add all the template data including the related tasks.
   */
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    // Process the data and adjust it to required format
    const processedData = processData(templateDetails);

    // Basic validations
    const result = validateData(processedData);

    // If validation is failed, it will scroll to the failed UI input
    if (!result.isValid) {
      scrollToErrorElement();
      return;
    }

    const payload = { ...processedData };

    try {
      setIsSubmitting(true);
      const response = await templateService.addTemplate(payload);
      if (response?.error_code === 0) {
        makeAlert(`Template successfully ${id && Boolean(parseInt(id)) ? 'updated' : 'created'}`);
        await globalSettingsRefetch();
        // Navigate to template list
        // navigate('/template-list');
        if (id) {
          navigate(`/${RT.TEMPLATE_LIST}?pageNo=${searchParams.get('pageNo') ?? 1}`);
        } else {
          navigate(`/${RT.TEMPLATE_LIST}`);
        }
      }
    } catch (err) {
      makeAlert(errorToString(err), 'error');
    } finally {
      setIsSubmitting(false);
    }
  };
  /***
   * For handling all the changes in the template form. This doesn't include the related task modal changes
   */
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;

    if (name === 'task_visibility_type') {
      setTemplateDetails((prev) => ({
        ...prev,
        [name]: parseInt(value),
      }));
      return;
    }

    if (name === 'is_unlisted') {
      setTemplateDetails((prev) => ({
        ...prev,
        [name]: checked,
        include_in_menu: checked ? checked : prev.include_in_menu,
      }));
      return;
    }

    if (name === 'template_visible_users') {
      const templateVisibleGroupLength = templateDetails.template_visible_groups?.length;
      // value is an array for template_visible_users
      const lastItem = value?.length > 0 ? value[value?.length - 1] : TEMPLATE_VISIBLE_ALL_OPTION;
      const isSelectedAll = lastItem.id === 0;
      // Only option 'All' or user can be selected at once;
      // So if 'All' is selected last, we will eliminate other users and show 'All', otherwise eliminate 'All' and show all selected users.
      const filteredValue = isSelectedAll
        ? templateVisibleGroupLength > 0
          ? []
          : [lastItem]
        : value.filter((item) => Boolean(item.id));

      setTemplateDetails((prev) => ({
        ...prev,
        [name]: filteredValue,
      }));
      return;
    }

    if (name === 'template_visible_groups') {
      const hasItem = value?.length > 0;
      const templateVisibleUserLength = templateDetails.template_visible_users?.length;

      const isVisibleUserHasAllOption =
        templateDetails.template_visible_users?.length > 0 &&
        templateDetails.template_visible_users[0].id === 0;

      setTemplateDetails((prev) => ({
        ...prev,
        [name]: value,
        template_visible_users:
          isVisibleUserHasAllOption && hasItem
            ? []
            : templateVisibleUserLength <= 0 && !hasItem
            ? [TEMPLATE_VISIBLE_ALL_OPTION]
            : prev.template_visible_users,
      }));

      return;
    }

    if (name === 'assign_to_main') {
      setTemplateDetails((prev) => ({
        ...prev,
        [name]:
          parseInt(value) === ALLOW_ASSIGN_TO_MAIN.ALLOW
            ? ALLOW_ASSIGN_TO_MAIN.DENY
            : ALLOW_ASSIGN_TO_MAIN.ALLOW,
      }));
      return;
    }

    if (type === 'radio') {
      setTemplateDetails((prev) => ({
        ...prev,
        [name]: value === 'true',
      }));
      return;
    }

    if (type === 'checkbox') {
      setTemplateDetails((prev) => ({
        ...prev,
        [name]: checked,
      }));
      return;
    }

    setTemplateDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  /***
   * For handling related task add
   * @param relatedTaskData - The related task details
   */
  const handleRelatedTaskAdd = (relatedTaskData) => {
    //Means edit
    if (editTaskIndex != null && editTaskIndex <= templateDetails.related_tasks?.length) {
      setTemplateDetails((prev) => ({
        ...prev,
        related_tasks: prev.related_tasks.map((itm, index) => {
          if (index === editTaskIndex) {
            return relatedTaskData;
          }
          return itm;
        }),
      }));
      setEditTaskIndex(null);
      return;
    }

    // Else add

    // get a tempId for related task(which will be need for adding task connections)
    const idList = templateDetails.related_tasks.map((task) => task.tempId);
    const id = generateNextId(idList);
    const updatedRelatedTask = { ...relatedTaskData, tempId: id };
    // Add related task to the state
    setTemplateDetails((prev) => ({
      ...prev,
      related_tasks: [...prev.related_tasks, updatedRelatedTask],
    }));
  };
  /***
   * For checking circular dependency.
   * @param currentRelatedTaskData - The current related task the user is editing through the modal
   * @returns boolean - Will return true if it has circular dependency
   */
  const handleCircularDependencyCheck = (currentRelatedTaskData) => {
    const precedingRelatedTasks = [...templateDetails.related_tasks, currentRelatedTaskData].filter(
      (task) => task.task_start_mode === START_MODES.PRECEDING_OTHER_TASK,
    );
    const isCircular = hasCircularDependencies(precedingRelatedTasks);

    return isCircular;
  };
  /***
   * Will generate a initialRelatedTaskData for the related task modal.
   * Will set a temporary id and assignee id(if exist, taken from local storage)
   * @returns A initial task object
   */
  const generateInitialRelatedTaskData = () => {
    const initTask = { ...taskState };
    // const loggedUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : {};
    // const loggedUserDetails = userList?.find((user) => user.id === loggedUser.id) ?? null;
    const idList = templateDetails.related_tasks.map((task) => task.tempId);
    const id = generateNextId(idList);
    initTask.tempId = id;
    // Only set current user assign user if template is for task
    // if (templateDetails.type === TEMPLATE_TYPE.NORMAL) {
    //   initTask.assignee_id = loggedUserDetails ? loggedUserDetails : taskState.assignee_id;
    // }
    return initTask;
  };
  /***
   * For deleting the related task
   * @param index - The index of the task to be deleted
   */
  const handleRelatedTaskDelete = (index) => {
    // Get the task to be deleted
    const taskToBeDeleted = templateDetails.related_tasks[index];
    // If task depends on other task, user can't delete it.
    const isTaskDependsOn = templateDetails.related_tasks.some(
      (task) => task.preceding_task_id === taskToBeDeleted.tempId,
    );

    if (isTaskDependsOn) {
      makeAlert(TEMPLATE_ERROR_MSGS.TASK_DEPENDS_ON_OTHER_TASK);
      return;
    }

    // Otherwise delete the task
    setTemplateDetails((prev) => ({
      ...prev,
      related_tasks: prev.related_tasks.filter((task) => task.tempId !== taskToBeDeleted.tempId),
    }));
  };

  const handleScroll = (event) => {
    const { scrollHeight, scrollTop, clientHeight } = event.target;

    //

    if (scrollTop === 0) {
      setScrollFlag({ isTopReached: true });
    } else if (scrollHeight - scrollTop <= clientHeight + 5) {
      setScrollFlag({ isBottomReached: true });
    } else {
      setScrollFlag({ isBottomReached: false, isTopReached: false });
    }
  };

  /**handleEditTaskChange - to handle the task settings values */
  const handleEditTaskChange = async (name, value, type = '') => {
    let updatedTaskEditSettings;
    if (type != '') {
      updatedTaskEditSettings = {
        ...templateDetails?.task_edit_permissions,
        [name]: {
          ...templateDetails?.task_edit_permissions?.[name],
          selectedFields: value,
        },
      };
    } else {
      updatedTaskEditSettings = {
        ...templateDetails?.task_edit_permissions,
        [name]: {
          ...templateDetails?.task_edit_permissions?.[name],
          fields: value,
          selectedFields:
            value === 2 || value === 1
              ? []
              : templateDetails?.task_edit_permissions?.[name]?.selectedFields,
        },
      };
    }

    /** setting the data to add database */
    setTemplateDetails({
      ...templateDetails,
      task_edit_permissions: updatedTaskEditSettings,
    });
  };

  const modalRef = useRef(null);

  /**
   * onDuplicateTask - duplicate template with generate new tempId
   * @param {Integer} index - index of task to be duplicate
   */
  const onDuplicateTask = (index) => {
    const taskToBeDuplicate = templateDetails.related_tasks[index];
    const newTempId = generateNextId(templateDetails.related_tasks.map((task) => task.tempId));
    const duplicatedTask = { ...taskToBeDuplicate, tempId: newTempId };
    const newRelatedTasks = [...templateDetails.related_tasks, duplicatedTask];
    setTemplateDetails({ ...templateDetails, related_tasks: newRelatedTasks });
  };

  if (isError) {
    return (
      <div className='template-add-container page-height d-flex flex-column justify-content-center align-items-center'>
        <div>{error.message}</div>

        <a
          className='pointer'
          onClick={() => {
            refetch();
          }}
        >
          Retry
        </a>
      </div>
    );
  }

  if (isTemplateDataLoading || isLabelLoading || isUserLoading || isTeamLoading) {
    return (
      <div className='template-add-container page-height d-flex justify-content-center align-items-center'>
        <div>
          <LoadingIcon size={60} />
        </div>
      </div>
    );
  }
  const isEdit = id && Boolean(parseInt(id));
  return (
    <div
      onClick={() => {
        setIsPermissionBoxOpen(false);
        setIsValidationBoxOpen(false);
      }}
    >
      <form onSubmit={handleSubmit}>
        <div
          className='align-items-center template-container-horizontal-padding d-flex justify-content-between'
          style={{
            paddingBottom: 21,
            boxShadow: scrollFlag.isTopReached ? 'none' : '0 2px 7px -1px var(--shadow, #091e4229)',
            position: 'relative',
            zIndex: 9,
            paddingTop: 18,
          }}
        >
          <div className='title'>{isEdit ? 'Update' : 'Create'} Template</div>
          <div className='d-flex gap-3 align-items-center'>
            <ToolTip
              isModern
              tooltipText={'Task edit permissions'}
              hideTooltip={isPermissionBoxOpen}
            >
              <div
                className='modal-alert-icon btn-wrapper pe-1 pb-1 position-relative'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsPermissionBoxOpen(true);
                }}
              >
                <FilePenLine color='#87909E' size={20} strokeWidth={'1.5'} />
                {isPermissionBoxOpen && (
                  <div
                    className='addon-settings-container sub-section cursor-default'
                    style={{ width: 320 }}
                  >
                    <div
                      // role='button'
                      className='d-flex gap-1 align-items-center justify-content-between w-fit-content py-1 pe-1'
                    >
                      <div className='fw-600 fz-16px-rem'>Task edit permissions</div>
                      {/* <ChevronUp width={18} className={`${isValidationExpanded ? 'rotate-down' : 'rotate-up'}`} /> */}
                    </div>

                    <div className={`anim-hide fz-13px-rem`}>
                      <div className='overflow-hidden'>
                        <div className='mt-5px'>
                          <TaskEditSettings
                            taskSettingData={templateDetails?.task_edit_permissions}
                            loadingItemName={loadingItemName}
                            handleEditTaskChange={handleEditTaskChange}
                            modalRef={modalRef}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </ToolTip>
            <ToolTip isModern tooltipText={'Validations'} hideTooltip={isValidationBoxOpen}>
              <div
                className='modal-alert-icon btn-wrapper pe-1 pb-1 position-relative'
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setIsValidationBoxOpen(true);
                }}
              >
                <CircleAlert color='#87909E' size={20} strokeWidth={'1.5'} />
                {isValidationBoxOpen && (
                  <ValidationOptions
                    setTemplateDetails={setTemplateDetails}
                    templateDetails={templateDetails}
                    onClose={() => setIsValidationBoxOpen(false)}
                  />
                )}
              </div>
            </ToolTip>
            {/* <div
              className='modal-close-icon btn-wrapper pe-1'
              onClick={() => {
                navigate('/template-list');
              }}
            >
              <X color='#87909E' size={25} strokeWidth={'1.5'} />
            </div> */}
          </div>
        </div>

        <div
          onScroll={handleScroll}
          className='template-add-container fade-in'
          ref={containerRef}
          style={{ paddingTop: 21, scrollbarWidth: 'thin', background: '#fff' }}
        >
          {/* Left Section - ADD FORM */}
          <TemplateFormSection
            templateDetails={templateDetails}
            handleChange={handleChange}
            isSubmitted={isSubmitted}
            setTemplateDetails={setTemplateDetails}
            labelList={labelList}
            userList={userList}
            containerRef={containerRef}
            usersForVerification={usersForVerification}
            isSubtaskActive={isSubtaskActive}
            statusGroup={statusGroup}
            groupList={groupList}
            isEdit={isEdit}
          />

          {/* Right Section - PREVIEW(Related tasks) */}
          <RelatedTaskPreviewSection>
            <div>
              <div className='sub-title d-flex align-items-center mb-1'>
                Related Tasks
                {templateDetails.related_tasks?.length > 0 && (
                  <ToolTip tooltipText={expandAll ? 'Collapse All' : 'Expand All'} isModern>
                    <div style={{ marginLeft: 8 }}>
                      <ToggleButton
                        key={expandAll}
                        isChecked={expandAll ? 1 : 0}
                        handleToggle={() => setExpandAll(!expandAll)}
                        size={28}
                        disabled={false}
                      />
                    </div>
                  </ToolTip>
                )}
              </div>
              {/* List all related tasks */}
              <section className='template-related-task-container'>
                {templateDetails.related_tasks.map((relatedTask, idx) => {
                  return (
                    <TemplateTaskPreviewItem
                      key={relatedTask?.subtask?.length}
                      relatedTaskData={relatedTask}
                      setTemplateDetails={setTemplateDetails}
                      setEditTaskIndex={setEditTaskIndex}
                      index={idx}
                      onEdit={() => {
                        setEditTaskIndex(idx);
                        setTaskModalVisible(true);
                      }}
                      onDuplicate={() => {
                        onDuplicateTask(idx);
                      }}
                      onDelete={() => handleRelatedTaskDelete(idx)}
                      allRelatedTask={templateDetails.related_tasks}
                      isMenuSubTaskOn={false}
                      isMenuLabelOn={true}
                      expandAll={expandAll}
                      setExpandAll={setExpandAll}
                    />
                  );
                })}
              </section>

              {/* Add related task button */}
              <div
                role='button'
                onClick={() => {
                  setTaskModalVisible(true);
                }}
                className='pe-2 ms-3 py-1 w-fit-content d-flex align-items-center gap-1 btn-wrapper '
                style={{ marginTop: 7 }}
              >
                <Plus strokeWidth={1.5} color='#879096' size={18} />
                {/* <div className='grey-txt'>Add related task</div> */}
              </div>

              {/* Related task visibility */}
              <RelatedTaskVisibilitySelector
                handleChange={handleChange}
                isSubmitted={isSubmitted}
                templateDetails={templateDetails}
                setTemplateDetails={setTemplateDetails}
                containerRef={containerRef}
                userList={userList}
              />
            </div>
          </RelatedTaskPreviewSection>
        </div>
        {/* Bottom Buttons */}
        <div
          className={`mt-30px template-container-horizontal-padding template-container-vertical-padding position-relative d-flex ${
            scrollFlag.isBottomReached ? 'temp-border-top' : 'top-light-shadow'
          } justify-content-between align-items-center btn-container`}
        >
          {/*   Template Privacy */}
          <div className='row mt-18px'>
            <div className='col'>
              <div className='mt-5px d-flex align-items-center justify-content-between gap-2'>
                <ToggleButton
                  isChecked={templateDetails.is_private}
                  handleToggle={handleChange}
                  size={30}
                  id={'is_private'}
                />
                <div className='fz-13px'>Set as private</div>
              </div>
            </div>
          </div>
          <div className='d-flex gap-2'>
            <Button.Container
              type='button'
              className='cancel-btn'
              isDisabled={isSubmitting}
              handleOk={() => {
                navigate('/template-list');
              }}
            >
              <Button.Title title='Cancel' />
            </Button.Container>
            <Button.Container type='submit' isDisabled={isSubmitting || isTemplateDataLoading}>
              <Button.Title title={id && Boolean(parseInt(id)) ? 'Update' : 'Create'} />
              <Button.Loading isLoading={isSubmitting} />
            </Button.Container>
          </div>
        </div>
      </form>

      {/* Related task add/edit modal */}
      {taskModalVisible && (
        <RelatedTaskTemplateAddModal
          isEdit={editTaskIndex !== null}
          isSubtaskActive={isSubtaskActive}
          initialTaskData={
            editTaskIndex != null
              ? templateDetails.related_tasks[editTaskIndex]
              : generateInitialRelatedTaskData()
          }
          onClose={() => {
            setTaskModalVisible(false);
            setEditTaskIndex(null);
          }}
          startModeOptions={generateStartModeOptions(templateDetails.related_tasks, editTaskIndex)}
          onSubmit={handleRelatedTaskAdd}
          checkForCircularDependency={handleCircularDependencyCheck}
          labelList={labelList}
          userList={userList}
        />
      )}
    </div>
  );
}
