import React, { useEffect, useRef } from 'react';
import { CHAT_TEXTAREA_CLASS_NAME } from '../_constants/chat.constants';
import { focusInputByClassName } from '../Utils';

const TextAreaComponent = ({
  text,
  setText,
  onSend,
  className = '',
  onPaste = () => {},
  placeholder = '',
  onChange = () => {},
  containerRef,
  isInTask,
}) => {
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.style.height = 'auto';
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'; //+28 for container
      containerRef.current.style.height = textAreaRef.current.scrollHeight + 28 + 'px';
    }
  }, [text]);
  // Focus input on load
  useEffect(() => {
    // Only focus on web view (if chat is in task single view), otherwise page will scroll to bottom on focus
    if (!isInTask || window.innerWidth > 1110) {
      focusInputByClassName(CHAT_TEXTAREA_CLASS_NAME);
    }
  }, []);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      if (e.altKey || e.shiftKey || e.ctrlKey) {
        // If Alt, Shift, or Control is pressed with Enter, insert a new line at the cursor position
        e.preventDefault(); // Prevent the default Enter behavior

        const cursorPosition = e.target.selectionStart;
        const newText = text.slice(0, cursorPosition) + '\n' + text.slice(cursorPosition);
        setText(newText);

        // Move the cursor to the position after the new line
        setTimeout(() => {
          e.target.selectionStart = e.target.selectionEnd = cursorPosition + 1;
        }, 0);
      } else {
        // Otherwise, send the text
        e.preventDefault(); // Prevent the default Enter behavior
        onSend(text);
        setText(''); // Clear the text area
      }
    }
  };

  return (
    <textarea
      id='chat-text-input-ta' //Don't change this id.
      ref={textAreaRef}
      value={text}
      onChange={onChange}
      onKeyDown={handleKeyDown}
      onPaste={onPaste}
      className={className}
      rows='1'
      cols='50'
      placeholder={placeholder}
    />
  );
};

export default TextAreaComponent;
