import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import { GlobalContext } from '../Utils';
import { MENU } from '../_constants';

export const OptionTabs = ({ activeTab, setActiveTab }) => {
  const { folderId } = useParams();

  const { getMenuLabelName, globalSettings } = useContext(GlobalContext);
  const LABEL_OF_CLIENT = getMenuLabelName(MENU.contact.default_name);

  const menuSettings =
    globalSettings &&
    globalSettings.length &&
    globalSettings.find((settings) => settings?.name === 'menu-settings');

  const hasExist = (default_name) => {
    return (
      menuSettings?.labels &&
      menuSettings?.labels.some((label) => label.default_name === default_name)
    );
  };
  // Base tabs without the "Client" tab
  const baseTabs = [
    { name: 'Share', value: 'share' },
    { name: 'Folder', value: 'folder' },
    { name: 'Expire & Notification', value: 'expire' },
  ];

  // Conditionally add the "Client" tab if user.role is 1
  const TABS = hasExist(MENU.contact.default_name)
    ? [...baseTabs, { name: LABEL_OF_CLIENT, value: LABEL_OF_CLIENT }]
    : baseTabs;

  // Filter out the "Folder" tab if folderId is present
  const filteredTabs = folderId ? TABS.filter((tab) => tab.value !== 'folder') : TABS;

  const isActive = (tabName) => activeTab === tabName;

  return (
    <section className='file_tabs'>
      <div className='file-tab-line' style={{ top: 30 }} />
      <div className='d-flex gap-3 my-2 flex-wrap'>
        {filteredTabs.map((item, index) => (
          <div
            key={index}
            style={{ zIndex: '2' }}
            className={`${isActive(item.name) ? 'tab_active' : 'tab_inactive'}`}
            onClick={() => setActiveTab(item.name)}
          >
            {item.name}
          </div>
        ))}
      </div>
    </section>
  );
};
