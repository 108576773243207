import { useQueryClient } from '@tanstack/react-query';
import { base64ToFile, getFileType, sanitizeFileName } from '../Utils';

function useChatUtils({ chatListQueryKey, messages = [], onMessageUpdate = () => {} }) {
  const queryClient = useQueryClient();

  const updateMessage = (messageId, newMessageData) => {
    queryClient.setQueryData(chatListQueryKey, (oldData) => {
      //Change the key according to props
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => ({
        ...page,
        data: {
          ...page.data,
          rows: page.data.rows.map((message) =>
            parseInt(message.id) === parseInt(messageId)
              ? { ...message, ...newMessageData }
              : message,
          ),
        },
      }));

      return {
        ...oldData,
        pages: newPages,
      };
    });
  };

  const deleteFromUseQuery = (messageId) => {
    let queryIndex = 0;

    const updatedTempMessages = messages.filter((message, index) => {
      if (parseInt(messageId) === parseInt(message.id)) queryIndex = index;
      return parseInt(messageId) !== parseInt(message.id);
    });

    if (updatedTempMessages.length !== messages.length) {
      onMessageUpdate(updatedTempMessages);
      return { queryIndex, isFromTemp: true };
    }

    queryClient.setQueryData(chatListQueryKey, (oldData) => {
      //Change teh key according to props
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => ({
        ...page,
        data: {
          ...page.data,
          rows: page.data.rows.filter((message, index) => {
            if (parseInt(message.id) === parseInt(messageId)) queryIndex = index;
            return parseInt(message.id) !== parseInt(messageId);
          }),
        },
      }));

      return {
        ...oldData,
        pages: newPages,
      };
    });
    return { queryIndex, isFromTemp: false };
  };

  // Recover to query client
  const recoverMessageFromUseQuery = (queryIndexObj, item) => {
    const { queryIndex, isFromTemp } = queryIndexObj;

    if (isFromTemp) {
      onMessageUpdate((message) => {
        return [...message.slice(0, queryIndex), item, ...message.slice(queryIndex)];
      });
      return;
    }

    queryClient.setQueryData(chatListQueryKey, (oldData) => {
      //Change teh key according to props
      if (!oldData) return;

      const newPages = oldData.pages.map((page) => {
        return {
          ...page,
          data: {
            ...page.data,
            rows: [
              ...page.data.rows.slice(0, queryIndex),
              item,
              ...page.data.rows.slice(queryIndex),
            ],
          },
        };
      });

      return {
        ...oldData,
        pages: newPages,
      };
    });
  };

  const getFileDataFromBlobs = async (files) => {
    const promises = Array.from(files)
      .filter((item) => item.kind === 'file')
      .map((fileItem) => getFileDataFromBlob(fileItem));
    try {
      const fileDataArray = await Promise.all(promises);
      return fileDataArray;
    } catch (error) {
      // Handle the error (if one of the files failed)
      console.error('Error processing files:', error);
      throw error; // Pass the error along if needed
    }
  };

  const getFileDataFromBlob = (file) => {
    return new Promise((resolve, reject) => {
      if (file.kind === 'file') {
        const blob = file.getAsFile();
        const reader = new FileReader();
        reader.onload = (event) => {
          const dataUriRegex = /^data:(image\/\w+);base64,/;
          if (event.target.result.match(dataUriRegex)) {
            const { file, fileType } = base64ToFile(event.target.result);

            if (!fileType) {
              reject('Not Supported');
            }

            const fileData = {
              name: file.name.replace(/[^\w.-]|[\s&]/g, ''),
              sanitizeName: sanitizeFileName(file.name),
              fileType: getFileType(file.name.split('.').pop()),
              size: file.size,
              extension: file.name.split('.').pop(),
              file,
              attachedType: fileType,
              isUploading: true,
              isFailed: false,
              isSuccess: false,
            };
            resolve(fileData);
          } else {
            reject('Not Supported');
          }
        };
        reader.readAsDataURL(blob);
      }
    });
  };

  return {
    recoverMessageFromUseQuery,
    deleteFromUseQuery,
    updateMessage,
    getFileDataFromBlob,
    getFileDataFromBlobs,
  };
}

export default useChatUtils;
