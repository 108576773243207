/* eslint-disable no-unused-vars */
import { useInfiniteQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import {
  Bold,
  Check,
  Italic,
  ListOrdered,
  Minus,
  PanelLeftClose,
  PanelLeftOpen,
  Strikethrough,
  Underline,
  X,
} from 'lucide-react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button } from '../_components';
import ToolTip from '../_components/Tooltip/ToolTip';
import { noteService } from '../_services';
import useDebouncedEffect from '../Hook/useDebouncedEffect';
import useObserver from '../Hook/useObserver';
import { GlobalContext } from '../Utils';
import { CustomTrashIcon, LoadingIcon, NotEdit, ShareIcon } from '../Utils/SvgIcons';
import './NoteEditor.css';
// Helper function to extract the first line of plain text from the note
function extractTextBeforeFirstBr(note) {
  // Replace all tags except <br> with an empty string
  let plainText = note?.replace(/<\/?(?!br\s*\/?)[^>]+>/g, ''); // Remove all tags except <br>

  // Replace &nbsp; with a space
  plainText = plainText?.replace(/&nbsp;/g, ' ');

  // Replace <br> with newline characters to simulate line breaks in plain text
  const textWithNewlines = plainText?.replace(/<br\s*\/?>/gi, '\n');

  // Split the text by newlines
  const lines = textWithNewlines?.split('\n');

  // Get the first line and trim it
  let textBeforeBr = lines[0]?.trim();

  // If there are additional lines and the first line is empty, find the next non-empty line
  if (!textBeforeBr) {
    for (let i = 1; i < lines.length; i++) {
      // Start from the second line
      const nextLine = lines[i]?.trim();
      if (nextLine) {
        textBeforeBr = nextLine; // Update the first line with the next non-empty line
        break; // Stop once we find the first non-empty line
      }
    }
  }

  // Return the result including the indication of additional lines
  return textBeforeBr;
}

export function NoteEditor({ setIsOpen }) {
  const [noteData, setNoteData] = useState({ id: null, note: '', heading: '', status: 1 });
  const [prevNoteData, setPrevNoteData] = useState(noteData);

  const [isBold, setIsBold] = useState(false);
  const [isItalic, setIsItalic] = useState(false);
  const [isStrike, setIsStrike] = useState(false);
  const [isUnderline, setIsUnderline] = useState(false);
  const [isOrderedList, setIsOrderedList] = useState(false);
  const [isNoteListVisible, setIsNoteListVisible] = useState(true);
  const [activeNoteList, setActiveNoteList] = useState(true);
  const [error, setError] = useState('');
  const editorRef = useRef(null);
  const queryClient = useQueryClient();
  const { makeAlert } = useContext(GlobalContext);

  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [clickedNoteId, setClickedNoteId] = useState(null);

  const { data, fetchNextPage, hasNextPage, isLoading, isError, isFetchingNextPage, refetch } =
    useInfiniteQuery({
      queryKey: ['notes', activeNoteList],
      queryFn: ({ pageParam = 1 }) =>
        noteService.noteList({
          status: activeNoteList ? 1 : 0,
          pageVo: {
            noOfItems: 20,
            pageNo: pageParam,
          },
        }),
      getNextPageParam: (lastPage) =>
        lastPage.data.page < lastPage.data.pages ? lastPage.data.page + 1 : undefined,
      refetchOnWindowFocus: false,
    });

  const addNoteMutation = useMutation({
    mutationFn: noteService.noteAdd,
    onSuccess: (data) => {
      refetch();
      // Clear the note data and editor content after successful save
      // setNoteData({ id: null, note: '', heading: '', status: 1 });

      let noteID = noteData?.id;
      if (!noteData?.id) {
        // setNoteData((prev) => ({
        //   ...prev,
        //   id: data?.data?.id,
        // }));
        noteID = data?.data?.id;
        // setNoteData(data?.data);
      }
      setNoteData({
        id: noteID,
        note: noteData.note,
        heading: noteData?.heading,
        status: noteData?.status,
      });

      // if (editorRef.current) {
      //   editorRef.current.innerHTML = '';
      // }
    },
  });

  // const { observerRef } = useObserver({
  //   onIntersection: () => {
  //     if (hasNextPage && !isLoading) {
  //       fetchNextPage();
  //     }
  //   },
  //   dependency: [hasNextPage, isLoading],
  // });
  const { observerRef } = useObserver({
    onIntersection: () => {
      if (hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    dependency: [hasNextPage, isFetchingNextPage],
  });

  const deleteNoteMutation = useMutation({
    mutationFn: noteService.noteDelete,
    onSuccess: () => {
      // queryClient.invalidateQueries({ queryKey: ['notes'] });
      refetch();
    },
  });

  const updateNoteMutation = useMutation({
    mutationFn: noteService.noteAdd,
    onSuccess: () => {
      refetch();
    },
  });

  const handleNewNote = () => {
    setActiveNoteList(true);
    setNoteData({ id: null, note: '', heading: '', status: 1 });
    setError('');
    if (windowWidth <= 768) {
      setIsNoteListVisible(false);
    }
    if (editorRef.current) {
      editorRef.current.innerHTML = '';
    }
  };

  const handleDeletedItems = () => {
    setActiveNoteList(!activeNoteList);
    setNoteData({ id: null, note: '', heading: '' });
    if (clickedNoteId) {
      setClickedNoteId(null);
    }
  };

  const handleSaveNote = () => {
    let heading = noteData?.heading;
    const plainTextNote = extractTextBeforeFirstBr(noteData?.note)?.trim();

    if (!plainTextNote) {
      makeAlert('No points entered.');
      return;
    }

    if (!noteData?.heading?.trim()) {
      heading = extractTextBeforeFirstBr(noteData?.note);
    }

    if (noteData?.status && noteData?.status === 1) {
      setActiveNoteList(true);
    } else {
      setActiveNoteList(false);
    }

    addNoteMutation.mutate({ ...noteData, heading: heading });
  };

  const handleSelectNote = (note) => {
    setError('');
    setNoteData({ id: note?.id, note: note?.note, heading: note?.heading, status: note?.status });
  };

  const handleDeleteNote = (noteId) => {
    setClickedNoteId(noteId);
    setDeleteConfirmation(true);

    // deleteNoteMutation.mutate(
    //   { id: noteId },
    //   {
    //     onSuccess: () => {
    //       // Clear the note data and editor content after successful deletion
    //       setNoteData({ id: null, note: '', heading: '' });
    //       if (editorRef.current) {
    //         editorRef.current.innerHTML = '';
    //       }
    //     },
    //   },
    // );
  };

  const handleDeleteConfirmationNote = (noteId) => {
    if (activeNoteList) {
      deleteNoteMutation.mutate(
        { id: noteId },
        {
          onSuccess: () => {
            // Clear the note data and editor content after successful deletion
            setNoteData({ id: null, note: '', heading: '', status: 1 });
            if (editorRef.current) {
              editorRef.current.innerHTML = '';
            }
          },
        },
      );
    } else if (!activeNoteList) {
      updateNoteMutation.mutate(
        { id: noteId, status: 1 },
        {
          onSuccess: () => {
            // Clear the note data and editor content after successful deletion
            setNoteData({ id: null, note: '', heading: '', status: 1 });
            if (editorRef.current) {
              editorRef.current.innerHTML = '';
            }
          },
        },
      );
    }

    setDeleteConfirmation(false);
  };

  // Use the custom useDebouncedEffect hook
  useDebouncedEffect(
    () => {
      if (prevNoteData?.note !== noteData?.note || prevNoteData?.heading !== noteData?.heading) {
        // Make the save API call
        handleSaveNote(noteData);
        // Update the previous note data
        setPrevNoteData({ ...noteData });
      }
    },
    3000, // Adjust the delay as needed (e.g., 3000ms)
    [noteData],
  );

  const handleFormat = (command) => {
    document.execCommand(command, false, null);
    updateToolbarState();
  };

  const handleInsertHorizontalLine = () => {
    document.execCommand('insertHorizontalRule', false, null);
  };

  const handleToggleOrderedList = () => {
    document.execCommand('insertOrderedList', false, null);
    updateToolbarState();
  };

  const handleInput = () => {
    if (editorRef.current) {
      setNoteData((prev) => ({ ...prev, note: editorRef.current.innerHTML }));
    }
  };

  const handleHeadingChange = (e) => {
    setNoteData((prev) => ({ ...prev, heading: e.target.value }));
    setError('');
  };

  const updateToolbarState = () => {
    setIsBold(document.queryCommandState('bold'));
    setIsItalic(document.queryCommandState('italic'));
    setIsStrike(document.queryCommandState('strikeThrough'));
    setIsUnderline(document.queryCommandState('underline'));
    setIsOrderedList(document.queryCommandState('insertOrderedList'));
  };

  useEffect(() => {
    if (editorRef.current && noteData?.note) {
      editorRef.current.innerHTML = noteData?.note;
    }
  }, [noteData?.id]);

  const allItems = React.useMemo(() => {
    if (!data?.pages) return [];
    const result = data.pages.flatMap((page) => page.data.rows);

    const latestNote = result[0];

    if (latestNote) {
      setNoteData(latestNote);
    } else {
      setNoteData({ id: null, note: '', heading: '', status: 1 });
      if (editorRef.current) {
        editorRef.current.innerHTML = '';
      }
    }
    return result;
  }, [data]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // to set widow width
  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleClickNote = (note) => {
    if (windowWidth <= 768) {
      setIsNoteListVisible(false);
    }
    handleSelectNote(note);
  };

  return (
    <div className='note-editor-container'>
      <div className={`note-list ${isNoteListVisible ? 'visible' : 'hidden'}`}>
        <div className='sticky-header'>
          <div className='d-flex justify-content-between align-items-center mb-1'>
            <div className='d-flex'>
              <button
                className='toggle-button'
                onClick={() => setIsNoteListVisible(!isNoteListVisible)}
              >
                {isNoteListVisible ? <PanelLeftClose size={19} /> : <PanelLeftOpen size={19} />}
              </button>
            </div>
            <div style={{ fontSize: '18px', fontWeight: 'bold' }}>Notes</div>
            <div
              style={{
                cursor: 'pointer',
                padding: '3px 8px 3px 8px',
                borderRadius: '5px',
              }}
              className='d-flex align-items-center gap-2'
            >
              <ToolTip isModern tooltipText={'New'}>
                <div
                  onClick={handleNewNote}
                  //  className={`${noteData?.id ? '' : 'deleted-notes'}`}
                >
                  <NotEdit color={noteData?.id ? '#2A2E34' : '#3454d1'} />
                </div>
              </ToolTip>
              <ToolTip isModern tooltipText={'Trash List'}>
                <div
                  onClick={handleDeletedItems}
                  // className={`${activeNoteList ? '' : 'deleted-notes'}`}
                >
                  <CustomTrashIcon
                    width={15}
                    height={17}
                    color={activeNoteList ? '#2A2E34' : '#3454d1'}
                  />
                </div>
              </ToolTip>
            </div>
          </div>
        </div>
        <div style={{ padding: '10px 10px 10px 10px' }}>
          {allItems.length > 0 ? (
            <ul>
              {allItems.map((note) => (
                <li
                  key={note.id}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleClickNote(note);
                    setDeleteConfirmation(false);
                  }}
                  className='note-item'
                >
                  <div className='note-heading'>{note?.heading}</div>
                  {!deleteConfirmation && (
                    <button
                      className='delete-button'
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNote(note.id);
                      }}
                    >
                      {activeNoteList ? <CustomTrashIcon size={16} /> : <ShareIcon />}
                    </button>
                  )}
                  {deleteConfirmation && clickedNoteId === note.id && (
                    <div style={{ display: 'flex', gap: 10 }}>
                      <div className='icon-container'>
                        <X
                          onClick={(e) => {
                            e.stopPropagation();
                            setDeleteConfirmation(false);
                          }}
                          size={16}
                        />
                      </div>
                      <div className='icon-container'>
                        <Check
                          onClick={(e) => {
                            e.stopPropagation();
                            handleDeleteConfirmationNote(note?.id);
                          }}
                          size={16}
                        />
                      </div>
                    </div>
                  )}
                </li>
              ))}
              <div ref={observerRef} className='d-flex justify-content-center'>
                {hasNextPage && isFetchingNextPage && isLoading && (
                  <div
                    className='cursor-pointer'
                    onClick={() => hasNextPage && fetchNextPage()}
                    style={{ height: '90px' }}
                  >
                    {/* <RefreshCcw size={20} color='#054fc6' /> */}
                    <LoadingIcon size={20} />
                  </div>
                )}
              </div>
              {/* <div ref={observerRef}>{isLoading && 'Loading more...'}</div> */}
            </ul>
          ) : (
            <div className='no-notes-message'>No notes available</div>
          )}
        </div>

        {isError && <div>Error loading notes</div>}
      </div>
      <div className={`editor ${isNoteListVisible ? ' hidden ' : 'full-width'} position-relative`}>
        <div style={{ position: 'absolute', top: 10, right: 20, cursor: 'pointer' }}>
          <ToolTip tooltipText={'Close'} isModern>
            <X size={19} onClick={() => setIsOpen(false)} />
          </ToolTip>
        </div>
        <div className='d-flex align-items-baseline'>
          <ToolTip tooltipText={isNoteListVisible ? 'Expand' : 'Collapse'} isModern>
            <button
              className='toggle-button'
              onClick={() => setIsNoteListVisible(!isNoteListVisible)}
            >
              {isNoteListVisible ? <PanelLeftClose size={19} /> : <PanelLeftOpen size={19} />}
            </button>
          </ToolTip>
          {addNoteMutation.isPending && (
            <div className='fz-10px'>
              Saving...
              <LoadingIcon size={30} />
            </div>
          )}
        </div>

        <input
          type='text'
          className='heading-input'
          placeholder='Enter heading'
          value={noteData?.heading}
          onChange={handleHeadingChange}
        />
        {error && <div className='error-message'>{error}</div>}
        <div className='editor-content-container'>
          <div className='toolbar'>
            <button className={isBold ? 'active' : ''} onClick={() => handleFormat('bold')}>
              <Bold size={16} />
            </button>
            <button className={isItalic ? 'active' : ''} onClick={() => handleFormat('italic')}>
              <Italic size={16} />
            </button>
            <button
              className={isStrike ? 'active' : ''}
              onClick={() => handleFormat('strikeThrough')}
            >
              <Strikethrough size={16} />
            </button>
            <button
              className={isUnderline ? 'active' : ''}
              onClick={() => handleFormat('underline')}
            >
              <Underline size={16} />
            </button>
            <button onClick={handleInsertHorizontalLine}>
              <Minus size={16} />
            </button>
            <button className={isOrderedList ? 'active' : ''} onClick={handleToggleOrderedList}>
              <ListOrdered size={16} />
            </button>
          </div>
          <div
            ref={editorRef}
            className='editor-content'
            contentEditable
            onInput={handleInput}
            onKeyUp={updateToolbarState}
            onMouseUp={updateToolbarState}
          ></div>
        </div>
        <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
          <Button.Container
            disabled={addNoteMutation.isPending}
            className={'blue-btn'}
            onClick={handleSaveNote}
          >
            <Button.Title title={addNoteMutation.isPending ? 'Saving...' : 'Save Note'} />
            <Button.Loading isLoading={addNoteMutation.isPending} />
          </Button.Container>
        </div>
      </div>
    </div>
  );
}
